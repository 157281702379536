//Action-link
.action-link{
	display: inline-block;
	position: relative;
	align-items: center;
	text-transform: uppercase;
	font-family: $font-secondary;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 1.44px;
	line-height: 20px;
	width: 100%;
	&:before{
		content: "";
		display: inline-block;
		margin-right: 20px;
		line-height: 20px;
		border-top: 1px solid;
		width: 40px;
		min-height: 4px;
		vertical-align: middle;
		transition: all 1s ease-in-out;
	}
	&:hover {
		text-decoration: none;
		
		&:before {
			margin-right: 30px;
			width: 50px;
			transition: all 1s ease-in-out;
		}
	}
	&.action-link--black{
		&:after{
			background-image: url('#{$images}/icons/arrow-right-black.svg');
		}
	}
	&.action-link--white{
		color: $white;
	}
	&.action-link--black{
		&:before{
			border-top-color: $black;
		}
	}
	
}
.white-section{
	.action-link{
		&:before{
			border-top-color: $black;
		}
		&:hover{
			color: $light-black;
		}
	}
}
.visualBox,
.heroBanner{
	.action-link{
		&.action-link--black{
			&:before {
				border-top-color: $black;
			}
		}
	}

}

//scroll link
$line-height: 60px;
$line-height-abs: -60px;
.action-scroll {
	display: none;
	@media (min-width: $tablet-L) {
		display: block;
		position: absolute;
		bottom: 100px;
		left: 170px;
	}
	.scroll-title {
		font-family: $font-primary;
		font-size: 14px;
		letter-spacing: 3px;
	}
	.scroll-block {
		width: 1px;
		height: $line-height;
		position: absolute;
		overflow: hidden;
		.scroll-line {
			width: 100%;
			height: 100%;
			display: block;
			background-position: 0 $line-height-abs;
			background-size: 100% 200%;
			animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
		}
	}
	
	//Black version
	&.text-black {
		.scroll-line {
			background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 50%);
			background-size: 100% 200%;
		}
	}
	//White version
	&.text-white {
		.scroll-line {
			background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 50%);
			background-size: 100% 200%;
		}
	}
}
@keyframes scrolldown {
	0%{
		background-position: 0 $line-height-abs;
	}
	75% {
		background-position: 0 0;
	}
	100%{
		background-position: 0 $line-height;
	}
}

//slide-link-animation
$side-animation:150px;
.slide-animation{
	position: relative;
	display: flex;
	overflow: hidden;
	img{
		transition: 1s all ease-in-out;

	}
	&:hover{
		img{
			transition: 1s all ease-in-out;
			transform: scale(1.2);
			
			
		}
	}
}

//HeroBanner-Fullscreen
$start-point:300px;
$end-point:0px;
$time:.5s;

.heroBannerFullScreen {
	.slick-slide {
		.heroBannerFullWidth__background{}
		.heroBannerFullScreen__title {
			transform: translateX($start-point);
			transition: transform $time;
		}
		.heroBannerFullScreen__subTitle{
			transform: translateX($start-point);
			transition: $time;
		}
		.link{
			transform: translateX($start-point);
			transition: $time;
		}
		.heroBannerFullScreen__sectionImage{
			opacity: 0;
			transition: $time;
			@media all and (-ms-high-contrast: none),
			(-ms-high-contrast: active) {
				height:800px;
			}
		}
	}
	.slick-active {
		.heroBannerFullScreen__title {
			transform: translateX($end-point);
			transition: $time;
		}
		.heroBannerFullScreen__subTitle{
			transform: translateX($end-point);
			transition: $time;
		}
		.link{
			transform: translateX($end-point);
			transition: $time;
		}
		.heroBannerFullScreen__sectionImage{
			opacity: 1;
			transition: $time;
			@media all and (-ms-high-contrast: none),
			(-ms-high-contrast: active) {
				height:800px;
			}
		}
	}
	a.action-link{
		margin-top: 15px!important;
	}
	@media(min-width: $tablet-P){
		a.action-link {
			margin-top: 0px!important;
		}
	}
}

//Slick
.slick-arrow{
	height: 25px;
	width: 25px;
	z-index: 1;
	&:before{
		font-size: 1.5rem;
		text-shadow: 0 3px 5px rgba(0,0,0,0.5);
	}
}
.slick-prev{
	margin-left: 25px;
	left:0;
}
.slick-next{
	margin-right: 25px;
	right: 0;
}

.heroBanner,
.visualBox,
.bannersSlider{
	.slick-arrow{
		height: 25px;
		width: 25px;
		z-index: 1;
		&:before{
			font-size: 25px;
			text-shadow: 0px 3px 5px rgba(0,0,0,0.5);
		}
	}
	.slick-prev{
	}
	.slick-next{
	}
}
.relatedItems,
.product-details__slider,
.cards-grid__gallery{
	.slick-arrow{
		margin-left: -25px;
		margin-right: -25px;
		@media (min-width: $desktop) and (max-width: $desktop-XL) {
			margin-left: 0;
			margin-right: 0;
		}

	}
}
.newslist-preview{
	.slick-arrow{
		margin-left: -50px;
		margin-right: -50px;
	}
}
.story-details__pagination{
	.slick-next{
		&:before{
			display: none;
		}
		&:after{
			font-size: 25px;
			text-shadow: 0 3px 5px rgba(0,0,0,.5);
			content: '→';
			font-family: 'slick';
			line-height: 1;
			opacity: .75;
			color: white;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

		}
	}
}