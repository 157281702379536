.how-to-connect-component{
	padding-bottom: 100px;
	@media (min-width: $desktop) {
		padding-top: 110px;
	}
	
}

.smeg{
	.how-to-connect-component{
		.form-group{
			input.form-control{
				&:focus{
					color: var(--color-whiteSmoke)
				}
				&::placeholder {
					color:$white;
				}
			}
		}
	}
	.text-white{
		.how-to-connect-component{
			.form-group{
				input.form-control{
					color:$white;
					&:focus{
						color:$white;
					}
					&::placeholder {
						color:$white;
					}
				}
			}
		}
	}
}
.foodservice,
.instrument{
	.text-white{
		.how-to-connect-component{
			.form-group{
				input.form-control{
					color:$black;
					&:focus{
						color:$black;
					}
					&::placeholder {
						color:$black;
					}
				}
			}
		}
	}

}