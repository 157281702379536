.course-body-container {
  color: $black;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  padding-top: 40px;

  @media (min-width: $tablet-P) {
    grid-template-columns: 1fr 1fr;
    padding-top: 60px;
  }

  &__title {
    grid-column: 1;
    grid-row: 1;
    margin-bottom: 1.5rem;

    @media (min-width: $tablet-P) {
      grid-column: 1 / 3;
      grid-row: 1;
    }

    h1 {
      font-family: $font-secondary;
      font-size: 40px;
      font-weight: 300;
      line-height: 42px;
      margin-bottom: 10px;
      

      @media (min-width: $tablet-P) {
        margin-bottom: 30px;
      }

      @media (min-width: $desktop) {
        font-size: 80px;
        line-height: 80px;
        margin-bottom: 40px;
      }
    }
  }
  &__info {
    grid-column: 1;
    grid-row: 3;

    @media (min-width: $tablet-P) {
      grid-column: 2;
      grid-row: 2 / 4;
    }

    > div {
      background-color: $dusty-grey;
      padding: 59px 30px 20px;

      @media (min-width: $desktop) {
        padding: 62px;
      }

      > div {
        padding: 30px 0;
      }
    }

    &__contact {

      span {
        display: block;
      }

      a {
        text-decoration: underline;
      }

      span, a {
        font-family: 'Mulish', sans-serif;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.64px;
        line-height: 30px;
      }
    }

    &__worktime,
    &__legal {

      h6 {
        font-family: Raleway, sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.68px;
        line-height: 16px;
        margin-bottom: 0.05rem;
      }

      span {
        display: block;
        font-family: 'Mulish', sans-serif;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.64px;
        line-height: 30px;
      }
    }
  }

  &__content {
    background-color: #f2f2f2;
    padding: 20px;
    &--fullwidth{
      width:100%;
      padding-top: 10px;
    }
    h4 {
      font-family: 'Mulish', sans-serif;
      letter-spacing: 0.64px;
      font-size: 1rem;

      + ul {
        margin-top: -17px;
        margin-left: 14px;
      }
    }
    .detail__label{
      font-weight: 300;
      .icons  {
        margin-right: 10px;
      }
    }
    .detail__txt {
      letter-spacing: 0.64px;
    }
  }
}

.row.equal {
  display: flex;
  flex-wrap: wrap;
}

.courses_contaner{
  .documentViewer{
    .documentViewer__textBlock{
      .documentViewer__title{
        max-width: 100%;
        text-align: left!important;
      }
    }
  }
}

.pastCoursesSlider{
  @media (min-width: $desktop) {
    /* the parent */
    .slick-list {
      margin: 0 -100px;
    }
    /* item */
    .slick-slide {
      padding: 0 100px;
    }
    .slick-prev{
      margin-left: unset;
      left:-3rem;
    }
    .slick-next{
      margin-right: unset;
      right:-3rem;
    }
  }
}

.course-element{
  background: #f2f2f2;
  //border-bottom: 1px solid #02020280;
  padding: 25px 0;
  @media (max-width: $tablet-L){
    .col-12{
      padding-bottom: 1.5rem;
      &:last-child{
        padding-bottom: 0;
      }
    }
  }
}

.btn_subscribe{
  background: black;
  color: white !important;
  padding: 10px 35px;
  &:hover{
    color: white !important;
  }
}

.heroBannerBlocks__info__title{
  max-width: unset;
}

.documentViewer__textBlock{
  width: 100%;
  .documentViewer__title{
    font-size: 22px;
  }
  p{
    line-height: 20px;
  }
}

.column-course{
  line-height: 20px;
}

.column-course-information{
  @media (min-width: $desktop) {
    text-align: left;
  }
}

.simple-course{
  .heroBannerBlocks{
    height: unset;
    padding-bottom: 0;
    margin-bottom: 0;
    //padding-top: 0;
    .heroBannerBlocks__info__title{
      max-width: unset;
    }
    .heroBannerBlocks__info{
      padding-bottom: 0;
    }
  }
}