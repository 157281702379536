//HeroBannerComponent
$imageHeight: 580px;

.cmp_heroBanner {
    margin: 0 auto;

    .newOverLay{
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        @media (min-width: $desktop) {
            width: 50%;
        }
        &-gradient {
          background: linear-gradient(90deg, rgba(0,0,0,.75) 0%, rgba(0,0,0,0) 100%)
        }
    }

    .textBlock{
        .topic{
            @include subText(md);
            @include pb(2);
            display: inline-block;
        }
        .title{
            @include newtitle(lg, lg);
        }
        .text{
            @include text();
        }
    }
}

.cmp_heroBanner {
  &.tpl_fullScreen,
  &.tpl_heroBannerDefault {
    .textBlock {
      @media (min-width: $desktop) {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
      }

      .wrapper-text {
        @media (min-width: $tablet-L) {
          max-width: 50%;
        }
        @media (min-width: $desktop) {
          max-width: 40%;
        }
        @media (min-width: $desktop) {
          max-width: 45%;
        }
        @media (min-width: $max-screen-size) {
          max-width: 40%;
        }
      }
    }
  }
  &.tpl_heroBannerDefault {
    position: relative;

    .gallery {
      width: 100vw;
      max-width: 100%;
      position: relative;
      overflow: hidden;
      background-size: cover;
      background-position: center;

      @media (min-width: $tablet-P) {
        width: inherit;
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 800px;
      }
    }

    .textBlock {
      @include space-l();
      @include space-r();
      position: absolute;
      top: 0;
      z-index: 2;
      height: inherit;
      width: 100%;
      @media (min-width: $tablet-L) {
        min-width: 780px;
        height: 100%;
        display: flex;
      }
      @media (min-width: $desktop) {
        padding-right: 0;
      }
      .topic {
        visibility: hidden;
        @include pb(0);
        @media (min-width: $tablet-P) {
          visibility: visible;
        }
        @media (min-width: $desktop) {
          margin-bottom: -10px;
          display: block;
        }
      }
      .title {
        @media (min-width: $desktop) and (max-width: $desktop-large) {
          font-size: 26px !important;
        }
      }

      .text {
        @media (min-width: $tablet-L) {
          width: 100%;
        }
      }
      .hideIntroductionOnMobile {
        display: none;
        @media (min-width: $desktop) {
          display: block;
        }
      }
    }
    /*Se l'herobanner default non ha l'opacità, il titolo occuperà l'intera riga dello schermo*/
    .newOverLay ~ .textBlock {
      .title {
        inline-size: 100%;
      }
    }
    .background {
      max-width: unset;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      height: auto;

      @media (min-width: $tablet-P) {
//         padding-right: 30px;
        position: initial;
        height: 100%;
      }

      @media (min-width: $desktop-XL) {
        padding-left: 0;
        padding-right: 50px;
      }
    }

    .textAlign {
      width: 100%;
      padding-top: 0px !important;
      padding-bottom: 20px;

      @media (min-width: $desktop) {
        padding-top: 100px;
        padding-bottom: unset;
      }

      @media (min-width: $desktop-XL) {
        padding-top: 37px;
      }
    }

    .textBlock {
      .wrapper-text {
        .title {
          letter-spacing: 1.3px;
          font-weight: 500;
          font-family: raleway, sans-serif;
          line-height: 1.3;

          @media (min-width: 1200px) {
            font-weight: 400;
          }

          &.style-h1 {
            font-size: 20px;

            @media (min-width: 1200px) {
              font-size: 40px;
            }
          }

          &.style-h2 {
            font-size: 18px;

            @media (min-width: 1200px) {
              font-size: 36px;
            }
          }

          &.style-h3 {
            font-size: 18px;

            @media (min-width: 1200px) {
              font-size: 32px;
            }
          }
        }
      }
      .background {
        padding-top: 10px;
        padding-bottom: 50px;
        .textAlign {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: baseline;
          height: 100%;
          @media (max-width: 1199px){
            justify-content: flex-start;
          }
          .topic,
          .title,
          .text {
            @media (min-width: $desktop) {
              padding-bottom: unset;
            }
          }
        }
      }
    }
    &.tpl_heroBannerDefault--txt-centered {
      .newOverLay {
        width: 100%;
      }
      .wrapper-text {
        max-width: none;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .textBlock .background {
        padding-right: 0;
        .textAlign {
          text-align: center;
          align-items: center !important;
        } 
      }
      .textBlock{
        padding-left: 0;
        padding: 0;
      }
      .topic {
        font-family: Raleway,sans-serif;
        font-size: 12px;
      }
    }
  }
  &.tpl_blocks {
    @media (min-width: $desktop) {
      height: 470px;
    }
    @media (min-width: $desktop-XL) {
      height: 710px;
    }
    .wrapper {
      padding-top: $text--pt;
      position: relative;
      @media (min-width: $tablet-P) {
        padding-top: 100px;
      }
      @media (min-width: $desktop) {
        padding-top: 100px;
        height: 400px;
        margin-bottom: 70px;
      }
      @media (min-width: $desktop-XL) {
        height: 580px;
        padding-bottom: 100px;
        margin-bottom: 130px;
      }
    }
    .textBlock {
      @media (min-width: $tablet-L) {
        margin-top: 30px;
      }
    }
    .title {
    }
    .text {
      @include pb(2);
    }
    .hideIntroductionOnMobile {
          display: none;
          @media (min-width: $desktop) {
            display: block;
          }
    }
    .category {
      display: flex;
      align-items: center;
      font-family: $font-secondary;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 1.82px;
      line-height: 36px;
      margin-bottom: 10px;

      @media (min-width: $tablet-P) {
        padding-left: unset;
        padding-right: unset;
      }

      @media (min-width: $desktop) {
        font-size: 16px;
        letter-spacing: 2.24px;
      }

      span {
        display: inline-block;
        margin-right: 19px;
        line-height: 20px;
        border-top: 1px solid;
        width: 39px;
      }
    }

    .gallery {
      @media (min-width: $tablet-L) {
        margin-top: 30px;
      }
      .image {
        width: 100vw;
        margin-left: -37px;
        @media (min-width: $tablet-P) {
          width: auto;
          margin-left: auto;
        }
      }
    }
  }
  &.tpl_fullScreen {
    $fullImageHeight: calc(100vh - 160px);
    max-width: unset;
    padding-bottom: 0px;
    position: relative;
    .textBlock {
      @include space-r;
      @include space-l;
      position: absolute;
      height: 100%;
      display: flex;
      align-items: unset;
      bottom: 0;
      @media (min-width: $tablet-L) {
        width: 65%;
        z-index: 2;
      }
      @media (min-width: $desktop) {
        align-items: center;
        z-index: 2;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
      }

      .wrapper-text{
        @media (min-width: $tablet-P) and ( max-width: $desktop) {
          max-width: 100%;
        }
      }

      .topic {
        display: block;
        font-family: $font-secondary;
        font-weight: 500;
        letter-spacing: 1.44px;
        line-height: 36px;
        padding-top: 50px;

        @media (min-width: $tablet-P) {
          padding-top: 10px;
        }

        @media (min-width: $desktop) {
          display: initial;
          padding-top: 0;
        }
      }
      .topic,
      .title,
      .text,
      .action-link {
        position: relative;
        z-index: 2;
        @media (min-width: $desktop) {
          position: inherit;
          z-index: 1;
        }
      }
      .title {
        display: initial;
        @media (min-width: $tablet-L) {
          display: block;
        }
      }
      .text {
        @media (min-width: $tablet-L) {
          max-width: 470px;
        }
      }
      .hideIntroductionOnMobile {
          display: none;
          @media (min-width: $desktop) {
            display: block;
          }
      }

      &.hasCountdown {
        
        .wrapper-text {
          .countdown-title {
            margin-bottom: 1rem;
            margin-top: 1.5rem;
            position: relative;
            z-index: 44;

            @media (max-width: $tablet-L) {
              font-size: 1.1rem;
            }
          }
          .flipdown {
            margin-bottom: 2rem;

            @media (max-width: $tablet-L) and (orientation: portrait) {
              max-width: 300px;
              margin-left: -15px;
            }
          }
        }
        @media (max-width: $tablet-L) and (orientation: portrait) {
          width: 100%;
        }
      }
    }

    .heroBannerFullScreen__background--black,
    #slick-slide14,
    .newOverLay {
      .textBlock {
        width: 100% !important;
        max-width: 100%;
        background-color: rgba(0, 0, 0, 0.4);

        @media (min-width: $desktop) {
          max-width: 50%;
        }

        .title {
          width: 338px;
        }
      }
    }

    .slick-dots {
      position: absolute;
      bottom: 15px;
      right: 0;
      width: 100%;
      text-align: center;
      padding-left: unset;

      @media (min-width: $tablet-L) {
        width: 33%;
        text-align: left;
        bottom: $dots--pb;
      }

      li {
        button {
          border-bottom: 3px solid $grey;
          width: 100%;

          &:before {
            content: "";
          }
        }

        &.slick-active {
          button {
            border-color: $white;
          }
        }
      }
    }

    &.slick-dotted.slick-slider {
      margin-bottom: 0px;
    }

    $fullVideoHeight: calc(100vw * 0.496);
    .video {
      width: 100%;
      height: 533px;
      display: flex;
      flex-direction: column-reverse;
      object-fit: cover;

      img {
        object-fit: cover;
        width: 100%;
      }

      @media (min-width: $desktop) {
        height: $fullVideoHeight;
        max-height: unset;
        position: relative;
        width: 100%;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          height: auto;
        }

        img {
          object-fit: cover;
          height: $fullVideoHeight;
          width: 100%;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            height: auto;
          }
        }
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        iframe {
          width: 100%;
          height: $fullImageHeight;
        } 
      }
    }
    &.tpl_fullScreen-textCentered {
      .textBlock {
        width: 100%;
        display: flex;
        align-items: center;

        .wrapper-text {
          max-width: 100%;
          text-align: center;

          .text{
            max-width: none;
          }
        }
      } 
    }
  }
  &.tpl_textFocus {
    .textBlock {
      position: relative;
      padding-bottom: 0px;
      margin-top: 50px;
      @media (min-width: $tablet-L) {
        margin-top: 100px;
        padding-left: 85px;
        padding-right: 85px;
      }
      &:before {
        @media (min-width: $tablet-L) {
          content: "";
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          width: 2px;
          border-left: 2px solid;
        }
      }
      &.collapsed {
        .text {
          p:first-of-type {
            display: inline-block;
          }
          p {
            display: none;
          }
        }

        a.btn__showMore__label {
          &.show {
            display: inline-block;
          }
        }
      }
      &.expanded {
        .text {
          p {
            display: inline-block;
          }
        }

        a.btn__showMore__label {
          &.show {
            display: inline-block;
          }
        }
      }
      .title {
        @include newtitle(lg, lg);
      }
      .hideIntroductionOnMobile {
        display: none;
        @media (min-width: $desktop) {
          display: block;
        }
      }
    }

    .btn__showMore a {
      display: none;
      padding-top: $text--pt;
      padding-bottom: $text--pt;
    }
  }
}

.foodservice{
    .cmp_heroBanner {
        &.tpl_textFocus {
            .textBlock {
                padding-left: 0px;
                &:before {
                    @media (min-width: $tablet-L) {
                        display: none;
                    }
                }
            }
        }
        &.tpl_blocks{
            .textBlock{
                .title{
                    @include pb(0);
                }

            }
        }
    }
}

.tpl_sliderDefault{
  .hideIntroductionOnMobile {
      display: none;
      @media (min-width: $desktop) {
        display: block;
      }
  }
}