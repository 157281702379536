.products-manager-page {
    background-color: $white;
    
    .white-section {
        
        a {
            color: $color-primary;
            
            &:hover {
                color: $color-primary;
            }
        }
        
        .action-link {
            color: $color-primary;
            
            span {
                border-color: $color-primary;
            }
            
            &:hover {
                color: $color-primary;
            }
        }
        
    }

    .cmp_heroBanner.tpl_textFocus{
        .products-manager-page__content__action-link {
            font-weight: bold;
            text-decoration: none;
            padding: 16px 10px;
            cursor: pointer;
            width: 100%;
            min-width: fit-content;
            max-width: 350px;
            text-align: center;
            display: block;
            .smeg & {
                text-align: left;
            }
        }
    }

    .products-list {
        .products-list__item {
            display: flex;
            justify-content: center;
            //flex-direction: row;
            margin-left: 7px;
            margin-right: 7px;
            @media (min-width: $desktop) {
                margin-left: unset;
                margin-right: unset;
            }
            
            > a {
                display: table;
                margin: 0 auto;
            }
            
            .products-list__item__image {
                width: 100%;
                height:auto;
                @media (min-width: $desktop) {
                    width: 100%;
                    max-width: 350px;
                    height:350px;
                    object-fit: cover;
                }
            }
            
        }
        
        &:not(.js-product-list-slider) {
            
            .products-list__item {
                display: table;
                @media (min-width: $mobile) {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
                
                .products-list__item__image {
                    display: none;
                    
                    @media (min-width: $mobile) {
                        width: 100%;
                        display: block;
                    }
                }
                
                .products-list__item__title {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
                
            }
            
        }
        
        &.js-product-list-slider {
            
            .slider-arrow {
                
                img {
                    position: absolute;
                    width: 20px;
                    left: 0;
                }
                
                &:before {
                    content: "";
                }
                
            }
            
            .products-list__item {
                
                .products-list__item__title {
                    text-align: center;
                    color: $light-black;
                    font-family: $font-secondary;
                    font-size: 25px;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    max-width: 300px;
                    margin: 20px auto;
                }
                
            }
            
        }
        
        &.product-list-slider-align {
            
            .slick-track {
                margin-left: 0px;
                margin-right: 0px;
            }
            
            .products-list__item {
                
                > a {
                    margin-left: 0px;
                    margin-right: 0px;

                    @media (max-width: 575px) {
                        width: 100%;
                    }
                }
                
            }
            
        }
        
    }
    
    .products-all {
        position: relative;
        
        .products-all__description {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            display: flex;
            align-items: center;
            color: $white;
            
            h1 {
                text-transform: uppercase;
            }
            
            .action-link {
                color: $white;
            }
            
        }
        
    }
    
    .products-manager-page__content {
        margin-top: 30px;
        margin-bottom: 30px;

        .title-category {
            color: $black;
            font-family: $font-secondary;
            font-size: 24px;
            font-weight: 300;
            line-height: 28px;
            margin-bottom: 20px;
            @media (min-width: $tablet-L) {
                text-align: left;
                font-size: 30px;
                line-height: 58px;
            }
            
        }
        
        .products-manager-page__content__action-link {

            text-align: center;
            display: block;
            .showed-mobile {
                display: block;
                @media (min-width: $tablet-P) {
                    display: none;
                }
            }
            
            .showed-not-mobile {
                display: none;
                @media (min-width: $tablet-P) {
                    display: block;
                }
            }
            
        }

        .row{
            padding-left: 0;
            @media(min-width: $tablet-L){
                padding-left: 80px;
            }
        }
    }
}

.products-manager-page{
    .products-manager-page__slider{
        padding-left: 0px;
        padding-right: 0px;
        margin-top:25px;
        margin-bottom: 50px;
        @media (min-width: $tablet-P) {
            padding-left: 60px;
            padding-right: 60px;
        }
        @media (min-width: $tablet-L) {
            padding-left: 100px;
            padding-right: 100px;
        }
        @media (min-width: $desktop) {
            padding-left: 45px;
            padding-right: 45px;
        }
        .slick-arrow{
            padding-left: 30px;
            padding-right: 30px;
            z-index: 2;
            @media (min-width: $tablet-P) {
                padding-left: 0;
                padding-right: 0;
            }
            @media (min-width: $desktop) {
                padding-left: 0;
                padding-right: 0;
            }
            @media (min-width: $desktop-XL) {
                padding-left: 50px;
                padding-right: 50px;
            }
            
        }
    }
    .cards-custom-category{
        padding-left: 0;
        padding-right: 0;
        @media (min-width: $desktop) {
            max-width: 1920px;
        }
        .cards__card{
            max-width: 100%;
            @media (min-width: $desktop) {
                //max-width: 460px;
                margin-left:unset;
                margin-right:unset;
            }
        }
        .cards__image{
            width: 100%;
            @media (min-width: $desktop) {
                max-width: 620px;
            }
        }

        .cards__textBox{
            .cards__title{
                line-height: normal;
            }
            a.action-link{
                position: absolute;
                bottom: 25px;

                @media (min-width: $desktop) {
                    bottom: 0;
                }
            }
        }
    }
    .cards-footer-category{
        .slider-cards-component{
            justify-content: center;
            @media (min-width: $desktop) {
                justify-content: space-around;
            }
        }
        .cards__card{
            max-width: 460px;
        }
        .cards__image{
            width: 100%;
            @media (min-width: $tablet-P) {
                max-width: 620px;
            }
        }
    }
    .cards-custom-category,
    .cards-footer-category{
        .header{
            .title{
                font-weight: 400;
            }
        }
    }

    .js-slider-cards-component{
        .slick-track{
            display: flex !important;

            @media (min-width: $desktop) {
                width: 100% !important;
            }
        }

        .slick-slide{
            height: inherit !important;
        }
    }

    .slick-arrow{
        @media (min-width: $tablet-P) {
            &.slick-prev{
                margin-left: -$space--md;
            }
            &.slick-next{
                margin-right: -$space--md;
            }
        }
        @media (min-width: $desktop) {
            &.slick-prev {
                margin-left: -45px;
            }
            &.slick-next {
                margin-right: -45px;
            }
        }
    }

    .cmp_relatedByLook {
        .slick-arrow{
            @media (min-width: $tablet-P) {
                &.slick-prev{
                    margin-left: $space--md;
                }
                &.slick-next{
                    margin-right: $space--md;
                }
            }
            @media (min-width: $desktop) {
                &.slick-prev {
                    margin-left: 45px;
                }
                &.slick-next {
                    margin-right: 45px;
                }
            }
        }
    }

    
}
