.page__product-detail.page__bundle-detail {
    margin-bottom: 0;
    .bundle-info {
        p {
            color: #020202;
        }
    }

    .product-details.product-details__main {
        margin-bottom: 0;
    }

    .product-details .product-details__slider .product-details__slide .product-details__image {
        padding: 0;
    }

    .slick-track {
        display: flex;
        flex-grow: 1;

        .slick-slide {
            height: auto;
        }
    }

    .js-related-Product__slide .data-href {
        margin-bottom: 20px;
    }
    .js-related-Product__slide .data-href.active{
        background-color: #e3e3e3;

        &::after {
            content: "";
            box-sizing: border-box;
            height: 50px;
            width: 40px;
            border-style: solid;
            border-color: #e3e3e3;
            border-width: 0px 5px 5px 0px;
            transform: rotate(45deg);
            transition: border-width 150ms ease-in-out;
            position: absolute;
            bottom: 7px;
            left: calc(50% - 20px);
        }

        .cards__image {
            background-color: #fff;
        }
    }

    .product-details__main {
        grid-template-areas:
        "title"
        "products"
        "gallery"
        "features"
        ;
        grid-template-rows: auto;
        grid-gap: 10px 0;

        @media (min-width: $desktop) {
            grid-gap: 10px;
        }

        .main__features .btn {
            
            @media (max-width: $desktop) {
                width: calc(100% + 30px);
            }
        }

    }

    .bundle-products {
        grid-column: 1;
        grid-row: 5/6;
        grid-area: "products";

        @media (min-width: $desktop) {
            grid-column: 3/11;
            grid-row: 5/6;
        }
    }

    .product-details .prices {
        display: flex;
        align-items: baseline;

        .price.price--strikethrough {
            margin-left: 10px;
        }
        .price-eco.price--strikethrough {
          margin-left: 10px;
          font-size: 1rem;
        }
    }
    .product-details__main .main__title {
        grid-column: 1/6;
        justify-content: flex-start;
        .bundle-products-title {
            display: block;
            font-size: 16px;
            color: #797979;
            margin-top: 20px;
        }
    }
 }

 .product-selection-label {
    font-size: 13px;
    letter-spacing: 1.82px;
    line-height: 36px;
    text-transform: uppercase;
    font-weight: 900;
    color: #161616;
    margin: 0 auto;
    text-align: center; 
    padding: 0;

    &::after {
        content: "";
        display: block;
        width: 0;
        height: 3px;
        position: absolute;
        bottom: 0;
        transition: width .3s;
        background: #161616;
        top: 0;
        width: 100%;
    }

    &::before {
        content: ">";
        display: block;
        transform: rotate(-90deg);
        position: absolute;
        transition: width .3s;
        top: -45px;
        width: 100%;
        font-size: 50px;
        font-family: monospace;
    }
 }

 .bundle-wrap-product-data {
    position: relative;

    .bundle-product-loader {
        display: flex;
        height: 100%;
        width: 100%;
        position: absolute;
        background: #000;
        z-index: 6;
        min-height: 600px;
        top: 0;
        opacity: 0.4;
    }
 }

 #pdp_new.bundle-main {

    .main__title h1 {
        font-weight: 400;
        padding-bottom: 0;
    }

    .main__gallery {
        margin-bottom: 25px;
        padding: 0;

        .product-details__slide {
            &:hover {
                cursor: auto;
            }
        }
    }

   .ecoPartecipation-table{
     table{
       tr{
         td{
           &:first-child{
             width: 65px;
           }
         }
       }
     }
   }

    .color__selection {
        margin-top: 20px;

        .selection__title {
            margin-bottom: 8px;
        }
    }
    
    .breadcrumb-item a:hover,
    .breadcrumb-item.active {
        color: #000 !important;
    }

    .product-details__infos_priceEnergy .prices {
        flex-wrap: wrap;

        .product-energyClass {
            width: 100%;
        }
    }


    img{ margin:0 auto; }

    .price--discounted{
        font-size:1.3rem;
        @media (min-width: $tablet-P){
          font-size: 2.3rem;
        }
        color: #000;
        font-weight:bold;
    }
    .price--discounted-eco{
       font-size:1rem;
       color: #000;
       font-weight:400;
      @media (min-width: $tablet-P){
        font-size: 1.3rem;
      }
     }
    .discount{
        background-color: #640000;
        display: inline;
        color: #FFF;
        font-weight: bold;
        padding: 5px 20px;
        font-family: 'Mulish', sans-serif;

        @media (min-width: $tablet-P) {
            padding: 2px 10px;
        }
    }
    .btn-add-to-cart{
        width:100%;
    }
    @media (min-width: $tablet-P) {
        .btn-add-to-cart{
            width:50%;
        }
    }
    .tabSelector{
        padding-top:1%;
        background-color:#FFF
    }
    .tabSelector.active {background-color:#FFFFFF}
    .tabSelector .cards__image img { width:100%; padding:20%; background-color:#FFF; }
    .subtab {
      background-color:#F8F8F8;
      margin-bottom:50px;
      padding: 50px;
      h1{
        font-weight: 400;
        padding-bottom: 0;
      }
    }
    .product-details__image img { width:100%; }

    .tabSelector.active .data-href:after {
        content: '';
        display: inline-block;
        rotate: 180deg;
        font-size: 32px;
        font-family: monospace;
        transition: transform .15s ease-in-out;
        background: url('#{$images}/icons/arrow-accordion.svg') center no-repeat;
        height: 20px;
        width: 20px;
        margin-left: calc(50% - 10px);
    }
    .cards__sub_title {
        min-height:100px;
    }

    .smeg-btn{
        padding:10px 30px;
        min-width: 100%;

        display: inline-block;
        text-align: center;
    }
    .tab{
        margin: 0 -50px;
    }

    /* Nuova struttura */
    .site-smeg .title-1st{
        font-size: 32px;
        line-height: 34px;
    }

    .site-smeg .title-2nd{
        font-size: 22px;
        line-height: 26px;
    }

    .site-smeg .smeg-btn{
        background-color:#000;
        font-weight:bold;
        color:#FFF;
    }
    .site-smeg .smeg-btn:hover{
        background-color:#000;
        color:#FFF;
        text-decoration:underline;
    }
    
    @media (min-width: $tablet-P) {
        .smeg-btn{
            min-width: 300px;
        }
        .tab{
            margin: 0 -15px;
        }
        .tabSelector.active {background-color:#F8F8F8}
        .site-smeg .title-1st{
            font-size: 40px;
            line-height: 44px;
        }

        .site-smeg .title-2nd{
            font-size: 22px;
            line-height: 26px;
        }
    }

   @media (min-width: $desktop) and (max-width: $desktop-XL) {
     .smeg-btn{
       min-width: 200px;
       padding: 0;
     }
     .tabSelector.active {background-color:#F8F8F8}
     .site-smeg .title-1st{
       font-size: 35px;
       line-height: 40px;
     }
     .site-smeg .title-2nd{
       font-size: 15px;
     }
   }

    .product-details__slider{
        .carousel-control-prev,
        .carousel-control-next {
            display: none;

//             @media (min-width: $desktop) {
//               display: -ms-flexbox;
//               display: flex;
//             }
        }
    }

}