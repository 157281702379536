.reference-tab-component {

    .tab-component {
        margin: 0px;
        padding: 0;
        list-style-type: none;

        @media (min-width: $tablet-L) {
            padding-bottom: 0;
        }

        @media (min-width: $mobile) {
            display: flex;
        }

        li.tab-component__button {
            position: relative;

            @media (min-width: $mobile) {
                text-align: center;
            }

            &:after {
                content: '';
                display: block;
                width: 0;
                height: 3px;
                @media (min-width: $mobile) {
                    background: $light-black;
                }
                position: absolute;
                bottom: 0px;
                -webkit-transition: width .3s;
                -moz-transition: width .3s;
                -o-transition: width .3s;
                transition: width .3s;
            }

            &.easy-in-button-left {
                &:after {
                    left: 0px;
                }
            }

            &.easy-in-button-right {
                &:after {
                    right: 0px;
                }
            }

            &:hover {
                cursor: pointer;

                @media (min-width: $mobile) {
                    background-color: rgba(220, 220, 220, 0.3);
                }

            }

            .tab-component__button__title {
                padding: 15px;
                background-color: rgba(220, 220, 220, 0.3);
                color: $light-black;
                font-family: $font-secondary;
                font-size: 13px;
                letter-spacing: 1.82px;
                line-height: 36px;
                text-transform: uppercase;
                font-weight: 900;

                @media (min-width: $mobile) {
                    background-color: transparent;
                }
            }

            &.tab-component__button--active {

                @media (min-width: $mobile) {

                    &:after {
                        width: 100%;
                    }

                    .tab-component__button__title {
                        background-color: transparent;
                    }

                }

            }

            &.easy-out-button {
                &:after {
                    background: transparent;
                }
            }

            @media (min-width: $mobile) {
                .tab-content-inside {
                    display: none;
                }
            }

        }

    }

    .tab-content {
        display: none;


        @media (min-width: $mobile) {
            &.tab-content--active {
                display: block;
            }
        }

    }

    &.enable-mobile {

        @media (max-width: $mobile) {

            .tab-component {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: scroll;

                li.tab-component__button {

                    text-align: center;

                    &:after {
                        background: $light-black;
                    }

                    &.easy-in-button-left {
                        &:after {
                            left: 0px;
                        }
                    }

                    &.easy-in-button-right {
                        &:after {
                            right: 0px;
                        }
                    }

                    &:hover {
                        background-color: rgba(220, 220, 220, 0.3);
                    }

                    .tab-component__button__title {
                        background-color: transparent;
                    }

                    &.tab-component__button--active {

                        &:after {
                            width: 100%;
                        }

                        .tab-component__button__title {
                            background-color: transparent;
                        }

                    }

                    .tab-content-inside {
                        display: none;
                    }

                }

            }

            .tab-content {

                &.tab-content--active {
                    display: block;
                }

            }

        }

    }

}