

@mixin recipeTag {
    background:  $dark-grey;
    color: $white; 
    display: inline-block;
    padding: 2px 8px;
}


@import "recipes-details"; 

//RECIPES LIST + stories-grid.scss
.recipe-list {
    .stories-grid {
        margin-top: 0;
        padding-top: 50px;
    }

    .storyItem__textBlock {
        ul {
            margin: 0;
            padding: 0;
            height: 72px;
            overflow: hidden;
            text-align: left;
            list-style: none;
            line-height: normal;

            display: flex;
            align-content: flex-end;
            flex-direction: row; 
            flex-wrap: wrap; 

            > :last-child {
                margin-top: auto;
            }
        }

        li {
            @include recipeTag;
            font-size: 0.8rem;
            margin: 2px 2px;
            white-space: nowrap;
        }
    }

    .storyItem__title {
        position: relative;
    }
}
 
.recipe-faceted-navigation {
    
    .default-section { //fix spazio bianco quando nessun risultato
        overflow: hidden;
    }

    .storyHeader__title {

        margin-bottom: 25px;
    
        @media (min-width: $desktop) {
            padding-top:130px;
            line-height: 70px;
        }
    }
} 

.faceted-navigation__filters {
    padding-bottom: 25px;

    > div {
        padding: 0 15px;
        width: 100%;
    
        @media (min-width: $tablet-P) {
            width: 50%;
        }  

        @media (min-width: $desktop) {
            width: calc(100%/3);
        } 
    }
 
        
 
}

.faceted-results {
    .selectedtags__link,
    .searchtags__link {
        @include recipeTag;
        line-height: normal;
        margin-right: 20px;

        i {
            padding-left: 5px;
        }
    }
}

