/*PADDING*/

@mixin paddingY($size:0){
    padding-top: map-get($paddingY,$size);
    padding-bottom: map-get($paddingY,$size);
}

@mixin pb($size:0){
    padding-bottom: map-get($pb,$size);
}

/*MARGIN*/

/*SPAZIATORI*/

@mixin space-l() {
    padding-left: 22px;
    @media (min-width: $tablet-P) {
        padding-left: 55px;
    }
    @media (min-width: $tablet-L) {
        padding-left: 105px;
    }
    @media (min-width: $desktop) {
        padding-left: 155px;
    }
}
@mixin space-r() {
    padding-right: 22px;
    @media (min-width: $tablet-P) {
        padding-right: 55px;
    }
    @media (min-width: $tablet-L) {
        padding-right: 105px;
    }
    @media (min-width: $desktop) {
        padding-right: 155px;
    }
}
@mixin space-h() {
    padding-left: 37px;
    padding-right: 37px;
    @media (min-width: $mobile) {
        padding-left: 70px;
        padding-right: 70px;
    }
    @media (min-width: $tablet-P) {
        padding-left: 70px;
        padding-right: 70px;
    }
    @media (min-width: $tablet-L) {
        padding-left: 120px;
        padding-right: 120px;
    }
    @media (min-width: $desktop) {
        padding-left: 170px;
        padding-right: 170px;
    }
}
