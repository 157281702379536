.stores-index {
  height: auto;
  overflow: hidden;
  @include paddingY(3);

  .grid {
    padding: 20px 0;

    .row {
      padding-bottom: 40px;

      .grid-element {
        padding: 20px 20px;
      }

      .grid-separator {
        border-bottom: 1px solid $white;
        display: block;
      }
    }

    h2 {
      font-family: Raleway, sans-serif;
      font-size: 32px;
      font-weight: 400;
      letter-spacing: 1.28px;
      line-height: 38px;
      margin-top: 50px;
    }

    h3 {
      font-family: 'Mulish', sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
