$square-side: 300px;

.documentViewer {
  display:flex;
  flex-direction: column;

  @media (min-width:$mobile) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-between;
  }
  @media (min-width:$tablet-P) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap:15px 15px;
    justify-content: unset;
  }
  @media (min-width:$desktop) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap:15px 15px;
    justify-content: space-between;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .documentViewer__document {
    padding-bottom: 15vw;

    @media (min-width: $mobile) {
      margin:0;
      padding-bottom: 5vw;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: calc(33% - 2em);
      margin:1em;
    }
  
  }

  .documentViewer__picture {
    > picture {
      height:75vw;
      margin-bottom: 10vw;
      display:flex;
      align-items: center;

      @media (min-width: $mobile) {
        max-height: 350px;
        margin-bottom: 2vw;
        height:$square-side;
      }
    }
  }

  .documentViewer__thumbnail {
    width: 100%;
    @media (min-width: $tablet-P) {
      max-width: 300px;
      max-height: 300px;
    }
  }

  .documentViewer__title {
    margin-bottom: 20px;
    font-family: $font-secondary;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.72px;
    line-height: 26px;
    text-transform: uppercase;
    @media (min-width: $desktop) {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: 1.28px;
      line-height: 38px;
      max-width:300px;
    }
  }
  
  .documentViewer__secondaryLink {
    //display: block;
    //height: 37px;
    //font-family: $font-secondary;
    //font-size: 10px;
    //font-weight: 500;
    //letter-spacing: 1.44px;
    //line-height: 36px;
    //text-align: left;
    //
    //@media (min-width:$tablet-L) {
    //  font-size: 12px;
    //}
  }
}



