.award{
	padding-top: 0px;
	padding-bottom: 0px;
	align-items: center;
	
	> div:first-of-type {
		padding-top: 25px;
		padding-bottom: 15px;
	}
	@media (min-width: $tablet-L) {
		padding-top: 75px;
		padding-bottom: 75px;
		> div:first-of-type {
			padding-bottom: initial;
		}
	}
	&__sliderNav{
		display: initial;
		font-family: $font-primary;
		font-weight: 400;
		line-height: 46px;
		@media (min-width: $tablet-L) {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			height: 85px;
			max-width: 940px;
			width: 100%;
			float: right;
			font-family: $font-primary;
			font-weight: 400;
			line-height: 46px;
		}
		&--yearText{
			min-width: 200px;
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: 1.44px;
			margin-bottom: 2px;
			@media (min-width: $tablet-L) {
				line-height: 0;
				margin-top: 40px;
				margin-bottom: auto;
			}
		}
		.nav {
			white-space: nowrap;
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			align-items: baseline;
			flex-wrap: nowrap;
			margin-bottom: 20px;
			@media (min-width: $tablet-L) {
				margin-bottom: auto;
			}
			.nav-item {
				display: inline-block;
				float: none;
				&:last-of-type > .nav-link {
					padding-right: 0px;
				}
				.nav-link {
					color: $light-grey;
					height: 43px;
					font-size: 14px;
					font-weight: 700;
					letter-spacing: -0.06px;
					cursor: pointer;
					@media (min-width: $tablet-L) {
						font-size: 22px;
						font-weight: 400;
						height: 73px;
					}
					&:hover{
						color: $white;
						opacity: 1;
					}
					&.active {
						color: $white;
						font-size: 40px;
						font-weight: 400;
						@media (min-width: $tablet-L) {
							color: $white;
							font-size: 60px;
							height: 73px;
						}
						&:hover{
							color: $white;
						}
					}
				}
			}
		}
		
	}
	&__gallery{
		position: relative;
		margin-bottom: 41px;
		@media (min-width: $desktop) {
			position: unset;
			margin-left: unset;
			margin-bottom: unset;
			min-height: 700px;
		}
		&--left{
			>div {
				float: right;
			}
		}
		&--right{
			>div {
				float: left;
			}
		}
		&--fullwidth{
			max-width: 100vw;
			@media (min-width: $desktop) {
				max-width: 100%;
			}
			>div{
				@media (min-width: $desktop) {
					max-width: 100%;
				}
			}
		}
	}
	&__slider{
		width: 100vw;
		max-width: 940px;
		float: unset;
		margin-left: -37px;
		@media (min-width: $tablet-P) {
			width: 100%;
			max-width: 940px;
			float: right;
		}
		.slick-slide {
			margin: 0 7px;
			@media (min-width: $tablet-L) {
				margin: auto;
			}
			img{
				width: 100vw;
				@media (min-width: $tablet-P) {
					width: auto;
				}
				
			}
			
		}
		ul.slick-dots {
			text-align: center;
			bottom: -30px;
			height: 30px;
			padding-left: 37px;
			padding-right: 37px;
			width: 100%;
			@media (min-width: $tablet-L) {
				text-align: left;
				padding-left: 0;
				padding-right: 0;
			}
			li {
				width: 102px;
				padding-right: 10px;
				padding-left: 0px;
				margin: 0;
				@media (min-width: $tablet-L) {
					max-width: 69px;
					padding-left: 0px;
					padding-right: 10px;
				}
				button {
					border-bottom: 3px solid $grey;
					width: 100%;
					@media (min-width: $tablet-L) {
						width: 100%;
					}
					&:before {
						content: "";
					}
				}
				&.slick-active {
					button {
						border-color: $white;
					}
				}
			}
		}
	}
	&__image{
		width: 940px;
	}
	&__link{
		margin-top: 50px;
		margin-left: 37px;
		@media (min-width: $tablet-P) {
			margin-top: 60px;
			margin-left: initial;
		}
		@media (min-width: $tablet-L) {
			float: right;
			margin-top: -15px;
			margin-left: initial;
		}
	}
}