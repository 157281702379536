.heroBanner {
    margin: 0 auto;

    .heroBanner__pv-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .newOverLay{
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        @media (min-width: $desktop) {
            width: 50%;
        }
    }
}

//HeroBannerComponent
$imageHeight:580px;

.heroBannerComponent {
    position: relative;
    .heroBannerComponent__image {
        width: inherit;
        position: relative;

        img {
            width: 100vw;
        }
    }

    .heroBannerComponent__textBlock {
        position: absolute;
        top: 0;
        z-index: 2;
        height: inherit;
        width: 100%;
        @media (min-width: $tablet-L) {
            min-width: 780px;
            width: 338px;
            height: 100%;
        }
    }
    .heroBannerComponent__background {
        max-width: unset;
        position: absolute;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        height: auto;

        @media (min-width: $tablet-P) {
            min-width: 50vw;
            padding-right: 30px;
            position: initial;
            height: 100%;
        }

        @media (min-width: $desktop) {
            //min-width: 780px;
            padding-left: 0;
            padding-right: 170px;
        }
    }

    .heroBannerComponent__textAlign {
        width: 100%;
        padding-top: 0px !important;
        padding-bottom: 20px;

        @media (min-width: $desktop) {
            padding-top: 100px;
            padding-bottom: unset;
        }

        @media (min-width: $desktop-XL) {
            padding-top: 37px;
        }
    }

    .heroBannerComponent__topic {
        display: none;

        @media (min-width: $tablet-P) {
            display: block;
        }
    }

    .heroBannerComponent__title {
        font-size: 26px;
        padding-top: 50px;
        line-height: 42px;
        font-weight: 200;

        @media (min-width: $desktop) {
            font-size: 40px
        }

        @media(min-width: $desktop-XL) {
            margin-top: 0px;
        }
    }

    .heroBannerComponent__subTitle {
        display: none;

        @media (min-width: $tablet-L) {
            display: block;
            width: 60%;
            max-width: 470px;
        }
    }
}

.heroBannerFullScreen {
    max-width: unset;
    padding-bottom: 60px;

    @media (min-width: $desktop) {
        padding-bottom: 75px;
    }

    position: relative;

    .heroBannerFullScreen__sectionImage {
        width: 100vw;
        max-width: 100%;
        height: 349px;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: center;

        @media (min-width: $tablet-P) {
            width: inherit;
        }
        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            min-height: 800px;
        }
    }

    .heroBannerFullScreen__image {
        width: 100%;
        position: absolute;
    }

    .heroBannerFullScreen__background {
        height: 349px;
        background-color: rgba(0, 0, 0, 0.0);
        max-width: fit-content;

        @media (min-width: $tablet-P) {
            height: 580px;
            background-color: rgba(0, 0, 0, 0.5);
        }

        @media (min-width: $desktop) {
            height: 580px;
            max-width: 810px;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    .heroBannerFullScreen__textBlock {
        @include container;
        position: absolute;
        bottom: 0;
        left: 0;

        @media (min-width: $tablet-P) {
            width: 100%;
            min-width: 338px;
            max-width: 500px;
            position: initial;
            height: 100%;
        }

        div {
            width: 100%;

            @media (max-width: $desktop-XL) and (orientation: portrait) {
                width: 100%;
            }

            @media (max-width: $desktop-XL) and (orientation: landscape) {
                max-width: $textBlock-width;
                width: 100%;
            }

            @media (min-width: $desktop) {
                max-width: $textBlock-width;
            }

            @media (min-width: $desktop-XL) {
                max-width: 580px;
            }
        }
    }


    .heroBannerFullScreen__topic,
    .heroBannerFullScreen__title,
    .heroBannerFullScreen__subTitle,
    .action-link{
        position: relative;
        z-index: 11;
        @media (min-width: $desktop) {
            position: inherit;
            z-index: 1;
        }
    }

    .heroBannerFullScreen__background--black,
    #slick-slide14,
    .newOverLay{
        .heroBannerFullScreen__textBlock {
            width: 100% !important;
            max-width: 100%;
            background-color: rgba(0, 0, 0, .4);

            @media(min-width: $desktop) {
                max-width: 50%;
            }

            .heroBannerFullScreen__title {
                width: 338px;
            }
        }
    }

    .heroBannerFullScreen__textAlign {
        height: fit-content;
        z-index:2;
    }

    .heroBannerFullScreen__title {
        margin: auto 0 auto 0;
        font-family: $font-secondary;
        letter-spacing: 1px;
        font-size: 26px;
        padding-top: 30px;
        line-height: 42px;
        font-weight: 200;

        @media (min-width: $tablet-P) {
            padding: unset;
            padding-bottom: 20px;
        }

        @media (min-width: $desktop) {
            font-size: 40px;
        }

        @media (min-width: $desktop-XL) {
            padding-top: 0px;
            padding-bottom: 36px;
            font-weight: 200;
            line-height: 42px;
            margin-top: 0px;
        }

    }

    .heroBannerFullScreen__subTitle {
        width: 100%;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.64px;
        line-height: 30px;
        margin: auto 0 auto 0;
        display: none;

        @media (min-width: $tablet-P) {
            width: auto;
            display: inherit;
        }

        @media (min-width: $desktop) {
            width: auto;
        }

        @media (min-width: $desktop-XL) {
            width: 492px;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 15px;
        right: 0;

        @media (min-width: $tablet-L) {
            width: 33%;
            text-align: left;
            bottom: $dots--pb;
        }

        li {
            button {
                border-bottom: 3px solid $grey;
                width: 100%;

                &:before {
                    content: "";
                }

            }

            &.slick-active {
                button {
                    border-color: $white;
                }
            }
        }
    }
}

.heroBannerFullWidth {
    .slick-dots {
        padding-left: unset;
        left: 0;
        right: unset;
        bottom: 30px;

        @media (min-width: $tablet-P) {
            padding-left: 170px;
            bottom: 120px;
        }
    }

    &.slick-dotted {
        &.slick-slider {
            margin-bottom: 0;
        }
    }
}

$fullImageHeight:calc(100vh - 160px);

.heroBannerFullScreen {
    padding-bottom: 0;

    a {
        margin-top: 15px !important;

        @media(min-width: $tablet-P) {
            margin-top: 0px !important;
        }

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            display: table-header-group;
        }

    }

    &__background {
        height: 100%;
    }

    &__sectionImage {
        height: 100vh;
    }

    .slick-dots {
        width: 100%;
        text-align: center;
        padding-left: unset;
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0px;
    }

    .image {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column-reverse;

        img {
            object-fit: cover;
            height: auto;
            width: 100%;
        }

        @media (min-width: $desktop) {
            height: auto;
            position: relative;
            width: 100%;
            min-height: 620px;

            img {
                min-height: 620px;
                object-fit: cover;
                height: auto;
                width: 100%;

                @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    height: auto;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
    }
    $fullVideoHeight: calc(100vw * 0.496);
    .heroBannerFullScreen__video {
        width: 100%;
        height: 533px;
        display: flex;
        flex-direction: column-reverse;
        object-fit: cover;

        img {
            object-fit: cover;
            width: 100%;
        }

        @media (min-width: $desktop) {
            height: $fullVideoHeight;
            max-height: unset;
            position: relative;
            width: 100%;

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                height: auto;
            }

            img {
                object-fit: cover;
                height: $fullVideoHeight;
                width: 100%;

                @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    height: auto;
                }
            }

        }

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            iframe {
                width: 100%;
                height: $fullImageHeight;
            }
        }
    }

    .heroBannerFullScreen__textBlock {
        @include container;
        position: absolute;
        height: 100%;
        display: flex;
        align-items: unset;
        padding-top: 0px;
        padding-bottom: 100px;

        @media (min-width: $desktop) {
            align-items: center;
        }

        .heroBannerFullScreen__topic {
            display: block;
            height: 36px;
            font-family: $font-secondary;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.44px;
            line-height: 36px;
            padding-top: 50px;

            @media (min-width: $tablet-P) {
                padding-top: 10px;
            }

            @media (min-width: $desktop) {
                display: initial;
                padding-top: 0;
            }
        }

        .background {
            position: absolute;
        }
    }
}

.heroBannerBlocks {
    padding-top: $text--pt;
    position: relative;
    display: flex;

    @media (min-width: $tablet-P) {
        padding-top: 100px;
    }

    @media (min-width: $tablet-L) {
        padding-top: 100px;
        height: 350px;
        margin-bottom: 100px;
    }

    @media (min-width: $desktop) {
        padding-top: 100px;
        height: 400px;
        margin-bottom: 70px;
    }

    @media (min-width: $desktop-XL) {
        height: 580px;
        padding-bottom: 100px;
        margin-bottom: 130px;
    }

    .centerBlock {
        display: inline-block;
    }

    .heroBannerBlocks__row {}

    .heroBannerBlocks__info {
        padding-bottom: 30px;

        @media (min-width: $tablet-L) {
            margin-top: 50px;
            padding-bottom: 50px;
        }

        @media (min-width: $desktop-XL) {
            margin-top: 100px;
            padding-bottom: 50px;
        }
    }

    .heroBannerBlocks__info__title {
        max-width: 517px;
        font-family: $font-secondary;
        font-size: 26px;
        font-weight: 300;
        line-height: 40px;
        text-transform: uppercase;

        @media (min-width: $tablet-P) {
            padding-left: unset;
            padding-right: unset;
        }

        @media (min-width: $desktop) {
            font-size: 40px;
        }

        @media (min-width: $desktop-XL) {
            line-height: 60px;
        }
    }

    .heroBannerBlocks__info__subTitle {
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.64px;
        line-height: 30px;
    }

    .heroBannerBlocks__info__category {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: $white;
        font-family: $font-secondary;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 1.82px;
        line-height: 36px;
        margin-bottom: 10px;

        @media (min-width: $tablet-P) {
            padding-left: unset;
            padding-right: unset;
        }

        @media (min-width: $desktop) {
            font-size: 16px;
            letter-spacing: 2.24px;
        }

        span {
            display: inline-block;
            margin-right: 19px;
            line-height: 20px;
            border-top: 1px solid $white;
            width: 39px;
        }

    }

    .heroBannerBlocks__sectionImage {
        @media (min-width: $tablet-L) {
            margin-top: 30px;
        }

    }

    .heroBannerBlocks__image {
        margin-bottom: -1px;

        @media (max-width: $mobile) {
            width: 100vw;
            margin-left: -37px;

            picture,
            img {
                width: 100vw;

            }
        }

        @media (min-width: $tablet-L) {
            margin-bottom: -10%;
        }

        >img {
            max-height: none;

            @media (min-width: $tablet-L) {
                max-height: 520px;
            }
        }
    }
}

.default-section{
    .heroBannerBlocks {
        .heroBannerBlocks__info__category {
            color: $t-color-primary;
            span{
                border-top-color: $el-color-primary;
            }
        }
    }
}

.heroBannerUnderlined {
    .heroBannerUnderlined__content {
        padding-bottom: 0px;
        padding-top: 0px;

        @media (min-width: $desktop-XL) {
            padding-top: 100px;
        }

        .heroBannerUnderlined__text {
            position: relative;

            @media (min-width: $tablet-L) {
                padding-left: 85px;
                padding-right: 85px;
            }

            .heroBannerUnderlined__text__title {
                font-family: $font-secondary;
                letter-spacing: 0.28px;
                margin-bottom: 15px;
                padding-bottom: 0px;
                font-size: 26px;
                padding-top: 50px;
                line-height: 42px;
                font-weight: 200;

                @media (min-width: $tablet-L) {
                    letter-spacing: .7px;
                    line-height: 70px;
                    margin-bottom: 25px;
                    font-weight: 200;
                    margin-top: 100px;
                    padding-top: 20px;
                }

                @media (min-width: $desktop) {
                    font-size: 40px
                }

                @media(min-width: $desktop-XL) {
                    line-height: 42px;
                    margin-top: 0px;
                }
            }

            .heroBannerUnderlined__text__subTitle {
                font-family: $font-primary;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.12px;
                line-height: 18px;

                @media (min-width: $tablet-L) {
                    font-size: 18px;
                    letter-spacing: 0.18px;
                    line-height: 30px;
                }
            }

            .heroBannerUnderlined__text__line {

                @media (min-width: $tablet-L) {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    bottom: 0px;
                    width: 2px;
                }

                &--white {
                    background-color: $white;
                }

                &--black {
                    background-color: $black;
                }

            }

        }

    }

    .heroBannerUnderlined__text{
        .heroBannerUnderlined__text__subTitle{
            p{
                display: none ;
            }
            p:first-child{
                display: block;
            }
        }
        &.collapsed {
            .heroBannerUnderlined__text__subTitle {
                p {
                    display: none;
                }

                p:first-child {
                    display: block;
                }
            }
        }
        &.expanded{
            .heroBannerUnderlined__text__subTitle{
                p{
                    display: block ;
                }
                p:first-child{
                    display: block;
                }
            }
        }
    }

    .btn__showMore a{
        display:none;
        padding-top:$text--pt;
        padding-bottom:$text--pt;
    }
    .collapsed{
        .heroBannerUnderlined__text__subTitle{
            max-height:none;
        }
        a.btn__showMore__label{
            &.show{
                display: inline-block;
            }
        }
    }
    .expanded{
        .heroBannerUnderlined__text__subTitle{
            max-height: none;
        }
        a.btn__showMore__label{
            &.show{
                display:inline-block;
            }
        }
    }
}

.cmp_heroBanner {
    .slick-dotted.slick-slider {
        margin: 0;
 
        .slick-dots {
            bottom: 0;
        }
    }
}

.more-cta {
    display: flex;

    @media (max-width: $tablet-P) {
        flex-wrap: wrap;
    }

    .action-link.more-one {
        //border: 2px solid;
        text-align: center;
        margin-right: 20px;
        padding: 10px 20px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff; 

        @media (max-width: $tablet-P) {
            margin: 0 auto;
            margin-bottom: 20px;
            max-width: 310px;
        }
       

        &:before {
            display: none;
        }
    }
}