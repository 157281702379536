%ltr-text {
    direction: ltr;
    text-align: inherit;
}
%rtl-text {
    direction: rtl;
    text-align: right;
}
.rightToLeft {
    body,
    p,
    div,
    h5 {
        @extend %rtl-text;
    }

    .desktop-menu {
        div,
        ul,
        li,
        p {
            @extend %ltr-text;
        }
    }

    /* Components */
    .heroBanner,
    .cmp_heroBanner {
        &.tpl_fullScreen,
        &.tpl_heroBannerDefault {
            .textBlock {
                @media (min-width: $desktop) {
                    flex-direction: row-reverse;
                }
            }
        }

        &.tpl_textFocus {
            .textBlock:before {
                left: unset;
                right: 0;
            }
        }

        &.tpl_heroBannerDefault {
            .textBlock {
                left: 0;
                @media (min-width: $tablet-L) {
                    padding-right: 0%;
                }
            }

            .background {
                padding-right: 0;
                @media (min-width: $tablet-L) {
                    padding-left: 170px;
                }
            }
        }

        .newOverLay {
            left: 0;
        }

        .slick-list,
        .slick-dots {
            direction: ltr;
        }
    }

    .visualBox,
    .cmp_visualBox {
        direction: rtl;

        .visualBox__gallery > div {
            float: left;
        }

        .visualBox__gallery--right > div {
            float: right;
        }

        .visualBoxSlider {
            direction: ltr;

            .slick-list,
            .slick-dots {
                direction: ltr;
            }
        }
    }

    .newslist-preview {
        .slick-list {
            direction: ltr;
        }

        .storyItem__textBlock {
            text-align: center;
        }
    }

    .cmp_generalText {
        &.tpl_focus {
            p,
            .text {
                font-size: inherit;
            }
        }
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        direction: ltr;
    }

    .historyComponent .slider__item:first-of-type .historyComponent__slide:first-of-type .historyComponent__textBlock {
        left: unset;
        right: 0;
    }

    .historyComponent {
        .swiper-button-prev{
            right:20px
        }
        .swiper-button-next{
            right: calc(100% - 70px);
        }
    }

    /* Old Components */
    .aesthetic-sliders{
        .aesthetic-slider__textBlock{
            @media (min-width: $desktop) {
                right: 50%;
            }
        }
    }

    //burger-menu
    .hamburger-menu{
        margin-right: 10px;
        margin-left: 0;
    }
    //burger-menu.scss 102
    .menu-container .menu-list {
        padding-right: 55px;
        ul.menu-submenu{
            padding-right: 0;
        }
    }
    //burger-menu.scss 155
    .accordion-toggle .menu-link:after,
    .accordion-toggle .menu-link:before {
        right: -25px;
    }

    //footer
    //footer.scss 317
    .disclaimer-nav {
        padding-left: 0;
        .nav-item{
            padding-right: 0;
            padding-left: 20px;
        }
        .nav-item:not(:first-child):before {
            right: 8px;
        }
    }
    .footer .footer__columns--bottomLeft div p{
        padding-right: 0;
    }

    //product list
    .products-list__item__title {
        @extend %rtl-text;
    }
    //product-overview.scss 166
    .products-overview-page
    .products-list:not(.js-product-list-slider)
    .products-list__item
    .products-list__item__title {
        text-align: right;
    }
    //products-manager.scss 1
    .products-manager-page{
        .products-manager-page__content
        .products-manager-page__content__action-link {
            display: inline-flex;
        }
        .products-manager-page__slider{
            .slick-list{
                direction: ltr;
            }
        }
    }

    //filters
    .filters {
        .filters__item {
             
            .filters__item__header,
            .form-check {
                padding-left: 0;
                padding-right: 30px;
            }

            .filters__item__header:before,
            .filters__item__header:after,
            .form-check input[type=checkbox]{
                left: unset;
                right: 5px;
                margin-right: unset;
            }
        }
    }

    //hero-banner.scss 619
    .heroBannerUnderlined
    .heroBannerUnderlined__content
    .heroBannerUnderlined__text
    .heroBannerUnderlined__text__line {
        left: unset;
        right: 0;
    }

    //visual-box.scss
    .visualBoxSlider__gallery {
        direction: unset;
    }

    //bannerSlider
    .bannersSlider{
        div{
            direction: ltr;
        }
        .slick-dots{
            direction: ltr;
        }
    }

    .cmp_cards {
        &.cards-minimal .cards__textBox:before {
            left: unset;
            right: -50px;
        }
        &.tpl_minimal .listingItems .cardItem .textBlock:before{
            left: unset;
            right: -25px;
            @media (min-width: $tablet-L) {
                left: unset;
                right: -50px;
            }
        }
        &.tpl_twoColumn{
            .textBlock{
                @media (max-width: $tablet-L) {
                    .title,
                    .cta {
                        text-align: center;
                    }
                }

            }
        }
    }
    .productsPreview{
        .title,
        .image{
            text-align: center;
        }
    }

    //animations.scss 15
    .action-link:before {
        margin-right: unset;
        margin-left: 20px;
    }

    //slick.js
    .slick-list,
    .slick-track,
    .slick-dots {
        @extend %rtl-text;
    }

    //main.css 8310
    .product-detail .product-detail__content .detail-list__list {
        padding-right: 0;
    }

    .privacy-policy .form-check-label{
        margin-right: 25px;
    }
}

/* Israele */

html[data-channel="smeg-israel-he"],
.rightToLeft{
    /* tutti i paragrafi con font 18px */
    p,
    .text {
        font-size: 18px;
    }
    /* END tutti i paragrafi con font 18px */
}