@mixin a-symmetric-format($m-b:"", $f-size:"", $l-height:"") {
  margin-bottom: $m-b;
  font-size: $f-size;
  line-height: $l-height;
}

.generalText {
  position: relative;
  padding-top: 35px;
  padding-bottom: 35px;
  @media (min-width: $desktop) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .generalText__title {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    margin-bottom: 25px;
    @media (min-width: $desktop) {
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 50px;
    }
  }
  &.generalText--focus {
    .text-black{
      p{
        &:before {
          border: 1px solid $black;
        }
      }
    }
  }
  .generalTextFocus{
    &__textBlock{
      font-family: $font-secondary;
      font-size: 30px;
      font-weight: 300;
      line-height: 40px;
      position: relative;
      p {
        quotes: "“" "”" "‘" "’";
        font-family: $font-secondary;
        font-size: 26px;
        line-height: 34px;
        position: relative;
        @media (min-width: $tablet-P) {
          font-size: 30px;
          font-weight: 300;
          line-height: 40px;
        }
  
        max-width: 715px;
        margin: 0 auto;
        text-align: center;
        
        &:before {
          content: "";
          left: 50%;
          top:-20px;
          transform: translateX(-50%);
          font-size: 86px;
          display: inline-block;
          position: absolute;
          width: 177px;
          height: 1px;
          border: 1px solid;
        }
      }
    }
  }
  &.generalText--quote {
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    font-family: $font-secondary;
    .generalTextQuote__textBlock{
      position: relative;
      p {
        quotes: "“" "”" "‘" "’";
        font-family: $font-tertiary;
        font-size: 26px;
        font-style:italic;
        line-height: 34px;
        position: relative;
        @media (min-width: $tablet-P) {
          font-size: 1.5rem;
          font-weight: 300;
          line-height: 40px;
        }
      
        max-width: 715px;
        margin: 0 auto;
      
        &:before, &:after {
          font-size: 50px;
          font-family: $font-tertiary;
          font-style: italic;
          display: inline-block;
          position: absolute;
          opacity: .23;
        }
        &:before {
          content: open-quote;
          left: 45%;
          top:-20px;
          transform: translateX(-50%);
          @media (min-width: $desktop) {
            left: unset;
            top:0%;
            margin-left: -25px;
          }
        
        }
        &:after {
          content: close-quote;
          left: unset;
          top:calc(100% + 30px);
          transform: translateX(-50%);
          @media (min-width: $desktop) {
            transform: translateX(0%);
            top:100%;
          }
        
        
        
        }
      }
    
    }

    .generalText__divisor {
      width: 10%;
      min-width: 50px;
      height: 2px;
      border-top: 2px solid $grey;
      border-radius: 2px;
      position: absolute;
      left: 50%;

      &.generalText__divisor--top {
        top: 0px;
      }

      &.generalText__divisor--bottom {
        bottom: 0px;
      }

    }

  }

}

.symmetricText {
  &__title{
    max-width: 585px;

    h4 {
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.72px;
      line-height: 26px;
      text-transform: uppercase;
      margin-bottom: 15px;
      @media (min-width: $desktop) {
        font-family: $font-secondary;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
      }
    }
  }

  &__textBody {
    @media (min-width: $tablet-P) {
      column-gap: 36px;
      column-count: 2;
      -webkit-column-fill: balance;
      -moz-column-fill: balance;
      column-fill: balance;
    }

    p {
      font-family: $font-primary;
      font-size: 16px;
      letter-spacing: 0.32px;
      line-height: 24px;
      margin-bottom: 36px;

      @media (min-width: $tablet-L) {
        font-family: $font-primary;
        font-size: 16px;
        letter-spacing: 0.32px;
        line-height: 34px;
        margin-bottom: 36px;
      }

      &:last-child {
        margin-bottom: 0px
      }
    }
  }
}

.asymmetricText {
  .asymmetricText__textBlock{
    @media (min-width: $desktop) {
      justify-content: space-between;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex-wrap: initial;
    }

  }
  
  .asymmetricText__title {
    max-width: 585px;

    h4 {
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.72px;
      line-height: 26px;
      text-transform: uppercase;
      margin-bottom: 15px;
      @media (min-width: $desktop) {
        font-family: $font-secondary;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
      }
    }
  }
  
  .asymmetricText__textBody {
    font-family: $font-secondary;
    font-size: 16px;
    float: unset;
    @media (min-width: $desktop) {
      max-width: 940px;
      width: 100%;
      float: right;
    }
    ol{
      font-family: $font-secondary;
      li{
        font-family: $font-primary;
      }
    }

    h6 {
      margin-bottom: 5vw;

      @media (min-width: $tablet-P) {
        margin-bottom: 2vw
      }

      @media (min-width: $tablet-L) {
        margin-bottom: initial;
      }
    }

    p, a {
      line-height: 34px;
    }
    p {
      font-family: $font-primary;
      font-size: 16px;
      line-height: 24px;
      @media (min-width: $desktop) {
        font-family: $font-primary;
        font-size: 16px;
        letter-spacing: 0.32px;
        line-height: 34px
      }
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    strong{
      font-family: $font-secondary;
    }

    span {
      border-bottom: .5px solid $white;
      width: 39px;
    }
    .action-link{
      margin-top: 34px;
    }

  }
}

.asymmetricTwoColumnText{

  &__textBody{
    @media (min-width: $desktop) {
      -moz-column-gap: 36px;
      column-gap: 36px;
      -moz-column-count: 2;
      column-count: 2;
      -moz-column-fill: balance;
      column-fill: balance;
    }
    
  }
  
}