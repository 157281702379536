.store-map {
  height: 345px;
  @media (min-width: $desktop) {
    height: unset;
    aspect-ratio: 3.3/1;
  }
}

.store-header__title {
 
  @media (min-width: $tablet-P) {
    padding-top: 60px;
  }

  @media (min-width: $tablet-L) {
    padding-top: 130px;
  }

  img, h4 {
    display: inline;
    vertical-align: middle;
  }

  img {
    height: 13px;
    width: 13px;

    @media (min-width: $tablet-L) {
      height: 18px;
      width: 18px;
    }
  }

  h4 {
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    text-transform: uppercase;

    @media (min-width: $tablet-L) {
      font-size: 19px;
      line-height: 24px;
    }
  }

  h1 {
    font-family: $font-secondary;
    font-size: 26px!important;
    font-weight: 300;
    line-height: 42px;
    margin-bottom: 10px;

    @media (min-width: $tablet-P) {
      margin-bottom: 10px;
    }

    @media (min-width: $desktop) {
      font-size: 50px!important;
      line-height: 80px;
      margin-bottom: 10px;
    }
  }
}

.store-header {
  color: $black;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  padding-top: 40px;

  @media (min-width: $tablet-P) {
    grid-template-columns: 1fr 1fr;

  }

  &__description {
    grid-column: 1;
    grid-row: 2;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    @media (min-width: $tablet-P) {
      grid-column: 1;
      grid-row: 2;
      letter-spacing: 0.32px;
      line-height: 34px;
    }
  }

  &__info {
    grid-column: 1;
    grid-row: 3;

    @media (min-width: $tablet-P) {
      grid-column: 2;
      grid-row: 2 / 4;
    }

    &.fix {
      @media (min-width: $tablet-P) {
        grid-column: 1;
      }
    }

    > div {
      background-color: $dusty-grey;
      padding: 59px 30px 20px;

      @media (min-width: $desktop) {
        padding: 62px;
      }

      > div {
        padding: 30px 0;
      }
    }

    &__contact {

      span {
        display: block;
      }

      a {
        text-decoration: underline;
      }

      span, a {
        font-family: 'Mulish', sans-serif;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.64px;
        line-height: 30px;
      }
    }

    &__worktime,
    &__legal {

      h6 {
        font-family: Raleway, sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.68px;
        line-height: 16px;
        margin-bottom: 0.05rem;
      }

      span {
        display: block;
        font-family: 'Mulish', sans-serif;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.64px;
        line-height: 30px;
      }
    }
  }

  &__content {
    grid-column: 1;
    grid-row: 4;

    h4 {
      font-family: Raleway, sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 58px;
      width: 100%;
      margin-top: 20px;
      border-bottom: $black 1px solid;

      @media (min-width: $tablet-P) {
        font-size: 30px;
        font-weight: 400;
        line-height: 58px;
      }
    }

    span {
      font-family: 'Mulish', sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 40px;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0 8px 0 0;
    }

    @media (min-width: $tablet-P) {
      grid-column: 1;
      grid-row: 2/4;
      width: 80%;
    }
  }
}

.store-details {
  color: $black;
 
  .newslist-preview {

    h4 {
      font-family: Raleway, sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 58px;

      @media (min-width: $tablet-P) {
        font-size: 30px;
        font-weight: 400;
      }
    }
  }
}
