.container-fluid {
    padding: 0px;
    overflow: hidden;
}

.container, %container{
    max-width: 100%;
    padding-left: 37px;
    padding-right: 37px;
    @media (min-width: $tablet-P) {
        padding-left: 70px;
        padding-right: 70px;
    }
    @media (min-width: $tablet-L) {
        padding-left: 120px;
        padding-right: 120px;
    }
    @media (min-width: $desktop) {
        max-width: 1920px;
        padding-left: 170px;
        padding-right: 170px;
    }
}

.container-m , %container-m {
    @extend %container;
    @media (min-width: $tablet-P) {
        padding-left: 135px;
        padding-right: 135px;
    }
    @media (min-width: $tablet-L) {
        padding-left: 230px;
        padding-right: 230px;
    }
    @media (min-width: $desktop) {
        padding-left: 310px;
        padding-right: 310px;
    }
}

@mixin container() {
    max-width: 100%;
    padding-left: 37px;
    padding-right: 37px;
    @media (min-width: $tablet-P) {
        padding-left: 70px;
        padding-right: 70px;
    }
    @media (min-width: $tablet-L) {
        padding-left: 120px;
        padding-right: 120px;
    }
    @media (min-width: $desktop) {
        padding-left: 170px;
        padding-right: 170px;
    }
}
.container-m , %container-m {
    @include container;
    @media (min-width: $tablet-P) {
        padding-left: 135px;
        padding-right: 135px;
    }
    @media (min-width: $tablet-L) {
        padding-left: 230px;
        padding-right: 230px;
    }
    @media (min-width: $desktop) {
        padding-left: 310px;
        padding-right: 310px;
    }
}

@mixin container-m() {
    @include container;
    @media (min-width: $tablet-P) {
        padding-left: 135px;
        padding-right: 135px;
    }
    @media (min-width: $tablet-L) {
        padding-left: 230px;
        padding-right: 230px;
    }
    @media (min-width: $desktop) {
        padding-left: 310px;
        padding-right: 310px;
    }
}

.container-lg, %container-lg {
    @include container;
    @media (min-width: $tablet-P) {
        padding-left: 135px;
        padding-right: 135px;
    }
    @media (min-width: $tablet-L) {
        padding-left: 230px;
        padding-right: 230px;
    }
    @media (min-width: $desktop) {
        padding-left: 250px;
        padding-right: 250px;
    }
}

@mixin container--disabled() {
    padding-left: 0;
    padding-right: 0;
}

//container-xl-down-0
.container-xl-d-0{
     @include container;
     @media (max-width: $desktop) {
         padding-left: 0;
         padding-right: 0;
     }
 }
//container-xl-up-0
.container-xl-u-0{
    @include container;
}
.container-sm-0{
    @include container;
    max-width: $max-screen-size;
    margin:0 auto;
    @media (max-width: $mobile) {
        padding-left: 0;
        padding-right: 0;
    }
}
.container-sm{
    @media (max-width: $mobile) {
        padding-left: 37px;
        padding-right: 37px;
    }
}
