.title {
    @include newtitle();
    @include pb(2);
    @include font(raleway);
    font-weight: 400;
    line-height: 1.3;
}

.title_2 {
    @extend .title;
    @include newtitle(sm);
    text-transform: uppercase;
}
.title_3 {
    @extend .title;
    @include newtitle(lg);
    font-weight: 300;
}
.title_4 {
    @extend .title;
    @include newtitle(xs);
    font-weight: 300;
}
.title_quote {
    p {
        @include newtitle(md);
        @include font(didot);
        font-weight: 300;
        text-transform: initial;
        font-size: 1.5rem;
        line-height: 1.3;
        @media (min-width: $desktop) {
            line-height: 1.5;
        }
    }
}

.title_focus {
    p {
        @include newtitle(md);
        @include font(raleway);
        font-weight: 300;
        line-height: 1.4;
        text-transform: initial;
        @media (min-width: $desktop) {
            line-height: 1.3;
        }
    }
}


.title-sm {
    @include newtitle(sm);
}

.title-md {
    @include newtitle(md);
}

.title-lg {
    @include newtitle(lg);
}

//CLASSI
.subTitle{
    @include subTitle(md);
    @include pb(2);
    font-weight: 300;
}
.c-sm{
    @include subTitle(xs);
}
.subTitle_2{
    @include subTitle(sm);
}
.subTitle-md{
    @include subTitle(md);
}
.subTitle-lg{
    @include subTitle(lg);
}


//CLASSI
.text {
    @include text(md);
    @include font(Mulish);

    font-weight: 300;
    letter-spacing: .32px;
}

.text-sm {
    @include text(sm);
}

.text-md {
    @include text(md);
}

.text-lg {
    @include text(lg);
}

.text-preline {
    white-space: pre-line;
}

.text-raleway{
    font-family: 'Raleway', sans-serif;
}

.component{
    .textBlock{
        .text{
            a:not(.action-link){
                text-decoration: underline;
            }
        }
    }
}


//CLASSI
.subText {
    @include subText();
}

.subText-sm {
    @include subText(sm);
}

.subText-md {
    @include subText(md);
}

.subText-lg {
    @include subText(lg);
}

/*TITOLI DI LISTING*/

//wrapper-text
.wrapper-text{
    padding-right:15px;
    padding-left:15px;
    width: 100%;
}

//link-cta
.link-cta{
    display: inline-block;
    text-transform: uppercase;
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.44px;
    line-height: 20px;
    &:before{
        content: "";
        display: inline-block;
        margin-right: 20px;
        line-height: 20px;
        border-top: 1px solid $white;
        width: 40px;
        vertical-align: middle;
        transition: all 1s ease-in-out;
    }
    &:hover {
        text-decoration: none;

        &:before {
            margin-right: 30px;
            width: 50px;
            transition: all 1s ease-in-out;
        }
    }
}

//link-button
.link--btn{
    font-weight: bold;
    padding: 16px 10px;
    cursor: pointer;
    width: 100%;
    min-width: fit-content;
    max-width: 350px;
    text-align: center;
    display: block;
    line-height: 24px;
    &:hover{
        color: $light-grey;
        text-decoration: none!important;
    }
    .cmp_productManager &,
    .bg-alternative &{
        color: $t-color-primary;
        background-color:$bg-color-primary;
        &:hover{
            color: $light-grey;
            text-decoration: none!important;
        }
    }
    .foodservice & {
        color: $white;
        background-color: $btn-color-primary;
    }
    .bg-default &{
        color: $t-color-secondary;
        background-color:$bg-color-secondary;
        .instrument & {
            color: $white;
            background-color:$el-color-primary;
        }
    }
}

.js_addToCart{
    cursor: pointer;
}

/* typography reset */
h1, h2, h3,
h4, h5, h6 {
    &:not([class]) {
        padding-bottom: .5em;
        @media (min-width: $tablet-L) {
            padding-bottom: .8em;
        }
        @media (min-width: $desktop) {
            padding-bottom: 1em;
        }
    }
}
h1:not([class]) {
    font-size: 1.8em;
    @media (min-width: $tablet-L) {
        font-size: 1.9em;
    }
    @media (min-width: $desktop) {
        font-size: 2em;
    }
}

h2:not([class]) {
    font-size: 1.3em;
    @media (min-width: $tablet-L) {
        font-size: 1.4em;
    }
    @media (min-width: $desktop) {
        font-size: 1.5em;
    }
}

h3:not([class]) {
    font-size: 1.13em;
    @media (min-width: $tablet-L) {
        font-size: 1.15em;
    }
    @media (min-width: $desktop) {
        font-size: 1.17em;
    }
}

h4:not([class]) {
    font-size: .9em;
    @media (min-width: $tablet-L) {
        font-size: .95em;
    }
    @media (min-width: $desktop) {
        font-size: 1.00em;
    }
}

h5:not([class]) {
    font-size: .75em;
    @media (min-width: $tablet-L) {
        font-size: .8em;
    }
    @media (min-width: $desktop) {
        font-size: .83em;
    }
}

h6:not([class]) {
    font-size: .6em;
    @media (min-width: $tablet-L) {
        font-size: .65em;
    }
    @media (min-width: $desktop) {
        font-size: .67em;
    }
}

/* fine typography reset */
