.cmp_relatedByLook {
  .cmp-wrap {
    align-items: center;
  }

  .isOutOfStock {
    font-size: 16px;
    @media(min-width: 1200px) and (max-width: 1535px){
      font-size: 12px;
    }
  }

  .banner-look-title {
    text-align: center;
    padding: 10px 0;
//     @media(min-width: 1200px) {
//       position: absolute;
//       top: 0;
//       width: 100%;
//       padding: 0;
//       display: block;
//     }
  }

  .mobile-only{
    display: none;
    @media(max-width: 1200px){
        display: block;
    }
  }

  .desktop-only{
    display: none;
    @media(min-width: 1200px){
        display: block;
    }
  }

//   .col-lg-6.textBlock {
//        aspect-ratio: 931/669;
//     @media(min-width: 1200px) {
//       align-items: center;
//       display: flex;
//     }
//   }

  .product-preview__desktop {
    display: none;
    @media(min-width: 1200px) {
      display: block;
    }
  }
  .product-preview__mobile {
    display: none;
    @media(min-width: 1200px) {
      display: none;
    }
  }
	.cmp_relatedItems {
		width: 50%;
		margin: 0 auto;
    @media(min-width: 1200px) {
      margin-left: 5%;
    }

    .product-content {
      @media(min-width: 1200px) {
        display: flex;
        align-items: center;
        padding: 0 40px;
      }
    }



    .slick-prev, .slick-next {
      top: 140px;
      @media(min-width: 1200px) {
        top: 50%;
      }
    }
		.products-list .product-preview {
      grid-template-rows: auto;
      margin-bottom: 0;
      padding-bottom: 0;

          &:hover{
              background-color: transparent;
          }

			.product-preview__header {
				display: block;
//         padding: 10px 5px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        min-height: 0px;
        flex-wrap: wrap;

        @media(min-width: 1200px) {
//           padding: 10px 0;
        }

        &.product-preview__mobile {
          display: flex;
          @media(min-width: 1200px) {
            display: none;
          }
        }
        &.product-preview__desktop {
          display: none;
          @media(min-width: 1200px) {
            display: block;
          }
        }


        .promotion-preview__header {
          padding-top: 0;
        }
			}
			.product-preview__gallery {
				min-height: auto;
				//aspect-ratio: 1/1;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        @media(max-width: 1199px) {
          max-width: 500px;
          margin: 0 auto;
        }
        @media(min-width: 1200px) {
          max-width: 50%;
          margin-right: 20px;
        }

        img {
          min-width: 150px;
          max-width: 70%;
          @media(min-width: 1200px) {
            max-width: 100%;
          }
        }
			}
      .product-preview__description {
        color: #161616;
        font-family: Mulish,sans-serif;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: .72px;
        line-height: 20px;
        min-height: 0;
        padding: 0 5px;

        @media(min-width: 1200px) {
          padding: 0;
        }

        .text {
          line-height: normal;
          font-size: .825rem;
        }
      }

      .product-links {
//         margin-top: 7px;
        padding: 0 5px;

        @media(min-width: 1200px) {
          padding: 0;
        }
      }
		}



    .cta__add {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
//       margin-top: 8px;

      .isOutOfStock {
        padding: 0;
      }

      .btn_backInStock {
        line-height: normal;
        text-align: left;
      }

      .product-link__wishlist {
        padding: 0 0 20px 10px;
        display: flex;
        height: 34px;

        .icon {
          font-size: 30px;
          @media(min-width: 1200px) and (max-width: 1535px){
            font-size: 18px;
          }
        }
      }
      .btn_addToCart.button-primary {
        min-height: 45px;
        height: auto;
      }
    }

    .product-preview__desktop {
      .promotion-preview__header {
        padding-top: 5px;
      }
      .prices {
        text-align: left;
      }
      .price--strikethrough {
        left: 0;
      }
    }

	}

  &.text-white {
    .products-list .product-preview .product-preview__header,
    .products-list .product-preview .product-preview__description,
    .products-list .product-preview .product-links,
    .products-list .product-preview__desktop {
      .name,
      .text,
      .price,
      .isOutOfStock {
        color: #fff;
      }
      .button-primary {
        border: 1px solid #fff;
      }
    }  
    
  }

  .js-bannerSlider {
    opacity: 0;
    transition:opacity 1s;
    width: 100%;
    @media(max-width: 991px) {
      max-width: 400px;
    }

    @media(min-width: 992px) and (max-width: 1199px) {
      max-width: 350px;
    }

    &.slick-initialized {
      opacity: 1;
    }
  } 

  .pin-image {
//     aspect-ratio: 931/669;
//     position: relative;
//     display: flex;
//     overflow: hidden;

    img {
      width: 100%;
      height: auto;
//       position: absolute;
//       top: 0;
//       left: 0;
    }
  }
	 
}

//HOTSPOT IMAGE
$buttonRadius: 10px;
$labelPaddingX: $buttonRadius * 1;
$labelBorderRadius: 2px;
$labelFade: 0.1s;
$colorButton: #fff; 
$colorButtonHover: #555;
$colorText: #333;
$colorHeadingBackground: #555;

.big-slide {
  position: relative;
}

.pin-image {
    position: relative; 
}

.lg-hotspot {
  position: absolute;
  margin: 0;
  padding: 0;
  transform: translate(-50%, -50%);
  z-index: 0;
  cursor: pointer;

  &.mobile {
    @media(min-width: $desktop) {
      display: none;
    }
  }

  &.desktop {
    display: none;
    @media(min-width: $desktop) {
      display: block;
    }
  }
 

  &:hover,
  &:active {
    //  z-index: 999;
    .lg-hotspot__button {
      border-color: $colorButtonHover;
      background-color: $colorButtonHover;
      //&:after {
       // background-color: $colorButtonHover; 
      //}
    }
  }
  &--selected {
    z-index: 999;
    .lg-hotspot__label {
      opacity: 1;
    }

    & + .lg-hotspot__label {
      opacity: 1;
      z-index: 998;
    }

    .lg-hotspot__button {
      border-color: $colorButtonHover;
      background-color: $colorButtonHover;
    }
  }
  &__button {
    height: $buttonRadius * 2;
    width: $buttonRadius * 2;
    padding: 0px;
    border-radius: 100%;
    border: 1px solid $colorButton;
    background-color: $colorButton;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
    animation: button-pulse 7s ease-in-out infinite;
    

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      //pointer-events: none;
      content: "";
      display: block;
      height: $buttonRadius * 1;
      width: $buttonRadius * 1;
      border-radius: 100%;
      //border: 3px solid white;
      background-color: #000;
      transition: border-color 1s linear;

    }
  }
  &__label {
    position: absolute;
    padding: 0;
    width: 16em;
    max-width: 50vw;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    line-height: 1.45em;
    z-index: -1;
    //pointer-events: none;
    border-radius: $labelBorderRadius;
    user-select: none;
    opacity: 0;
    transition: all $labelFade linear;

    &-mobile {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
    }
    
    p {
      margin: 0;
      padding: .5em 1em 0.5em  $labelPaddingX;
      cursor: auto;
    }

    a {
      border-top: 1px solid #000;
      padding: .5em 1em 0.5em  $labelPaddingX;
      color: #000;
      display: block;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 700;
    }
  }
}

.lg-hotspot--top-left .lg-hotspot__label {
  top: 40px;
  left: 40px;
}

.lg-hotspot--top-right .lg-hotspot__label {
  top: 40px;
  right: 40px;
}

.lg-hotspot--bottom-right .lg-hotspot__label {
  right: $buttonRadius;
  bottom: $buttonRadius;
}

.lg-hotspot--bottom-left .lg-hotspot__label {
  bottom: $buttonRadius;
  left: $buttonRadius;
}

@keyframes button-pulse {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  40% {
    transform: scale(1.15, 1.15);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}