.img-fluid{
    width: 100%;
}

.icons{
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;

    .payment-icon{
        max-width: 50px;
        margin: 0 5px 5px 0;
    }
}
.icon-partecipants{
    background-image: url('#{$images}/icons/partecipants.png');
}
.icon-time{
    background-image: url('#{$images}/icons/time.png');
}
.icon-when{
    background-image: url('#{$images}/icons/when.png');
}
.icon-signup{
    background-image: url('#{$images}/icons/signup.png');
}
.icon-priceCourse{
    background-image: url('#{$images}/icons/price-course.png');
}