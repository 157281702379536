.cmp_heroBanner,
.cmp_bannerSlider.customLayout--fullcentered   {
    .flipdown {
        position: relative;
        z-index: 2;
        left: -8px;
        margin-bottom: 10px;
        @media (max-width: 966px)  {
            left: 0;
        }

        + .action-link {
            display: none;
            //margin-top: 25px;

            @media (min-width: 577px) and (max-width: 966px)  {
                font-size: .7rem;
                margin-top: 0
            }
        }

    }
    .action-link {
        @media (min-width: 577px) and (max-width: 966px)  {
            font-size: 10px;
            margin-top: 0
        }
    }
    .countdown {
        .timer {
            margin-right: 20px;
            width: 70px;
            @media (max-width: 450px)  {
                width: 60px;
            }
            @media (max-width: 966px)  {
                width: 24%;
                margin-right: 8px;
            }
        }
        .date-txt {
            @media (max-width: 966px)  {
                font-size: .8rem;
            }
        }
        .number {
            font-size: 3rem;
            @media (max-width: 966px)  {
                font-size: 2rem;
            }
        }
    }

    &.tpl_fullScreen-textCentered {
        .countdown {
            justify-content: center;
            .timer:last-of-type {
                margin-right: 0;
            }
        }
    }

    .countdown-title {
        padding-bottom: 0;
        margin-top: 15px;
        font-size: 1.8rem;

        @media (min-width: 577px) and (max-width: 966px)  {
            font-size: 1rem;
            margin-top: 0;
            line-height: normal;
        }
    }

    .link-cta-countdown {
        margin-top: 25px;
    }
}