//fullwidth image
@mixin image-fullwidth() {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
  img {
    width: 100%;
  }
}
@mixin no-image-fullwidth() {
  width: auto;
  position: inherit;
  left: auto;
  margin-left: inherit;
  img {
    width: auto;
  }
}

@mixin visuallyHidden() {
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

@mixin centre($axis: "both") {
  position: absolute;

  @if $axis == "y" {
      top: 50%;
      transform: translateY(-50%);
  }

  @if $axis == "x" {
      left: 50%;
      transform: translateX(-50%);
  }

  @if $axis == "both" {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
}

 
//https://fontawesome.com/icons?d=gallery&p=2&m=free
// di default scegliamo lo stile solid (font-weight: 900 ) perchè ci sono più icone
//USAGE
//.myelement { @include icon_fontAwesome("\f879", $style:'regular'); }

%fontAwesome {
  font-family: "Font Awesome 5 Free";
  -webkit-font-smoothing: antialiased;

  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@mixin icon_fontAwesome ($unicode, $type: 'before', $style:'solid') {
  @extend %fontAwesome; 
  
  @if $style == 'regular' {
    font-weight: 400;
  } @else {
    font-weight: 900;
  }
 
  &:#{$type} {
    content: $unicode; 
    display: inline-block;
  }
}

@mixin resetButtonStyle() {
  line-height: normal;
  background: transparent;
  color: inherit;
  border: 0;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  -webkit-appearance: none;
}

@mixin centre($axis: "both") {
  position: absolute;

  @if $axis == "y" {
      top: 50%;
      transform: translateY(-50%);
  }

  @if $axis == "x" {
      left: 50%;
      transform: translateX(-50%);
  }

  @if $axis == "both" {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
}
 
/* TYPOGRAPHY */
@mixin font($name:Mulish){
  font-family: $name, sans-serif;
}

@mixin letterSpacing ($size: md) {
  letter-spacing: map-get($letterSpacing, $size);
}


/*TITOLO*/

@mixin newtitle ($size: md, $size-mobile: sm) {

  font-size: map-get($title-mobile-fontSize, $size-mobile);

  @media (min-width: $desktop) and (max-width: $desktop-XL) {
    font-size: map-get($title-fontSize, $size)*.66;
  }

  @media (min-width: $desktop-XL) {
    font-size: map-get($title-fontSize, $size);
  }
}

/*SOTTOTITOLO*/

@mixin subTitle($size:md, $size-mobile: sm){
  font-size: map-get($title-mobile-fontSize, $size-mobile);

  @media (min-width: $desktop) and (max-width: $desktop-XL) {
    font-size: map-get($subTitle-fontSize, $size)*.66;
  }

  @media (min-width: $desktop-XL) {
    font-size: map-get($subTitle-fontSize, $size);
  }
}

/* TESTO */

@mixin text($size:md){
  font-size: map-get($text-fontSize, $size);
  line-height: 1.5;

  @media (min-width: $desktop) and (max-width: $desktop-XL) {
    font-size: map-get($text-fontSize, $size)*.66;
    line-height: 2;
  }

  @media (min-width: $desktop-XL) {
    line-height: 2;
  }
}

/*SOTTOTESTO*/

@mixin subText($size:md) {
  font-size: map-get($subText-fontSize,$size);
}

/*= BOOTSTRAP GRID */

%rowgrid { 
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@mixin rowgrid (){
  @extend %rowgrid;
}

%colgrid{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@mixin col-lg-4 (){
  @extend %colgrid;
  @media (min-width: $tablet-L) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

/* INSTRUMENT CATEGORY COLOR */

@mixin instrumentCategoryColor($categoryColor:inherit) {

  h1, h2, h3,
  h4, h5, h6,
  .products-manager-page .products-list.js-product-list-slider .products-list__item .products-list__item__title {
    color: $categoryColor;
  }
  .cmp_heroBanner {
    &.tpl_textFocus {
    }
    &.tpl_heroBannerDefault{
      .instrumentCategoryLine {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 5px;
        background-color: $categoryColor;
      }
    }

    background-color: $categoryColor;

    h1, h2 {
      color: inherit;
    }
  }



}


@mixin centre($axis: "both") {
  position: absolute;

  @if $axis == "y" {
      top: 50%;
      transform: translateY(-50%);
  }

  @if $axis == "x" {
      left: 50%;
      transform: translateX(-50%);
  }

  @if $axis == "both" {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
}