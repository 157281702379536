.ssc_prestep_container{
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  flex-direction: column;
  @media (min-width: $mobile) {
    flex-direction: row;
  }
}

.ssc_navigation_container{
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  @media (min-width: $mobile) {
    flex-direction: row;
  }

}
.ssc_prestep_categories{
  width: 47%;
  margin-right: 5px;
  display: flex;

  @media (max-width: $mobile) {
    margin-top: 10px;
    min-height: 200px;
    width: 100%;
  }
}

.ssc_prestep_search{
  width: 47%;
  padding: 20px;

  @media (max-width: $mobile) {
    width: 100%;
    margin-top: 10px;
    min-height: 200px;
  }
}

.ssc_boderded_box{
  text-align: center;
  border: 1px solid $black;
  cursor: pointer;
  color: $white;
  background: $black;
  p{
    margin:auto
  }
  .selectize-input {
    color: $white;
  }
}

.ssc_navigation_item{
  cursor: pointer;
  width: 30%;
  @media (max-width: $mobile) {
    width: 100%;
    margin-top: 10px;
    min-height: 100px;
  }
}
.ssc_selected_button{
  background: $white;
  color: $black;
}

.ssc_category_image{
  width: 100%;
}

.ssc_prestep_title{
  font-family: 'Mulish', sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 1.82px;
  text-transform: uppercase;
  transition: all .5s ease-in-out;
}