//common rules

.social-icon {
  display: inline-block;
 
  &:hover {
    cursor: pointer;
  }
 
  img {
    width: 100%;
    display: block;
  }
}

.social__footer {
  margin-bottom: 50px;
  text-align: center;

  .social__label {
    color: $product-grey;
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.68px;
    line-height: 24px;
    text-transform: uppercase;
  }

  .social__icons {
    display: flex;
    justify-content: center;
  
    .social-icon {
        width: 33px;
        margin-left: 5px;
        margin-right: 5px;

        &:hover {
          cursor: pointer;
        }

        @media (min-width: $tablet-P) {
          width: 17px; 
        }
    }
  }
}


