@import "stores-locator/stores-locator-filters";
@import "stores-locator/stores-locator-map";
@import "stores-locator/stores-locator-list";
@import "stores-index";


.stores-locator {
  padding-bottom: 40px;

  @media (min-width: $mobile) {
    padding-bottom: 0;
  }

  &__component {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;

    .anchor {
      display: none;
      position: absolute;
      top: 32px;
      left: 12px;
      background: $black;
      color: $white;
      font-family: 'Mulish', sans-serif;
      font-size: 12px;
      font-weight: 200;
      letter-spacing: 1.82px;
      text-transform: uppercase;
      padding: 0 16px;
      line-height: 40px;

      @media (min-width: $tablet-L) {
        display: block;
      }

      &:before {
        content: '\2630';
        font-weight: 100;
        padding-right: 8px;
        color: $white;
        font-family: 'Mulish', sans-serif;
        font-size: 12px;
        letter-spacing: 1.82px;
      }
    }

    > div {
      display: -ms-grid;
      display: grid;
      height: auto;
      grid-gap: 0;
      grid-template-columns: 1fr;
      -ms-grid-columns: 1fr;
      grid-template-rows: 1fr;
      -ms-grid-rows: 1fr;

      @media (min-width: $tablet-L) {
        height: 100%;
        grid-template-columns: 1fr 40%;
        -ms-grid-columns: 1fr 40%;
        grid-template-rows: auto 1fr;
        -ms-grid-rows: auto 1fr;
      }

      @media (min-width: $desktop) {
        grid-template-columns: 1fr 30%;
        -ms-grid-columns: 1fr 30%;
        grid-template-rows: auto 1fr;
        -ms-grid-rows: auto 1fr;
      }

      .stores-list {
        grid-area: stores;
        grid-row: 3;
        -ms-grid-row: 3;
        grid-column: 1;
        -ms-grid-column: 1;

        @media (min-width: $tablet-L) {
          grid-column: 2;
          -ms-grid-row: 2;
          grid-row: 2;
          -ms-grid-column: 2;
        }
      }

      .stores-filters {
        grid-area: filters;
        grid-row: 1;
        -ms-grid-row: 1;
        grid-column: 1;
        -ms-grid-column: 1;

        @media (min-width: $tablet-L) {
          grid-column: 2;
          -ms-grid-column: 2;
          grid-row: 1;
          -ms-grid-row: 1;
        }
      }

      .stores-map {
        position: relative;
        grid-area: map;
        grid-row: 2;
        -ms-grid-row: 2;
        grid-column: 1;
        -ms-grid-column: 1;

        @media (min-width: $tablet-L) {
          grid-column: 1;
          -ms-grid-column: 1;
          grid-row: 1 / 3;
          -ms-grid-row: 1;
          -ms-grid-row-span: 3;
        }
      }
    }
  }
}
