@import "stores-locator-shared";

.stores-locator .stores-map {
  position: relative;
  grid-area: map;
  grid-row: 2;
  grid-column: 1;

  @media (min-width: $tablet-L) {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  .map {
    height: 60vh;
    @media (min-width: $mobile) {
      height: 50vh;
    }
    @media (min-width: $tablet-L) {
      height: 100%;
    }
  }

  &__zoomController {
    display: none;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 2.5%;
    right: 3.5%;
    @media (min-width: $mobile) {
      display: flex;
    }
    @media (min-width: $tablet-L) {
      top: 5%;
    }

    > a {
      background-color: $black;
      border: none;
//       width: 10vw;
//       height: 10vw;
//       display: flex;
//       align-items: center;
//       justify-content: center;
      @media (min-width: $mobile) {
        width: 5vw;
        height: 5vw;
      }
      @media (min-width: $tablet-L) {
        width: 35px;
        height: 35px;
      }
      @media (min-width: $desktop) {
          width: 39px;
          height: 39px;
      }

      &::after {
        display: block;
//         width: 1.2vw;
//         height: 1.2vw;
        content: "";
        color: white;
        font-size: 32px;
        text-align: center;
//         vertical-align: middle;
//         background-position: center;
//         background-repeat: no-repeat;
        @media (min-width: $tablet-L) and (min-width: $desktop) {
          //margin-top:-50%;
        }
      }

      &.zoom-out::after {
//         background-image: url($minus-base64);
        content: "-";
        font-size: 46px;
      }

      &.zoom-in::after {
//         background-image: url($plus-base64);
        content: "+";
      }
    }
  }

  &__legend {
    display: none;
    position: absolute;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    left: 0;
    bottom: 0;
    height: auto;
    width: auto;
    padding: 20px 30px;
    @media (min-width: $mobile) {
      display: block;
    }

    > div {
      display: flex;
      height: auto;
      grid-row: 1;
      line-height: 21px;

      > img {
        height: 50%;
        padding: 0 .5rem;
        margin: 0 5px 0 10px;
      }

      > span {
        margin: auto 0;
        color: $white;
        font-family: 'Mulish', sans-serif;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 1.82px;
      }
    }
  }
}
