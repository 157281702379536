$btn-headerLayer-count-size: 18px;
$btn-headerLayer-padding: 6px;
$btn-headerLayer-size: 36px;

.header_layer {
  display: block;
  width: 100%; 
  z-index: 9999;
  overflow-x: hidden;
  position: fixed;
  bottom: 0;
  right: -100%;
  top: var(--page-margin);
  transition: right .2s cubic-bezier(0.57, 0, 0.07, 0.99);
  background: $color-secondary;
  color: $color-primary;
  border: 1px solid  $grey;
  &.active {
    right: 0;
  }
  @media (min-width: $desktop){
    max-width: 500px;
  }
  .header_layer-container {
    padding: $header-layer-padding-vertical 0 $header-layer-padding-vertical-bottom;
  }

  .header_layer-title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .header_layer-body {
      padding: 0 $header-layer-padding-horizontal;
      flex: 1 1 auto;
      overflow-y: auto;

      /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px #fff; 
      border-radius: 10px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ccc; 
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #fff; 
    }
  }
}

.ec_btn {
  @include resetButtonStyle;

  .testo {
      @include visuallyHidden; 
  }
  .icon {
      display: inline-block;
  }
}

.header_btn-closeLayer {
  position: absolute;
  right: 14px;
  top: 12px;

  width: 30px;
  height: 30px;
  line-height: 30px;
  
  font-size: 27px; 
  color: $color-primary;
  text-align: center;

  .icon {
     /*  &::before {
          content:'x'; 
          position: absolute; //trick x stile al :focus
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      } */
      background-image: url('#{$images}/icons/close-btn.svg');
      background-repeat: no-repeat;
      height: 25px;
      width: 25px;
  }
}

// STILI ECOMMERCE

.accountLayer,
.cartLayer  {
  a {
    .header__row & {
      color: $color-primary;
    }
    
  }
}

.header-nav__actions > *:not(.header_layer) { 
   height: $btn-headerLayer-size;
}

.menu-wrapper .hamburger-menu {
  margin: 2px 0  0 12px;

  @media (max-width: $mobile) {
    margin: 2px 0  0 8px;
  }
}

.header_btn-search,
.search-bar__submit,
.ec_btn-accountLayer,
.ec_link-accountPage,
.ec_btn-cartLayer,
.ec_link-wishlistPage {
    
    color: $color-secondary--menu;
    position: relative;
    font-size: 19px;
    padding: $btn-headerLayer-padding 0;
    //width: $btn-headerLayer-size;
    width: 24px;
    height: $btn-headerLayer-size;
    text-align: center;
    margin: 0 12px;

    @media (max-width: $mobile) {
      margin: 0 8px;
    }
    &:hover {
        color: inherit;
    }
}

.header_btn-search,
.search-bar__submit {
  .icon { 
    //background-image: url('#{$images}/#{$icon--search}');
    background-image: url('#{$images}/icons/icon-search.svg');
    //background-size: contain;
    background-repeat: no-repeat;
    //width: calc(100% - 16px);
    width: 26px;
    height: 26px;
    background-position: center;

    @media (min-width: 1200px) and (max-width: 1367px){
      zoom: 0.7;
    }
  }
}

.ec_btn-accountLayer, 
.ec_link-accountPage {
  display: inline-block;

  .icon {
      //@include icon_fontAwesome("\f007"); 
      background-image: url('#{$images}/icons/user.svg');
     // background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      background-position: center;

      @media (min-width: 1200px) and (max-width: 1367px){
        zoom: 0.7;
      }
  }
}

.ec_btn-cartLayer,
.ec_link-wishlistPage {

  .count {
    width:  $btn-headerLayer-count-size;
    height:  $btn-headerLayer-count-size;
    border-radius:  $btn-headerLayer-count-size;
    line-height:  $btn-headerLayer-count-size - 2px;
    background:  $dark-red;
    position: absolute;
    top: 2px;
    right: -5px;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    color: $color-secondary;
    border: 1px solid $color-secondary;
  }

}

.ec_btn-cartLayer {
    //padding-right: $btn-headerLayer-padding;

    .icon {
        //@include icon_fontAwesome("\f07a"); 
        background-image: url('#{$images}/icons/cart.svg');  
        //background-size: contain;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        background-position: center;

        @media (min-width: 1200px) and (max-width: 1367px){
          zoom: 0.7;
        }
    }
}

.ec_link-wishlistPage {
  display: inline-block;
 // padding-right: $btn-headerLayer-padding;

  .icon {
      //@include icon_fontAwesome("\f004");   
      background-image: url('#{$images}/icons/wishlist.svg');  
      //background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      background-position: center;

      @media (min-width: 1200px) and (max-width: 1367px){
        zoom: 0.7;
      }
  }
}

.accountLayer {
  .header_layer-title { 
    text-transform: none;
  }
}

.userLoggedLayer {
  text-align: center;

  .header_layer-title {
    span {
      text-transform: none;
    }
  }

  .userLoggedMenu {
    list-style: none;
    text-align: left;
    display: inline-flex;
    flex-direction: column; 
    padding: 0;
  
    li {
      font-weight: 700; 
      font-size: 1.25rem ;
      margin-bottom: 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  .ec_btn_logoutLayer {
    text-decoration: underline;
    text-transform: none;
    color: $dark-grey; 
  }
  
}

.searchLayer {
  background: $color-primary--menu;
  color: $color-secondary--menu;
  border: none;

  .header_btn-closeLayer {
    color: $color-secondary--menu;
  }

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 93%;
    margin: auto;
  }

  .search-bar__submit {

    margin-left: 10px;

    .icon {
      width: 100%;
    }
  }
  .search-bar__input{
    padding: 0 10px; 
    border: none;
    border-bottom: 1px solid $color-secondary--menu;
    caret-color: $color-primary;
    color: $t-color-secondary;
    width: 100%;
    min-height: 40px;
    margin-left: calc(#{$btn-headerLayer-size} - 10px); //input centrato col titolo
  }
 
}





