.smegStoreComponent__title {
  padding-top: 50px;
  padding-bottom: 50px;
}

.smegStoreComponent {
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid $light-grey;
  color: $black;

  .smeg__badge {
    font-size: 0.75rem;
    color: $product-grey;
    background-color: $product-label;
    padding: 5px 10px;
    font-weight: 600;
    letter-spacing: 1.68px;
    @include font(mulish);
  }

  &:last-of-type {
    border-bottom: none;
  }

  .smegStoreComponent__titleResult {
    text-transform: uppercase;
  }

  .smegStoreComponent__descResult {
  }

  .smegStoreComponent__address {
    font-weight: bold;
  }

  .smegStoreComponent__info {
    a {
      font-weight: unset;
      margin-top: 10px;
      padding-right: 20px;
      display: inline-block;
    }
  }
}

.eform-component__block {
  margin-bottom: 25px;
}

#select2-select-continent-results,
#select2-select-country-results {
  max-height: 300px;
}

.smeg-in-the-world {

  #smeg-in-the-world-container, #smeg-in-the-world-no-result, #smeg-in-the-world-anchor {
    color: $light-black;
  }

  #smeg-in-the-world-container, #smeg-in-the-world-no-result {
    padding-top: 25px;
    padding-bottom: 50px;
  }

  #smeg-in-the-world-anchor {
    padding-top: 50px;
  }
  .select-dropdown .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered{
    color: var(--color-primary);
  }
}

.column-count-3-d{
  @media (min-width: $tablet-L) {
    column-count: 3;
  }
}

#accordionSmegInTheWorld{
  div.accordion__wrapper{
    border-bottom: 1px solid #b6b6b6 ;
  }
  .accordion__header h2{
    position: relative;
    cursor: pointer;
  }
  .accordion__header h2:after {
    position: absolute;
    top:50%;
    transform: translateY(-50%)rotate(-180deg);
    right: 0;
    float: right;
    content: '';
    transition:all .2s ease-in-out;
    height: 25px;
    width: 25px;
    background-size: 25px;
    background-image: url('#{$images}/icons/arrow-down.svg');
    cursor: pointer;
  }
  .accordion__header h2.collapsed:after {
    background-image: url('#{$images}/icons/arrow-down.svg');
    transform: translateY(-50%)rotate(0deg);
    transition:all .2s ease-in-out;
  }
}