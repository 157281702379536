
.product-energyClass {

  .product-details & {
    margin-right: 15px; 
  }

  .open-energyClass {
    //@include resetButtonStyle;
    color: black; 
    display: inline-block;
    width: 40px;
  }

  img {
    width: 100%;
    height: auto;
    display: inline-block;
  }
}
