.faq {
    padding-top: 42px;
    padding-bottom: 42px;
    padding-left: 0px;
    padding-right: 0px;

    @media (min-width: $tablet-P) {
        @include container-m();
        padding-top: 75px;
        padding-bottom: 75px;
    }

    > div > h4 {
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.72px;
      line-height: 26px;
      text-transform: uppercase;
      margin-top: -34px;
      margin-bottom: 15px;
      margin-left: 37px;


      @media (min-width: $tablet-P) {
        text-align: center;
        margin-bottom: 75px;
        margin-left: 0px;
      }

      @media (min-width: $desktop) {
        font-family: $font-secondary;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
      }
    }

    .faq-component.foodservice {
        background-color: #F8F8FA;
        color: #020202;
    }

    .faq-component {
        background-color: $light-black;
        margin-bottom: 10px;
        display: table;
        width: 100%;
        color: $white;

        @media (min-width: $tablet-P) {
            margin-bottom: 20px;
            //position: relative;
        }

        .faq-component__header {
            text-align: center;

            .faq-component__header__info {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                &:after {
                    content: "";
                    background-color: $white;
                    width: 30px;
                    height: 1px;
                    position: absolute;
                    bottom:0;
                }
                &--space-time{
                    display: flex;
                    padding:25px 0;
                    @media (min-width: $tablet-P) {
                        padding:30px 0;
                    }
                    
                }

                .faq-component__header__info--city {
                    text-transform: uppercase;
                    color: $grey;
                    @include subTitle;

                    &:after {
                        content: "."
                    }
                }

                .faq-component__header__info--data {
                    text-transform: uppercase;
                    margin-left: 10px;
                    @include subTitle;
                }

                .faq-component__header__info--button {
                    position: absolute;
                    top:0;
                    right: 6%;
                    bottom: 10px;
                    @media (min-width: $tablet-P) {
                        bottom: -20px;
                        top: 40px;
                    }
                    height: 40px;
                    display: flex;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                    }
                }

            }

            &.collapsed {
                .faq-component__header__info {
                    .faq-component__header__info--button {
                        .faq-component__header__info--button__icon--plus {
                            display: block;
                            width: 10px;
                            @media (min-width: $tablet-P) {
                                width: 20px;
                            }
                        }
                        .faq-component__header__info--button__icon--minor {
                            display: none;
                            width: 10px;
                            @media (min-width: $tablet-P) {
                                width: 20px;
                            }
                        }
                    }
                }
            }

            &:not(.collapsed) {
                .faq-component__header__info {
                    .faq-component__header__info--button {
                        .faq-component__header__info--button__icon--plus {
                            display: none;
                            width: 10px;
                            @media (min-width: $tablet-P) {
                                width: 20px;
                            }
                        }
                        .faq-component__header__info--button__icon--minor {
                            display: block;
                            width: 10px;
                            @media (min-width: $tablet-P) {
                                width: 20px;
                            }
                        }
                    }
                }
             }

            .faq-component__header__title {
                position: relative;
                h3 {
                    @include newtitle;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    @media (min-width: $tablet-P) {
                        font-size:24px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        padding-left: 70px;
                        padding-right: 70px;
                    }
                }
            }
        }

        .faq-component-content {
            p {
                padding: 35px 60px 60px;
                @media (min-width: $tablet-P) {
                    padding: 30px 60px 60px;
                }
            }
            .action-link {
                margin-left: 40px;
                margin-bottom: 40px;
            }
        }

    }

    .faq-component--topic {

        .faq-component__header {

            .faq-component__header__info {

                .faq-component__header__info--button {
                    top:-40px;
                    bottom: 25px;

                    .faq-component__header__info--button__icon--arrow {
                        width: 25px!important;
                        margin-top: -50%;
                    }

                }

                &:after {
                    display: none;
                }

            }

        }

        .faq-component-content {

            p {
                padding: 20px 60px 20px!important;
            }

        }

    }

}