h3 {
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
}

a.action-link {
    font-weight: 700;
    &:hover {
        color: $light-grey;
        text-decoration: none;
    }
}

.generalText,
.visualBox__text,
.sliders-texts,
.heroBannerComponent__textBlock,
.heroBannerUnderlined__text,
.heroBannerFullScreen__textBlock,
.cards__textBox,
.job-position {
    p {
        a {
            text-decoration: underline;

            &:hover {
                color: $light-grey;
            }
        }
    }

    dl,
    ol,
    ul {
        padding: 0 30px 0 50px;
    }
}

body {
    color: $t-color-primary;
    font-size: 16px;
    font-family: $font-primary;
    font-weight: 300;
    padding:0;
    margin:0;
}

//paragraph
p {
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: .32px;

    @media (min-width: $tablet-P) {
        line-height: 34px;
    }
}

//buttons and links
.button-primary {
    font-family: $font-secondary;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.92px;
    line-height: 24px;
    background-color: $white;
    color: $black;
    border: 1px solid $black;
    width: 100%;
    min-width: fit-content;
    text-align: center;
    height: 45px;
    padding: 10px;

    @media (min-width: $tablet-P) {
        font-size: 16px;
    }

    &:hover {
        color: $light-black;
        border-color: $light-black;
    }

}

.btnMinSize {
    min-width: $minWidth;
}