.default-section{
    background-color: $bg-color-primary;
    color: $t-color-primary;
}


.black-section {
    background-color: $bg-color-primary;
    color: $t-color-primary;
}

.white-section {
    background-color: $bg-color-secondary;
    color: $t-color-secondary;
    a {
        color: $black;
        font-weight: bold;

        &:hover {
            color: $grey;
            text-decoration: underline;
        }
        &.link__disabled--desktop{

            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}

/* Additions */

//trustpilot start

.trustpilot{
    padding-top: $space--md;
    @media (min-width: $desktop){
        padding-top: $space--lg;
    }
    &:empty{
        padding-top: unset;
    }
}

//trustpilot end