.leaderboard{
  display: block;
  background-color:#968870;
  width: 100%;
  height: 50px;
  font-size: 0.8rem;
  line-height: 18px;
  position: fixed;
  top:0;
  z-index: 1000;
  @media (max-width: 767.9px){
    height: 80px;
  }
  .wrapper{
    padding-left: 0;
    margin-left: 37px;
    //padding-top:10px;
    position: relative;
    display: none;
    align-items: center;
    min-height: 50px;
    height: 100%;
    @media (min-width: $tablet-P){
      margin-left: 70px;
    }
    @media (min-width: $tablet-L){
      margin-left: 120px;
    }
    @media (min-width: $desktop){
      margin-left: 37px;
      height: 100%;
    }
    .sliders{
      width: 85%;
      padding-left: 0;
      margin:0;
      .slick-list{
        width: 100%;
      }
      .slick-track{
        position: relative;
        display: flex;
        align-items: center;
        .slick-slide{
          opacity: 0;
          transition: opacity .5s;
          max-width: 75vw;
          display: block;
          overflow-wrap: break-word;
          padding:0px 0px 0px 0px;
          @media (min-width: $desktop){
            max-width: 90vw;
          }
          &.slick-current{
            opacity: 1;
          }
          p{
            max-width: 80vw;
            line-height: 1.3rem;
            margin: 0;
            @media (min-width: $desktop){
              max-width: 90vw;
            }
          }
        }
      }
    }
    .button__close{
      width: 5%;
      position: absolute;
      display: block;
      right:30px;
      top: 50%;
      transform: translateY(-50%);
      @media (min-width: $desktop){
        right: 10px;
      }
      i{
        display: block;
        margin:0 auto;
        width: fit-content;
      }
    }
  }
}