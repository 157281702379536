.breadcrumb__row {
    nav[aria-label="breadcrumb"] {
        margin-top: 0px;
        line-height: 20px;
        position: absolute;
        top: 90px;
        display: none;
        z-index: 2;
        @media (min-width: $desktop) {
            display: block;
            top: 20px;
//             top: calc(var(--header-height) - var(--breadcrumb-position);
            z-index: 3;
        }
        
        .breadcrumb {
            background-color: unset;
            margin: 0px;
            padding: 0px;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background-color: rgba(0, 0, 0, 0);
            }
            .breadcrumb-item {
                font-family: $font-secondary;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.9px;
                line-height: 20px;
                &:before {
                    content: "/";
                }
                &:first-of-type:before {
                    content: " ";
                }
            }
        }
    }
}

.breadcrumb--black{
    .breadcrumb-item {
        a,
        span{
            color: $product-grey;
        }
        &.active span,
        a:disabled,
        a[disabled],
        a:not([href]):not([tabindex]){
            color: $black;
        }
    }
}
.breadcrumb--white{
    .breadcrumb-item {
        a {
            color: $breadcrumbs;
        }
        &.active,
        a:disabled,
        a[disabled],
        a:not([href]):not([tabindex]){
            color: $white;
        }
    }
}