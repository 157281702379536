.cmp_relatedItems{}
.cmp_relatedItems {
	&.tpl_relatedItemsDefault{
		.header {
			.col {
				@include pb(2);
			}
			.col:empty {
				padding-bottom:0;
				display: none;
			}
			.textBlock {
				&:after {
					content: '';
					display: block;
					border-bottom: 2px solid;

				}
			}
		}
		.listingItems{
			.item{
				@media (min-width: $tablet-P) {
					display: flex;
					justify-content: space-around;
					padding: 0 20px;
				}
				.gallery{
					.image{
						margin: 0 auto;
						width:100%;
					}
					@include pb(2);
				}
				.textBlock{
					.subTitle_2,
					.text{
						@include pb(2);
					}
				}
				.singleItem {
					display: contents;
					flex-wrap: wrap;
					margin-right: -15px;
					margin-left: -15px;

					> a,
					.textBlock  {
						position: relative;
						width: 100%;

						@media (min-width: $tablet-P) {
							flex: 0 0 50%;
							max-width: 50%;
							margin-left: $space--lg;
						}

					}
				}
			}
			.slick-arrow {
				top:30%;
			}
			.slick-prev{
				margin-left: unset;
				left:-25px;
			}
			.slick-next{
				margin-right: unset;
				right:-25px;
			}
			@media (min-width: $desktop) {
				/* the slides */
				.slick-slider {
					overflow: hidden;
				}
				/* the parent */
				.slick-list {
					margin: 0 -100px;
				}
				/* item */
				.item {
					padding: 0 100px;
				}
				.slick-prev{
					margin-left: unset;
					left:-3rem;
				}
				.slick-next{
					margin-right: unset;
					right:-3rem;
				}
			}
		}
		.container-fluid{
			.header{
				@include container;
			}
			.listingItems {
				.item {
					.singleItem {

						> a,
						.textBlock {
							@include container;
							@media (min-width: $tablet-P) {
								@include container--disabled;
							}
						}
					}
				}
			}
		}
	}

	&.tpl_relatedItemsSmall {
		.listingItems {
			@media (min-width: $tablet-P) {
				justify-content: center;
				display: flex;
			}
		}

		.item {
			display: inherit;
			width: 50%;
			@include pb(2);
			@media (min-width: $tablet-P) {
				max-width: 400px;
				padding-bottom: unset;
			}
		}

		.gallery {
			width: 26%;
			margin: 0 auto; 
			display: flex;  
			justify-content: center;
			margin-bottom: 25px;

			img {
				max-width: 100%;
				max-height: 100%;
				height: auto;

			}
		}

		.textBlock {
			.subTitle_2,
			.text{
				@include pb(1);
				min-height: 150px;
			}
			.title{
				min-height: 90px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
			}
		}

		.title,
		introduction,
		.text{
			&:empty{
				padding: unset;
			}
		}
 
	}

}