.has-edit-button {
    position: relative;
}

.has-new-content-button {
    position: relative;
    width: 40px;
    height: 40px;
}

.cms-preview {
    .hippo-overlay-element {
        &.hippo-overlay-element-container {
            &.hippo-overlay-element-visible {
                &.hippo-overlay-element-container-empty {
                    min-width: 100px;
                    min-height: 40px;
                }
            }
        } 
    }
}