@import "stores-locator-shared";

.stores-locator .stores-filters {
  position: relative;

  &__header {
    display: none;

    .title {
      //font-size: 30px;
      line-height: 58px;
    }

    p {
      font-family: 'Mulish', sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;
      line-height: 34px;
    }

    @media (min-width: $mobile) {
      display: block;
    }
  }

  &__box {
    position: relative;
    width: 100%;
    border: 1px $white outset;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: max-height 1s;
    -moz-transition: max-height 1s;
    -webkit-transition: max-height 1s;
  }

  &__label {
    width: 100%;
    height: 38px;
    background-color: $black;
    display: flex;

    > p {
      color: $white;
      margin: auto 0;
      margin-left: 1rem;
      margin-right: 1rem;
      font-family: Raleway, sans-serif;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.44px;
      line-height: 36px;

      > span {
        font-size: 10px;
        color: #a0a0a0;
        letter-spacing: 0.25px;
        font-family: Raleway, sans-serif;
        line-height: 40px;
        vertical-align: middle;
        max-width: 80%;
        display: inline-block;
        overflow-x: auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media (min-width: $tablet-L) {
          max-width: 90%;
        }
      }
    }

    > button {
      margin-left: auto;
      background-color: $black;
      border: none;
      display: flex;
      justify-content: center;

      &::after {
        display: inline-block;
        width: 20px;
        height: 20px;
        content: "";
        vertical-align: middle;
        background-position: center;
        background-repeat: no-repeat;
        margin: auto 0;
      }

      &.expand::after {
        background-image: url($plus-base64);
      }

      &.expanded::after {
        background-image: url($minus-base64);
      }
    }
  }

  &__container {
    position: absolute;
    right: -1px;
    left: -1px;
    height: auto;
    overflow: hidden;
    z-index: 1;
    max-height: 0;
    transition: max-height 0.33s linear;
    border-bottom: 1px $white solid;
    border-right: 1px $white solid;
    border-left: 1px $white solid;

    &.expanded {
      max-height: 600px;
    }

    &__content {
      padding: 12.5px;
      background-color: $black;
      @media (min-width: $tablet-L) {
        padding: 25px;
      }

      > label {
        font-family: Raleway, sans-serif;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 1.82px;
        line-height: 36px;
        text-transform: uppercase;
      }

      > .input-group {
        padding-bottom: 1rem;
        @media (min-width: $tablet-L) {
          padding-bottom: 2rem;
        }

        .form-control {
          font-family: 'Mulish', sans-serif;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.32px;
          line-height: 34px;
        }
      }

      > button {
        width: 100%;
        height: 38px;
        color: $white;
        background-color: $black;
        border: 1px $white outset;
        font-family: Raleway, sans-serif;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.44px;
        line-height: 24px;
      }
    }
  }

  select.form-control {
    color: $white;
    background-color: $black;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid $white;
    font-size: 16px;
    @media (min-width: $tablet-L) {
      font-size: initial;
    }

    > option[selected] {
      display: none;
    }
  }
}
