.story-details {
    background-color: $white;
    color: $black;
    &__footer{
	    padding-top: 25px;
	    padding-bottom: 25px;
    }
    &__pagination{
        display: flex;
        justify-content: space-between;
    }
    &__arrow{
	    font-size: 12px;
	    background-color: transparent;
	    border: none;
		width: fit-content;
		display: flex;
		align-items: center;
	    .arrow__title{
		    color: $black;
		    font-family: $font-secondary;
		    font-weight: 500;
		    letter-spacing: 2.24px;
		    text-transform: uppercase;
		    padding: 0 10px;
	    }
    }
}
