.search-stores {

  .grid {
    animation: fadeinloader 1s;

    .no-result {
      text-align: center;
    }

    .grid-element {
      background-color: transparent;
      border-radius: 5px;

      &:hover {
        background-color: $lighter-grey;
      }

      h3 {
        text-decoration: underline;
      }

      a, span {
        display: block;

        &:not(first-child) {
          font-size: 14px;
        }
      }
    }
  }

  &.white-section {

    form.form-inline.text-search {
      input {
        background-color: $white;
        filter: unset;
        border-bottom-color: $black;
        caret-color: $black;
        color: $black;
      }

      input::placeholder {
        color: $black!important;
      }

      button {
        background-color: $white;
        filter: unset;
        border: 1px solid $black;
        color: $black;

        &:hover {
          background-color: $dusty-grey;
        }
      }
    }

    .grid .grid-element:hover {
      background-color: $dusty-grey;
    }
  }

  .loader {
    animation: fadeinloader 1s;
    padding: 20px 50px;
    margin: auto;
    width: 10%;

    .spinner {
      display: block;
      height: 4em;
      width: 4em;
      animation: rotationloader 1.5s infinite linear;
      border: 4px solid $white;
      border-top-color: $light-grey;
      border-radius: 50%;
      margin: auto;
    }
  }

  @keyframes fadeinloader {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes rotationloader {
    to {
      transform: rotate(360deg);
    }
  }
}
