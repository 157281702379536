#countryModal {


  .modal-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
    background-image: none;
  }

  .container-fluid {
    position: relative;
    padding-bottom: 80px;
 
  }

  .logo {
    width: 200px;
    height: auto;
    text-align: center;
    margin: 0 auto 40px;
  }

  .titolo {
    margin-bottom: 40px;
  }  

  .description {
    font-size: 1.25rem; 
  }

  #btnGoTo {
    display: inline-block;
    max-width: 300px;
    margin: auto;
    background: #000; 
    color: #ffffff;
  }

  #btnCancel {
    text-decoration: underline; 

    position: absolute;
    display: block;
    bottom: 0;
    @include centre(x);

  }
}

.promoPopUp{
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 0px 0px;
    grid-template-areas:
            "title "
            "gallery"
            "textBlock";
    @media (min-width: $tablet-L) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      grid-template-areas:
            "title gallery"
            "textBlock gallery"
            "textBlock gallery";
    }
  }

  &.txt-white {
    .container-text.textBlock,
    .modal-content .modal-header .close {
      color: $white !important;
    }
  }

  &.text-on-right {
    .wrapper {
      @media (min-width: $tablet-L) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        grid-template-areas:
              "gallery title"
              "gallery textBlock"
              "gallery textBlock";
      }
    }
  }

  &.text-in-center {
    .wrapper {
      @media (min-width: $tablet-L) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
          "title "
          "gallery"
          "textBlock";
      }
    }
  }

  .title { grid-area: title; }
  .gallery { grid-area: gallery; }
  .textBlock { grid-area: textBlock; }
}
 