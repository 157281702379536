.products-overview-page {
    background-color: $white;
    color: $black;
    
    a {
        color: $black;
        
        &:hover {
            color: $black;
        }
    }
    .reference-tab-component{
        .tab-content{
            display: block;
        }
    }

    .navbarCategories {
        padding:50px 0 0 0;
        flex-wrap: wrap;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        &:not(#bundle-list-tabs ) {
            @media screen and (min-width: $desktop-XL) {
                padding-left: 25px;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .nav-item {
            position: relative;
            .nav-link {
                background: none;
                border-radius: 0;
                height: 37px;
                color: $product-grey;
                font-family: $font-secondary;
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 1.44px;
                line-height: 36px;
                text-transform: uppercase;
                display: inline-block;
                text-align: center;
                padding: 12px 16px 13px;

                &:before {
                    content: "";
                    display: block;
                    width: 0px;
                    height: 3px;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    background: $black;
                    transition: width 0.3s;
                }

                &.active {
                    color: $black;

                    &:before {
                        width: 51px;
                    }
                }

                &:hover {
                    color: $black;
                }
            }
        }
    }

    .action-link {
        color: $black;
        
        span {
            color: $black;
        }
        
        &:hover {
            color: $black;
        }
    }
    
    .products-list {
        padding:50px 0;
        
        .products-list__item {
            > a {
                margin-left: auto;
                margin-right: auto;
            }
            .products-list__container_images{
                position: relative;
                width: 100%;
                max-width: 300px;
                min-height:300px;
                margin: 0 auto;
                @media (min-width: $tablet-P) {
                    margin:unset;
                    max-width: 300px;
                }
                @media (min-width: $tablet-L) {
                    margin:unset;
                    max-width: 300px;
                    min-height:240px;
                }
                @media (min-width: $desktop) {
                    margin:unset;
                    max-width: 300px;
                    min-height:230px;
                }
                @media (min-width: $desktop-XL) {
                    margin:unset;
                    max-width: 300px;
                    min-height:300px;
                }
            }
            .products-list__item__image_top {
//                 position: absolute;
                width: 100%;
//                 max-width: 300px;
                img {
                }
            }
            .imageSwitcher {
    
                .products-list__item__image {
//                     position: absolute;
                    width: 100%;
//                     max-width: 300px;
                    img {
                    }
                }
    
//                 &:hover {
//                     .products-list__item__image {
//                         display: inline;
//
//                     }
//
//                     .products-list__item__image_top {
//                         display: none;
//                     }
//                 }
            }
        }
        
        &:not(.js-product-list-slider) {
            
            .products-list__item {
                width: 100%;
                max-width: 300px;
                margin: 0 auto;
                padding-top: 30px;
                padding-bottom: 30px;
                
                .products-list__item__title {
                    text-align: left;
                    color: $light-black;
                    font-family: $font-secondary;
                    font-size: 22px;
                    font-weight: 500;
                    letter-spacing: 1.32px;
                    padding-top:$text__productCategory--pt;
                }
                
            }
            
        }
        
        &.js-product-list-slider {
            margin-top: 50px;
            margin-bottom: 50px;
            
            .slider-arrow {
                
                img {
                    position: absolute;
                    width: 20px;
                    left: 0;
                }
                
                &:before {
                    content: "";
                }
                
            }
            
            .products-list__item {
                
                .products-list__item__title {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    text-align: center;
                }
                
            }
            
        }
        
    }
    
    .products-all {
        position: relative;
        
        .products-all__description {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            display: flex;
            align-items: center;
            color: $white;
            
            h1 {
                text-transform: uppercase;
            }
            
            .action-link {
                color: $white;
            }
            
        }
        
    }
}
