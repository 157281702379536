.product-comparison{

  input[type=checkbox] {
    vertical-align: middle;
  }

  label {
    color: $t-color-secondary;
    margin-left: .4rem; 
    margin-bottom: 0;
    display: flex;
    align-items: center;

    .products-list-page & {
        color: $dark-grey;
    }
  }
}
.comparator-section{
  position: relative;
  .breadcrumb__row{
    #breadcrumb{}
    .breadcrumb-item{
      margin-top: -50px;
      @media (min-width: $desktop){
        margin-top: unset;
      }
    }
  }

}
.comparator-container {
  display: none;
  background-color: #000;
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
  padding: 20px 0 40px;
  z-index: 3;

  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  color: #000;
  overflow: hidden;

  @media (min-width: $tablet-L){
    padding: 10px 0 20px;
  } 

  .action-link {
    display: inline-flex;
    vertical-align: middle;
    color: white;
    width: auto;
    background-color: #000;
    border: 0;
    margin-top: 15px;
    margin-left: -50px;

    @media (min-width: $tablet-L) {
      margin: 0; 
    }
  }

  .btn-removeAllSkus {
    @include resetButtonStyle;
    position: absolute;
    top: unset;
    //right: 70px;
    //padding: 18px;
    font-size: 12px;
    font-weight: 600;
    @media (min-width: $tablet-L) { 
      position:unset;
    }

  }

  .comparator-wrap {
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    position: relative; 
    padding: 0 15px;

    @media (min-width: $tablet-P) {
      display: flex;
    }

    @media (min-width: $desktop) {
      //width: calc(85% - 200px);
      max-width: 1920px;
      //margin: 0 auto;
      display: flex;
      overflow-x: hidden;
      align-items: center;
      padding: 0 4%;
    }
    @media (min-width: $desktop-large) {
      
      padding: 0 7%;
    }
  }

  .comparator-sku-wrapper {
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    position: relative; 
    margin-top: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    // @media (min-width: $tablet-L) {
    //   width: calc(85% - 200px);
    //   max-width: $el-max-width;
    //   margin: 0 auto;
    //   display: inline-flex;
    //   overflow-x: hidden;
    // }

    // @media (min-width: $desktop) {
    //   max-width: 1920px;
    // }

    .comparator-sku-background {
      position: absolute; 
      z-index: 1; 
      height: 100%;
      width: 100%; 
      top: 0; 
      left: 0; 
      display: flex;
      margin: 0 10px;

      .comparator-sku-fake {
        border: 1px dashed #E6E6E6;
        height: 78px;
        margin: 0 10px;
        padding: 10px;
        position: relative;
        width: 78px;
        margin: 0 5%;

        @media (min-width: $tablet-P) {
          width: 120px;
          height: 120px;
          
        }

        @media (min-width: $desktop) {
          margin: 0 10%;
        }

        &:before {
          content:"";
          background-color: #E6E6E6;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

    .comparator-sku-container {
      color: #FFF;
      width: auto;
      margin: 0 10px;
      text-align: left;
      display: block;
      position: relative; 
      z-index: 3;
      
      display: flex;

      @media (min-width: $tablet-L) {
        width: 100%;
      }

      .comparator-sku-element {
        background-color: #fff;
        //border-radius: 3px;
        margin: 0 10px;
        //display: inline-block;

        position: relative;
        width: 80px;
        margin: 0 5%;

        @media (min-width: $tablet-P) {
          width: 120px;
        }
        @media (min-width: $desktop) {
          margin: 0 10%;
        }

        &:before, &:after {
          position: relative;
          left: 15px;
          content: ' ';
          height: 33px;
          width: 2px;
          background-color: #333;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }

        span {
          padding: 6px 6px 6px 12px;
          color: #000;
          font-family: 'Mulish', sans-serif;
          font-size: 15px;
          font-weight: 300;
          letter-spacing: 1px;
          line-height: 20px;
          vertical-align: middle;
        }

        button {
          margin: 6px;
          height: 20px;
          width: 20px;
          border: none;
          vertical-align: middle;
          background-size: contain;
          background-position: center;
          background-color: transparent;
          background-image: url("data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACpUlEQVR4AezYAWQyYRzH8ReHMIQhhDAMEIYQADCEAwxDGIbDMIQhDMMwhBCGIYRhgHAIQ4AwhHCAwxAOz/tF4Mf2PPc8V4a+fID3yf/fdTf3/qusY8eONdHHCDMssN5JMcULrnCKP1EdAyxgSigwR4IaDl4NA+QwgTLcIMJBukQGU7EvdLDXHixDpLhDDx20drqIMcDS8tPqo/JO8AYDlSMpeWM28YAtDNQzIlTWDEYUGKIO3xoY/bJEJQ1hRIYuqqqHbxjRR1AxjFihhaprI9OrHHJj15FDv/km9lUbeiW+fO+HR/mgLfTbiDBB1/PqPkHrwYgbn5tLnxD30OGnMPguuUSM4pebVW/sDDU492z/gN3wcF9Chwfw5PAFJnBuLYevoSUw1iXswxeIHf5ozsvcSDpUDRSyhH14qQn9t6ewNpCDM0huS/gMLy3lzBWsvdsPuS9Ranj7l/kCa59y6ALkvYT78PY/pFNY28ihJshviZLDa105n8KaEWVLAobXWvIZ60MsEKPweMS6PIk2sJbJoYb38OFLdOT8AlTu0dX2Hz74SvRcHunahxyKA4aPkQQscSfnRj4vMK/+wxMFLJH6vODooytH5D+89xKnML6PdL2Re/7Dey+RlLmBtbHDW9HcfXjrEhOHt8EBnDtDYfn9nSC1D29dYorIch/mqIP8r0KOc10Cc8QoW/LD8F0Ulm/f+7VyhTr2VQtZyOukdgsj0j0t0cQKRlwiqAmMWOEcVdVBBiMeEFyEFEbk6COCbzXcYwsj3lBZDV1CHrE9RCUHv0YGAzXDCSot0p+TyPGKGG005Pd9gSvM9H/fxBB77RZbmIrliHGQGhijgAm0xSPqOHhnGCODKWmNZzTwJ+piiA8sZakNPvGOAdpw6dixY/9HAAAATkFxNQBe1hoAAAAASUVORK5CYII=");
          background-repeat: no-repeat;

          position: absolute;
          right: -12px;
          top: -6px;
          background-color: #fff;
          border-radius: 50%;

          &:hover {
            cursor: pointer;
            opacity: 0.5;
          }

          &:focus {
            outline: 0;
          }
        }

        .comparator-sku {
          color: #000;
          text-align: center;
        }

        .comparator-img {
          padding: 9px;
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.comparator__header{
  position: sticky;
  top:60px;
  background-color: $white;
  z-index: 1;
  max-width: 1920px;

  @media (min-width: $desktop) {
    position: initial;
    top:unset;
  }
}

.comparator-product-image {
  max-height: 300px;
  max-width: 300px;
  width: 100%;
  padding: 10px;
}

.comparator-product-image-container {
  text-align: center;
  align-content: center;
  width: 25%;
}

.comparator-table {
  padding-top: 125px;
  margin-bottom: 100px;
  display: block;
  max-width: 100%;
  thead{
    display: table;
    width: 100%;
  }
  tbody{
    display: table;
    width: 100%;
  }
  .comparator-product-feature-key{
    max-width: 25%;
    width: 25%;
  }
  .comparator-product-feature-value{
    width:25%;
  }
}

.comparator-show-difference-cell {
  text-align: center;
  padding-bottom: 20px;
  height: 100px;
  width: 25%;

  span {
    display: block;
    color: $black;
    font-weight: bold;
    padding: 15px 10px;
    border-radius: 5px;
    min-width: 250px;
  }
}

.comparator-product-feature-group-key {
  color: #020202;
  font-family: Raleway, sans-serif;
  border-bottom: 1px solid $light-grey;
  font-weight: 400;
  letter-spacing: 1.28px;
  line-height: 40px;
  text-transform: uppercase;
  td {
    padding: 10px 50px 10px 0;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 500;
    @media (min-width: $desktop) {
      height: 75px;
      padding: unset;
      vertical-align: middle;
    }
  }
}

.subTable-header{
  position: relative;
  td {
    position: relative;
      &:after {
        content: "";
        float: right;
        background-image: url('#{$images}/icons/arrow-accordion.svg');
        background-size: contain;
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        transform: translateY(-50%) rotate(180deg);
        top: 50%;
        right:0;
      }
  }
  &.active {
    td {
      &:after {
        transform: rotate(0deg);
        margin-top: -5px;
      }
    }
  }
}

.comparator-product-feature-key-row {
  height: 70px;
  border-bottom: 1px solid $light-grey;
  max-width: 25%;
  width: 25%;
}

.comparison-row-to-hide{
  height: 70px;
}

.comparator-product-feature-key {
  color: #797979;
  font-family: Raleway, sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.68px;
  line-height: 16px;
  padding-left: 35px;
  text-transform: uppercase;
}

.comparator-product-feature-value {
  text-align: center;
  padding-right: 25px;
  word-break: break-word;
  width:25%;
}

.comparator-products-empty {
  padding: 30px 0;
  text-align: center;
}

.comparator-product-optional-head-mobile {
  padding-left: 0;
  vertical-align: bottom;
}

.comparator-product-optional-head-desktop {
  text-align: center;
}

.comparator-product-optional-text-mobile {
  padding: 10px 0;
}

.comparator-product-optional-text-desktop {
  padding: 10px 0;
  text-align: center;
  width: 100%;
}

.comparator-product-optional-img {
  padding: 10px 0;
  width:50%;
}

.comparator-product-optional-description {
  display: inline-block;
  padding-top: 3px;
  font-weight: normal;
  text-transform: capitalize;
  line-height: 18px;
}

.product-preview__header{
  .name {
    word-break: break-word;
    font-size: .8rem;
    line-height: initial;
    @media (min-width:$desktop) {
      font-size: inherit;
    }
  }
}

.sharePrint{
  top: 15px;
  position: absolute;
  right: 0;
  margin-right: -15px;
}
.comparator-header {
  border-bottom: 1px solid #DCDCDC;
  margin: 0 30px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  &:after{
    content: "";
    background: url('#{$images}/icons/arrow-accordion.svg') center no-repeat;
    height: 20px;
    width: 20px;
    display: inline-block;
    rotate: 180deg;
    transition: transform .15s ease-in-out;
    cursor: pointer;
  }
  .comparator-header-txt {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    padding-bottom: 10px;

    p {
      font-size: 0.8rem;
      margin: 0;
    } 
  }
  .comparator-header-title {
    font-weight: 700;
    margin-right: 20px;
    img {
      margin-right: 10px;
    }
  }
}
.comparator-actions{
  @media (min-width: $tablet-P) {
    width: 25%;
  }
  button {
    display: block;
    width: calc(100% - 30px);
    margin: 0 auto;

    @media (min-width: $tablet-P) {
      width: 100%;
    }
  }
  .btn-comparator {
    background-color: #000; 
    color:#FFF;
    margin-bottom: 10px;
    border: 0;
    padding: 5px 0;

    &:disabled {
      opacity: 0.5;
    }
  }

  .category-link a,
  .btn-removeAllSkus {
    border: 1px solid #000;
    color: #000;
    padding: 4px 10px;
    margin-top: 10px;
    position: static;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: normal;
    display: block;
    width: calc(100% - 30px);
    margin: 0 auto;

    @media (min-width: $tablet-P) {
      width: 100%;
    }
  }

  .btn-removeAllSkus {
    display: none;
  }
  p {
    line-height: normal;
    white-space: break-spaces;
    display: block;
    color: #888888;

    &.opacity-0 {
      opacity: 0;
    }
  }
}


