$minus-base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAACCAYAAAA0AK6OAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAAAGUlEQVQYGWP8//9/K8MgBIxAh/0fhO5iAACEUweB+17dgAAAAABJRU5ErkJggg==';
$plus-base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAmBAMAAAC10Z+jAAAAAXNSR0IB2cksfwAAABVQTFRFAAAA////////////////////////SM9tBAAAAAd0Uk5TAP+FK7/glNiLS9UAAAAkSURBVHicY2CAAUYFBkwwKkg7QRMXKHAMgbESsAsOrDtHsCAAF+sPdzB0bYIAAAAASUVORK5CYII=';
$banner-height: 80px;

form.form-inline.text-search {
  .input-group {
    > input {
      //background-color: $light-black;
      //filter: brightness(3);
      background-color: #424242;
      color: $white;
      margin: 0;
      padding: 0 12px;
      border: none;
      border-bottom: 1px solid $white;
      border-radius: 0;
      &::placeholder{
        font-style: italic;
        color: #fff;
      }
    }
    > button {
      //background-color: $light-black;
      //filter: brightness(3);
      background-color: #424242;
      color: $white;
      margin: 0;
      border: none;
      border-bottom: 1px solid $white;
      border-radius: 0;
      font-family: 'Mulish', sans-serif;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 1.82px;
    }
    > select {
      max-width: 140px;
      text-indent: 10px;
    }
  }
}
