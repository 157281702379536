.cards-component {
    max-width: $max-screen-size;
    margin: 0 auto;
    &.cards-component--container {
        @media (max-width: $desktop) {
            .cards-category_title {
                padding-top: 50px;
            }
        }
        @media (min-width: $mobile) {
            padding-top: 50px;
        }
        @media (min-width: $desktop) {
            padding-top: 100px;
            padding-bottom: 100px;
        }
        .cards-category_title {
            .title{
                padding-bottom: 50px;
            }
        }
    }
    
    .cards {
        margin-bottom: 150px;
        @media (min-width: $mobile) {
            @include container();
        }
        &__card {
            background-color: unset;
            padding-top: 0px;
            padding-bottom: 50px;
            border: none;
            @media (min-width: $tablet-L) {
                padding-bottom: 50px;
                max-width: 460px;
                margin: 0 auto;
            }
            @media (min-width: $desktop) {
                padding:50px 0 0;
            }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background-color: rgba(0, 0, 0, 0);
                max-width: none;
            }
            
        }
        &__image{
            padding-bottom: 25px;
            
            img{
                width: 100%;
                height:auto;
            }
            
            img.box--bigger {
                max-width: 640px;
            }
            
        }
        
        &__textBox{
            padding-left: 37px;
            padding-right: 37px;
            max-width: 460px;
            @media (min-width: $mobile) and (max-width:$tablet-P) {
                padding-left: 37px;
                padding-right: 37px;
            }
            @media (min-width: $tablet-P) {
                padding-left: 0px;
                padding-right: 0px;
            }

        }
        &__title{
            font-family: $font-secondary;
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 1.28px;
            margin-bottom: 25px;
            //@media (min-width: $tablet-P) {
            //    font-size: 20px;
            //    line-height: 38px;
            //}
            @media (min-width: $desktop) {
                font-size: 20px;
            }
            @media (min-width: $desktop-XL) {
                font-size: 32px;
            }
        }
        &__text{
            font-family: $font-primary;
            font-size: 16px;
            letter-spacing: 0.32px;
            line-height: 32px;
            font-weight: 400;
        }
        &__link{
            font-family: $font-secondary;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.44px;
            line-height: 36px;
            text-transform: uppercase;
            margin-top: $text--pt;
        }
    }
    
    .slider-cards-component {
        justify-content: space-around;
        @media (min-width: $desktop) {
            justify-content: normal;
        }
    }
}

.cards-minimal{
    padding: 50px 0;
    justify-content: space-around;
    flex-direction:row;
    @media (max-width: $mobile) {
        justify-content: unset;
    }
    .cards__card{
        max-width: 400px;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
    }
    .cards__textBox {
        padding-right: 0;
        position: relative;
        @media (min-width: $tablet-L) {
            max-width: 250px;
            margin: 0 auto;
        }
        &::before {
            content: '';
            width: 1px;
            height: 100%;
            background-color: $white;
            position: absolute;
            margin-left: -45px;
            left: unset;
            top:0%;
            @media (min-width: $tablet-L) {
                margin-left: 0px;
                height: 120%;
                left:-50px;
                top:-10%;
            }
            
        }
        @media (min-width: $desktop-XL) {
            max-width: unset;
        }
        
    }
}

.white-section {
    
    .cards-component {
        &__card {
            padding-top: 25px;
            padding-bottom: 25px;
            
            @media (min-width: $mobile) {
                padding-top: 50px;
            }
            
            @media (min-width: $tablet-L) {
                padding-top: 150px;
                padding-bottom: 150px;
            }
            
        }
        .cards__textBox{
            &:before{
                background-color: $color-primary;

            }
        }
    }
    
}

.productsPreview{
    padding: 30px 0;
    .products{
        
        padding-top: 30px;
        padding-bottom: 30px;
        transition: .3s all ease-in-out;
        text-align:center;
        flex: 1;
        @media (min-width: $tablet-L) {
            padding-top: 100px;
            padding-bottom: 50px;
        }
        @media (min-width: $desktop) {
            padding-top: 150px;
            padding-bottom: 75px;
        }
        .title{
            color:$white;
            font-family: $font-secondary;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.72px;
            line-height: 26px;
            @media (min-width: $tablet-P) {
                font-size: 32px;
                font-weight: 400;
                letter-spacing: 1.28px;
                line-height: 38px;
            }
        }
        .cta{
            
            font-family: $font-secondary;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.44px;
            line-height: 16px;
            text-transform: uppercase;
            width: 300px;
            text-align: center;
            margin: 0 auto;
            margin-top: 20px;
            @media (min-width: $tablet-P) {
                text-align: center;
                margin-top: unset;
                display: flex;
                justify-content: center;
                line-height: 36px;
                width: auto;
            }
            a{
                justify-content: center;
                line-height: 16px;
                @media (min-width: $tablet-P) {
                    line-height: 36px;
                }
            }
        }
        .image{
            transition: .3s all ease-in-out;
            transform: translateY(0px);
            padding-top: 20px;
            picture,img{
                height: 265px;
                object-fit: cover;
                @media (min-width: $tablet-P) {
                    width: auto;
                    height: 400px;
                }
            }
        }
        @media (min-width: $desktop) {
            &:hover {
                transition: .3s all ease-in-out;
                transition-delay: .3s;
                background-color: white;
                
                .title {
                    transition: .3s all ease-in-out;
                    color: $black;
                    font-weight: bold;
                    
                    a {
                        transition: .3s all ease-in-out;
                        color: $black;
                        font-weight: bold;
                    }
                }
                
                .cta {
                    transition: .3s all ease-in-out;
                    transition-delay: .3s;
                    color: $black;
                    
                    a {
                        transition: .3s all ease-in-out;
                        transition-delay: .3s;
                        color: $black;
                        transition: .3s all ease-in-out;
                        transition-delay: .3s;
                        
                        &:before {
                            transition: .3s all ease-in-out;
                            transition-delay: .3s;
                            border-top: 1px solid $black;
                        }
                    }
                }
                
                .image {
                    @media (min-width: $desktop) {
                        transition: .3s all ease-in-out;
                        transition-delay: .3s;
                        transform: translateY(-20px);
                    }
                    
                }
            }
        }
        
    }
}
.white-section{
    .productsPreview{
        .products{
            @media (min-width: $desktop) {
                &:hover {
                    background-color:$color-primary;
                    .title,
                    .cta{
                        color: $t-color-secondary;
                        a{
                            color: $t-color-secondary;
                            text-decoration: none;
                            &:before{
                                border-top-color: $t-color-secondary;
                            }
                        }
                    }
                }
            }

        }
    }
}


.cards-grid{
    
    .cards__card{
        padding: 0;
        max-width: unset;
    }
    .cards-grid__gallery{
        @media (min-width: $desktop) {
            padding: 110px 0;
        }
    
        /* the slides */
        .slick-slide {
            margin-left:27px;
        }
    
        /* the parent */
        .slick-list {
            margin-left:-27px;
        }
        
        .slick-arrow {
            z-index: 10;
        }
        
        .cards-grid__slidersBlock{
            .cards-grid__slide{
                @media (min-width: $desktop) {
                    padding: 20px 0;
                }
                min-height: 350px;
                .cards__card {
                    display: flex;
                    justify-content: unset;
                    flex-direction: column;
                    @media (min-width: $desktop) {
                        justify-content: space-around;
                        flex-direction: row;
                    }
                    
                    .cards__image {
                        width: 100%;
                        @media (min-width: $desktop) {
                            width: 300px;
                        }
                    }
                    .cards__textBox {
                        padding: 0 37px 50px 37px;
                        width: 100%;
                        @media (min-width: $tablet-P) {
                            max-width: 450px;
                            padding: 0 0 50px;
                        }
                        @media (min-width: $desktop) {
                            padding: 0 $space--sm;
                        }
                        .cards__title {
                            max-width: 461px;
                            font-family: $font-secondary;
                            font-size: 20px!important;
                            font-weight: 400;
                            letter-spacing: 1.04px;
                            line-height: 3rem;
                            text-transform: uppercase;
                            margin-bottom: $space--sm;
                            
                        }
                        .cards__text {
                            font-family: $font-primary;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.32px;
                            line-height: 34px;
                            p{
                                line-height: 1.5rem;
                            }
                        }
                        .cards__link{
                        
                        }
                    }
                }
            }
            
        }
        
    }
    
}

.white-section{
    .cards-grid{
        .cards__card{
            .cards__textBox{
                color: $color-primary;

            }
        }
    }

}

.cards-partners{
    @include pv-50;
    .header{
        @include container;
        @media (min-width: $desktop) {
            @include container--disabled;
        }
        .title{
            @include newtitle();
            padding: $space--md 0 $space--lg 0;
            text-transform: uppercase;
            font-weight: 400;
            line-height: 38px;
            letter-spacing: 1.28px;
        }
    }
    .card {
        background-color: transparent;
        border-color: transparent;
        max-width: 300px;
        .title{
            text-align: center;
            padding-bottom: $space--sm;
        }
        .image {
            height: 200px;
            img{
                height: 100%;
                max-width: 100%;
            }
        }
        .link{

        }
    }
}