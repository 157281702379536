
 $product-details__image_padding: 40px;

 .page__product-detail{
   @media (min-width: $tablet-L) {
     //padding-top: 50px;
     padding-top: 25px;
   }
   .btnMinSize {
     min-width: $pdpMinWidth;
   }
 }


.product-details {
  margin-bottom: 50px;
  margin-top: 50px;

  @media (min-width: $tablet-L) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .product-details__main {
    display: flex;
    align-items: flex-end;
  }

  .detail--title {
    color: $black;
  }

  .family {
    color: $black;
    font-family: $font-secondary;
    font-size: 32px;
    font-weight: 300;
    display: block;

    @media (min-width: $tablet-L) {
      font-size: 42px;
    }
  }

  .aesthetics {
    color: $black;
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 1rem;
    display: block;

    @media (min-width: $tablet-L) {
      font-size: 34px;
    }
  }

  .code {
    color: $product-grey;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.56px;
    line-height: 16px;
    text-transform: uppercase;
    display: block;
    @media (min-width: $tablet-L) {
      font-size: 24px;
      letter-spacing: 1.12px;
      line-height: 36px;
    }

  }

  .prices {
    text-align: right;
    color: black;
    position: relative;

    .price {
      color: black;
      font-size: 2.3rem;
      font-weight: 600;
      margin-top: 5px;
    }

    .price-eco {
      color: black;
      font-size: 1.3rem;
      font-weight: 400;
      margin-top: 5px;
      line-height: 12px;
    }

    .price--strikethrough {
      color: $color-secondary--disabled;
      text-decoration: line-through;
      font-weight: normal;
      font-size: 1.375rem;
    }

    .price--strikethrough-eco {
      color: $color-secondary--disabled;
      text-decoration: line-through;
      font-weight: normal;
      font-size: 1rem;
    }

    .price--discounted {
      color: black;
      font-weight: bold;
      font-size: 1.8rem;
    }

    .price--discounted-eco {
      color: black;
      font-weight: 400;
      font-size: 1.3rem;
    }

  }

  .ean {
    color: $product-grey;
    font-family: $font-primary;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 14px;
    text-transform: uppercase;
    display: block;

    @media (min-width: $tablet-L) {
      font-size: 20px;
      letter-spacing: 1.6px;
      line-height: 16px;
      margin-bottom: 30px;
    }
  }

  .product-details__tags {
    display: inline-flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .product-details__tag__item {
      background-color: $product-light-grey;
      color: $product-grey;
      font-family: $font-primary;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 1.4px;
      line-height: 16px;
      text-transform: uppercase;
      padding: 4px 15px;

      @media (min-width: $tablet-L) {
        font-size: 12px;
        letter-spacing: 1.68px;
        line-height: 16px;
      }
    }
  }

  .product-details__slider {

    .product-details__slide {
      .product-details__image {
        @media (max-width: $mobile) {
          padding: 0;
        }

        padding: $product-details__image_padding;
      }

      .product-details__compare {
        text-align: right;
        color: $black;
        font-family: $font-secondary;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.44px;
        line-height: 36px;

        img {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
          display: inline-block;
        }

        > div {
          display: inline-block;
        }

        &:hover {
          cursor: pointer;
        }

      }


    }

    .slider-arrow {

      img {
        position: absolute;
        width: 30px;
        left: 0;
      }

      &:before {
        content: "";
      }

      &.slick-prev {
        left: -10px;

        @media (min-width: $tablet-P) {
          left: -30px;
        }

      }

      &.slick-next {
        right: -10px;

        @media (min-width: $tablet-P) {
          right: -20px;
        }
      }
    }

    ul.slick-dots {
      text-align: center;
      bottom: -40px;
      height: 50px;

      @media (min-width: $tablet-L) {
        bottom: 0;
        position: inherit;
      }

      li {
        width: 102px;
        margin-left: 0px;
        margin-right: 0px;

        @media (min-width: $tablet-L) {
          max-width: 58px;
          margin-left: 0px;
          padding-right: 10px;
        }

        button {
          border-bottom: 3px solid $grey;
          width: 100%;

          &:before {
            content: "";
          }

        }

        &.slick-active {
          button {
            border-color: $black;
          }
        }
      }
    }

  }

  .symbols {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;

    .symbol {
      cursor: pointer;

      &.symbol-extended {
        width: 100%;
        font-size: 12px;
        line-height: normal;
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &:hover{
          text-decoration: underline;
        }
        

        .symbol__img {
          margin-right: 5px;
        }
        
      }

      a {
        color: #020202;
        img {
          width: 40px;
          @media (min-width: $tablet-L) {
            width: 60px;
          }
        }
      }
    }
  }

  .product-details__infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0px;
    place-items: center;

    .btn_backInStock{
      display: flex;
      place-items: center;
      line-height: initial;
      margin-top: 6px;
      margin-bottom: 6px;
      padding: 10px 25px;
      min-height: 45px;
      background-color: var(--color-primary);
      color: var(--color-secondary);
    }

    .product-details__info {
      width: 45%;
      margin-top: 15px;
      margin-bottom: 15px;
      padding-right: 15px;

      @media (min-width: $tablet-L) {
        width: 24%;
      }
    }

    .product-details__designProduct {
      display: flex;
    }

    .product-details__genericProduct {
      display: flex;
      flex-direction: row;
    }

    .product-details__info__type {
      min-width: 150px;
      color: $product-grey;
      font-family: $font-secondary;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.68px;
      line-height: 16px;
      display: flex;
      align-items: center;
      overflow-wrap: anywhere;
    }

    .product-details__info__value {
      min-width: 150px;
      color: $light-black;
      font-family: $font-primary;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;
      @media (min-width: 1340px) {
        min-width: 120px;
      }
    }

    &.RAE_included {
      color: $color-primary;
    }
  }

  .product-details__infos_priceEnergy {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
 

  .product-details__infos.buttonRow {
    margin-top: 15px;
  }

  .product-details__actions {
 

    display:inline-flex;
    justify-content:center;
    align-items: center;
    min-width: $minWidth;
    white-space: nowrap; 
 

    .button-primary {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }

    span {
      text-align: center;
      width: 100%;
    }
  }

  .free-field{
    color: $color-primary;
  }

}

.btn_secondary{
  padding: 0;
  border: none;
  background-color: transparent;
  font-family: $font-primary;
  width: auto;
  color: var(--color-primary);
  &:hover{
    text-decoration: underline;
  }
  &:focus{
    outline: 0;
  }
  .icon{
    margin-right: 5px;
    font-weight: 400;
  }
}

.product-details__main {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: minmax(100px, $desktop) 1fr;
  grid-template-areas: "title""gallery""features";

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    position: relative;
    min-height: 800px;
  }

  .main__title {
    grid-area: title;
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      max-width: 50%;
    }
  }

  .main__gallery {
    position: relative;
    grid-area: gallery;
    grid-column: 1;
    grid-row: 2;

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      max-width: 50%;
      position: absolute;
      float: right;
      top: 0;
      right: 0;
    }

    .modal {

      .modal-dialog {

        @media (min-width: $tablet-P) {
          max-width: 700px;
        }

        @media(min-width: $tablet-L) {
          max-width: 900px;
        }

        @media (min-width: $desktop) {
          max-width: 1140px;
        }

        .modal-body {
          padding: 0;

          @media (min-width: $mobile) {
            padding: 1rem;
          }

          .carousel-control-prev,
          .carousel-control-next {
            display: none;

//             @media (min-width: $desktop) {
//               display: -ms-flexbox;
//               display: flex;
//             }
          }
        }
      }

      .carousel {
        .carousel-control-prev-icon {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
        }

        .carousel-control-next-icon {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
        }

        .carousel-item {

          img {
            height: 350px;
            object-fit: contain;

            @media (min-width: $tablet-P) {
              height: 70vh;
            }

            @media (min-width: $desktop) {
              height: 90vh;
            }
          }
        }
      }
    }
    
  }



  .main__features {
    grid-area: features;
    grid-column: 1;
    grid-row: 3;

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      max-width: 50%;
    }

    .btn {
      width: 100%;

      @media only screen and (min-width: $tablet-P) {
        width: inherit;

      }

      &.product-link__buy {
        @media only screen and (min-width: $tablet-P) {
          text-align: left;
        }

        button {
          @media only screen and (min-width: $tablet-P) {
            width: auto;
    
          }
        }
      }
    }

    .message--alert{
        line-height: 24px;
        display: flex;
    }
  }

  @media only screen and (min-width: $desktop) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, 1fr);

    .main__title {
      grid-column: 1 / 8;
      grid-row: 1 / 2;
    }

    .main__gallery {
      grid-column: 8 / 13;
      grid-row: 1 / 4;
      max-width: 740px;
    }

    .main__features {
      grid-column: 1 / 8;
      grid-row: 2 /4;
    }
  }
}

.accessoriesNotIncluded {
  .product-preview {
    &.accessories-preview {
      margin-bottom: 100px;
    }

    .accessories__gallery {
      max-width: 300px;
      padding: 20px;
      border: 1px solid rgba(0, 0, 0, .1);
      margin-bottom: 20px;

      img {
        width: 100%;
      }
    }

    &__header {
      color: #161616;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.96px;
      line-height: 28px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    &__description {
      color: #181818;
      font-family: $font-secondary;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.72px;
      line-height: 20px;
    }
  }

}

.product-subdetail {
  margin: 50px 0 80px;

  .product-detail__subtitle {
    margin-bottom: 60px;
    color: $black;
    font-family: $font-secondary;
    font-size: 24px;
    font-weight: 400;
    line-height: 58px;
  }
}

.product-exteded_description{
  margin: 20px 0 0;
  color: $black;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 400;
}

.product-detail {
  margin: 40px 0;

  &:last-child {
    margin-bottom: 20px;
  }

  .product-detail__title {
    margin-bottom: 15px;
    color: $black;
    font-family: $font-secondary;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 1.28px;
    line-height: 38px;
    text-transform: uppercase;
  }

  .product-detail__content {
    .icon-30 {
      height: 30px;
    }

    .details {
      margin-top: 15px;

      .detail {
        margin-top: 10px;

        &:first-of-type {
          margin-top: 0;
        }

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
          display: flex;
          align-items: baseline;
        }

        .detail__label {
            display: inline-block;
            width:45%;
            color: $product-grey;
            font-family: $font-secondary;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.68px;
            line-height: 16px;
            padding-top: 5px;
            padding-right:5%;
        }

        .detail__txt {
            display: inline-block;
            color: $black;
            font-family: $font-primary;
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            vertical-align:top;
            width: 50%;
        }
      }

    }

    .detail {
      &__list {
        margin-top: 80px;
      }
    }

    .detail-list {

      &__label {
        font-weight: bold;

      }

      &__list {
        padding-left: 0;

        &__li {
          display: inherit;
          width: unset;
          color: $product-grey;

          @media (min-width: $tablet-P) {
            display: inline-block;
            width: 24%;
            padding: 20px 0;
            vertical-align: top;
          }
          img{
            display: block;
          }
        }


      }
    }
  }
}
 

.product-details-content {
  padding-bottom: 100px;

  >.container:first-child {
    background-color: $product-light-grey;
  }

  .tab-component {

    li.tab-component__button {

      &:after {
        top: 0px;
      }

    }

  }

}
.pdp_tab--wrapper{
  padding-top: 70px;
}
.downloadTab {
  padding-top: 70px;

  .item {
    padding-bottom: 30px;

    .title {
      color: $black;
      font-family: $font-secondary;
      font-size: 25px;
      font-weight: 400;
      letter-spacing: 0.5px;
      padding-bottom: 0;
      margin-bottom: 5px;
    }
  }

}

.promotion-not-found{
  margin: 100px 0 200px 0;
  text-align: center;
}

.product-details-secondaryInfo {
 
  padding: 20px 0 0;

  @media (min-width: $mobile) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px  $product-details__image_padding ;
  
  }
}

.modal-download { 
  img {
    width: 100%;
  }
  
  .modal-header {
    border: 0;
  }
}

.pdp-nordics-img {
  max-width: 1920px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 15px auto;

  img {
    max-width: 98%;
  }
  
  .img-dx img{
    margin-left: 2%;
  }

  .img-sx img{
    margin-right: 2%;
  }
}


#reactPlaceholder_backInStockProductDetail {
  color:#161616;
  > div{
    margin: 0 !important;
  }
  .message,
  #privacy ~ label {
    color: #161616;
  }
}


.new-pdp {
  #reactPlaceholder_backInStockProductDetail.notNordics {
    color:#161616;
    > div{
      margin: 0 15px !important;
    }
  }
}

.backInStock__oldPdp {
    padding-left: 0.75rem;
    margin-bottom: 10px;
    #reactPlaceholder_backInStockProductDetail{
      width: 100%;
      @media (min-width: $desktop) {
        width: 80%;
      }
    }
}

 #gallery-modal {
   .modal-fullscreen {
     max-width: none !important;
     max-height: 100vh !important;
     width: 100vw;
     height: 100vh;
   }
   button.close {
     position: absolute;
     right: 20px;
     opacity: 1;
     z-index: 10;
   }
   .modal-dialog {
     max-width: 1024px;
     max-height: 100vh;
     height: auto;

     .zoomIcon {
       position: absolute;
       z-index: 2;
       &.zoom-plus {
         pointer-events: none;
         @media (max-width: $tablet-L) and (orientation: portrait){
           left: 1.1rem;
         }
       }
     }
     .carousel-item {

       &.zoomable-image {
         cursor: pointer;
       }

       img.zoomable-img {
         max-height: 70vh;
         height: auto;
         max-width: 100%;
         margin: 0 auto;
       }
     }
   }

   .carousel-control-next-icon,
   .carousel-control-prev-icon {
     background: none;
     height: auto;
     width: auto;
     display: none;

     //     @media (min-width: $desktop) {
     //       display: -ms-flexbox;
     //       display: flex;
     //     }
   }

   .carousel-control-prev-icon {


     &::after {
       content: "<";
       color: #000;
       font-size: 24px;
       font-family: monospace;
       background: #fff;
       border-radius: 50%;
       height: 30px;
       width: 30px;
       display: flex;
       align-items: center;
       justify-content: center;
     }

   }
   .carousel-control-next-icon {
     background: none;

     &::after {
       content: ">";
       color: #000;
       font-size: 24px;
       font-family: monospace;
       background: #fff;
       border-radius: 50%;
       height: 30px;
       width: 30px;
       display: flex;
       align-items: center;
       justify-content: center;
     }
   }

   .carousel-indicators {
     position: relative;
     margin: 0;
     width: 100%;
     overflow: hidden;
     overflow-x: auto;


     button {
       border: 0;
       margin: 2px 0;
       width: 9%;
       opacity: 0.5;
       background: none;
       max-width: 100px;
       min-width: 65px;

       &.active {
         opacity: 1;
       }

       img {
         width: 100%;
         height: auto;
       }
     }
     &::-webkit-scrollbar-track{
       -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
       background-color: #F5F5F5;
     }

     &::-webkit-scrollbar{
       width: 10px;
     }

     &::-webkit-scrollbar-thumb{
       background-color: #000000;
       border: 1px solid #555555;
     }

   }
 }

 .ecoPartecipation-table{
   table{
     @media (min-width: $desktop) and (max-width: $desktop-XL) {
      line-height: 20px;
     }
     tr{
       td{
         padding: 0;
         vertical-align: baseline;
         &:first-child{
           width: 150px;
         }
         //porcata per farci stare i prezzi sulla stessa riga all'interno della tabella mobile
         @media (max-width: 767.9px) {
           .price--strikethrough{
             font-size: 12px !important;
           }
           .price--discounted{
             font-size: 16px !important;
             span{
               padding: 0 !important;
             }
           }
         }
       }
     }
   }
 }