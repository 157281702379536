.aesthetic-sliders {
	position: relative;
	height: 100%;
	background-color: $black;

	.slick-list {
		padding: 0 !important;
	}

	.scroll-buttons {
		position: absolute;
		display: flex;
		align-items: center;
		display: flex;
		align-items: center;
		height: 200px;
		right: 0;
		margin-top: calc(50vh - 200px);

		.arrows-conatiner {
			position: relative;
			height: 200px;
		}

		.counterSlide {
			position: relative;
			right: 0;
			width: 45px;
			border-bottom: 1px solid #fff;
			z-index: 99;
			top: 10px;
		}

		.slick-arrow {
			width: 50px;
			height: 50px;
			z-index: 99;
			right: 0;
			left: auto;
			position: absolute;
			cursor: pointer;

			img {
				width: 40px;

				transform: rotate(90deg);
			}
		}

		.scroll-up {
			position: absolute;
			top: 50px;
		}

		.scroll-down {
			position: absolute;
			bottom: 0px;
		}
	}

	.aesthetic-slider {
		height: calc(100vh - #{map-get($burgerMenu, offset-top-m)});
		position: relative;
		cursor: grab;
		border: 0px !important;
		background-color: $black;

		@media (min-width: $desktop) {
			height: calc(100vh - #{map-get($burgerMenu, offset-top-d)});
			position: relative;
		}

		.aesthetic-slider__textBlock {
			@include container;
			position: absolute;
			height: 100%;
			display: flex;
			align-items: flex-start;
			margin-top: 50px;

			@media (min-width: $desktop) {
				align-items: center;
				margin-top: unset;
			}

			.aesthetic-slider__title {}

			.aesthetic-slider__introduction {}
		}

		.aesthetic-slider__image {
			width: 100%;
			@media (min-width: $desktop) {
			    object-fit: cover;
                height: 100%;

            }
		}
	}
}