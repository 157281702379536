@import "mixin";

//remove for accessibility menu
//Reset
//textarea:focus,
//input:focus,
//button:focus {
//    outline: none;
//}
//
//*:focus {
//    outline: none;
//}

.base-layout {
    //margin-top: 60px;
    //@media (min-width: $desktop) {
    //    margin-top: 120px;
    //}

    .base-layout__left-right {
        background-color: $black;
        .row{
            max-width: $max-screen-size;
            margin: 0 auto;
        }

        .col-xl-6:first-of-type {
            .container {
                @media (min-width: $desktop) {
                    padding-right: $text--px;
                }

            }
        }

        .col-xl-6:nth-of-type(2) {
            .container {
                @media (min-width: $desktop) {
                    padding-left: $text--px;
                }

            }
        }

    }
}

.desktop-menu {
    //display: none;
    //
    // @media (min-width: $desktop) {
    //     display: inherit;
    // }
}

.mobile-menu {
    display: inherit;
    position: fixed;
    top: var(--top-position-menu);
    left: 0;
    right: 0;
    z-index: 3;
    background-color: $bg-color-dark;

    @media (min-width: $desktop) {
        display: none;
    }
    .default-section{
        background-color: $bg-color-dark;
        color:$t-color-primary;
    }
}

//Slick slider default setting
.slick-slider {
    user-select: text;
}

.slick--same-height,
.slick--equal-height{
    .slick-track{
        display:flex!important;
    }
    .slick-slide{
        height:inherit!important;
    }
}

//Assign slider arrows height
.slick-arrow--productsCategoryHeight {
    .slick-arrow {
        top: 150px;

        @media (min-width: $tablet-P) {
            top: 165px;
        }

        @media (min-width: $tablet-L) {
            top: 190px;
        }

        @media (min-width: $desktop) {
            top: 175px;
        }
    }
}

.slick-arrow--relatedItemsHeight {
    .slick-arrow {
        top: 150px;

        @media (min-width: $tablet-P) {
            top: 22%;
        }

        @media (min-width: $tablet-L) {
            top: 30%;
        }

        @media (min-width: $desktop) {
            top: 30%;
        }
    }
}

//Assign slider dots color
.slick-dots--black,
.slick-dots--white {
    .slick-dots {
        button {
            border-bottom: 3px solid #b4b4b4;
        }
    }
}

.slick-dots--black {
    .slick-dots {
        .slick-active {
            button {
                border-color: $black !important;
            }
        }

    }
}

.slick-dots--white {
    .slick-dots {
        .slick-active {
            button {
                border-color: $white !important;
            }
        }
    }
}

//max-width dots on slick-slider
.slick-dots {
    li {
        max-width: 50px;
        margin: 0;
        padding: 0 5px;

        button {
            max-width: 50px;
            width: 100%;
            margin: 0;
            padding: 0 5px;
        }
    }

}

//Row setting
.row-reverse{
    flex-direction: row-reverse;
}

//Text color and weight
.text-black {
    color: $light-black;

    a:not(.action-link) {
        color: $light-black;
    }
}

.text-white {
    color: $white;

    a:not(.action-link) {
        color: $white;
    }
}

.text-bold {
    font-weight: bold;
}


b,
strong {
    font-weight: bold;
}

//Size
.w-icon--repairabbility{
    width: 40px;
}

//Padding
@mixin pv-50() {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: $tablet-P) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media (min-width: $desktop) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
.pv-50 {
    @include pv-50;
}

.pv-75 {
    @media (min-width: $desktop) {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    padding-top: 30px;
    padding-bottom: 30px;


}

.pv-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.pv-sm-0 {
    @media (max-width: $mobile) {
        padding-top: 0;
        padding-bottom: 0;
    }
}
.pb-50{
    padding-bottom: 30px;
    @media (min-width: $desktop) {
        padding-bottom: 50px;
    }
}

//Default class select
//put this class on div before
.select-dropdown {
    .select2.select2-container {
        height: calc(1.5em + .75rem + 2px);
        font-family: $font-primary;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.4px;
        line-height: 34px;
        width: 100% !important;

        @media (min-width: $tablet-P) {
            font-size: 20px;
        }

        .select2-selection {
            &.select2-selection--single {
                border: none;
                border-radius: 0;
                height: calc(1.5em + .75rem + 2px);
                color: $light-grey;
                font-family: $font-primary;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0.4px;
                line-height: 34px;
                background-color: transparent;
                border-bottom: 1px solid $light-grey;

                @media (min-width: $tablet-P) {
                    font-size: 20px;
                }

                &[aria-expanded="true"] {
                    border-bottom: none;
                }

                &:focus {
                    .select2-selection__rendered {
                        color: $t-color-primary;
                    }
                }

                .select2-selection__rendered {
                    font-family: $font-primary;
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: 0.4px;
                    line-height: calc(1.5em + .75rem + 2px);
                    color: $t-color-primary;
                    padding-left: 0px;
                    @media (min-width: $tablet-P) {
                        font-size: 1rem;
                    }
                }

                .select2-selection__arrow {
                    height: calc(1.5em + .75rem + 2px);
                    background-image: url('#{$images}/select-icon.png');
                    background-repeat: no-repeat;
                    background-size: 14px auto;
                    background-position: center center;

                    b {
                        display: none;
                    }
                }
            }
        }
    }
}

.select2-container--default {
    margin-top: -1px;

    .select2-dropdown {
        border: none;
        border-radius: 2px;
        border: 1px solid $light-grey;
        border-bottom: 3px solid $light-grey;

        .select2-search {
            background-color: $bg-color-primary;

            &.select2-search--dropdown{
                background-color: $bg-color-secondary;
            }

            .select2-search__field {
                background-color: $bg-color-secondary;
                color: $t-color-secondary;
                caret-color: $t-color-secondary;

                &::placeholder {
                    color: $light-grey;
                }
            }
        }
    }
}

//Default button
.button-empty-white-border {
    width: 100%;
    margin-top: 25px;
    display: table;

    >div {
        float: none;
    }

    @media (min-width: $tablet-P) {
        margin-top: 50px;
    }

    @media (min-width: $desktop) {
        >div {
            float: right;
        }
    }

    button {
        font-family: $font-secondary;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.92px;
        line-height: 24px;
        text-transform: uppercase;
        background-color: $black;
        color: $light-grey;
        border: 1px solid $light-grey;
        padding: 0 50px;
        height: 60px;
        text-align: center;

        &:hover {
            color: $white;
            border: 1px solid $white;
        }
    }
}

.navbarCategories {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border-radius: 3px;

    &::-webkit-scrollbar {
        display: none;
    }

    &__nav {
        width: 100%;
        vertical-align: middle;
        overflow-x: auto;
        overflow-y: hidden;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            overflow-y: hidden;
        }
    }

    &__item {
        height: 37px;
        color: $white;
        font-family: $font-secondary;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 1.44px;
        line-height: 36px;
        text-transform: uppercase;
        display: inline-table;
        text-align: center;
        padding: 12px 16px 13px;

        &.active {
            font-weight: 700;
            &:before {}
        }
    }

    &__border {
        border-top: 3px solid transparent;
        width: 51px;
        margin: 0 auto;
        padding-top: 15px;
    }
}

.eform-hidden-field {
    display: none;
}

/* Items not found */
.itemsNotFound{
    .title{
        color: $grey;
        font-family: $font-secondary;
        font-size: 26px;
        line-height: 42px;
    }
}

//links
//disabled links
a{

    &.link__disabled--mobile{
        text-decoration: none;
        display: inline-block;  /* For IE11/ MS Edge bug */
    }
    &.link__disabled--desktop{
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }
    @media (min-width: $desktop) {
        &.link__disabled--mobile{
            pointer-events: inherit;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
        }
        &.link__disabled--desktop{
            text-decoration: none;
            display: contents;
        }
    }

}


.visuallyHidden {
    @include visuallyHidden;
}

.hidden { 
    display: none !important; 
}

/* VIDEOS */

//show video audio on ios
.plyr--is-ios {
    .plyr__volume {
        display: flex !important;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .cmp_bannerSlider,
    .cmp_visualBox {
        .video {
            iframe {
                width: 100%;
                max-width: $el-max-width;
                height: 600px;
            }
        }
    }
}

/* LOADER */

.isLoading:empty,
.isLoading:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.isLoading:empty {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0,0,0, 0.2);
    border-right: 1.1em solid rgba(0,0,0, 0.2);
    border-bottom: 1.1em solid rgba(0,0,0, 0.2);
    border-left: 1.1em solid #000000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

//messages
.message--success{
    color: var(--color-success);
}

.message--limited-qta{
    color: #faa914;
}

//modal
.modal {
    button.close:focus {
        outline: 0 !important;
    }
}
.symbols-modal {
    .modal__title {
    }

    .modal__image {
    }

    .modal__textBlock{
        min-height: 100%;
    }
    .modal__description {
    }

    .modal__cta {
        position: absolute;
        bottom: 0;
    }
}

.tag-image {
    position: absolute;
    margin-top: 10px;
    background-color: var(--color-alert-dark);
    padding: 10px 20px;
    line-height: 1rem;
    font-weight: 600;
    font-size: 12px;
    z-index: 9;
    color: var(--color-secondary);
}
a {
    &:hover {
        span.tag-image {
            color: var(--color-secondary);
        }
    }
}

.cursor--pointer {
    cursor: pointer;
}

//arrow
.arrows-external{
     @media (min-width: $tablet-P) and (max-width: $desktop) {
         .slick-prev {
             left: -8%;
         }
         .slick-next {
             right: -8%;
         }
     }
}
