// Extra large devices (large desktops, 1367px and up)
$desktop-XL: 1367px;

// Extra large devices (large desktops, 1200px and up)
$desktop: 1200px;

// Large devices (desktops, 992px and up)
$tablet-L: 992px;

// Medium devices (tablets, 768px and up)
$tablet-P: 768px;

// Small devices (landscape phones, 576px and up)
$mobile: 576px;


$max-screen-size:1920px;
$desktop-large: 1600px;