/* Israele */

html[data-channel="smeg-israel-he"]{
  /* tutti i paragrafi con font 18px */
  p,
  .text,
  .nav__item,
  .nav-link p,
  .menu-link,
  .burgerMenu .head,
  .header-nav__store .store__link{
    font-size: 18px!important;
  }
  /* END tutti i paragrafi con font 18px */

  .nav-link.nav-first,
  .nav-link.nav-second{
    font-size: 1.5rem!important;
  }
}
/* Israele End */

/* Nordics */

html[data-channel="smeg-sweden-sv"],
html[data-channel="smeg-finland-fi"],
html[data-channel="smeg-norway-nb"],
html[data-channel="smeg-denmark-da"]{
  #navbarSmegContent {
    .secondNav__products {
      .nav-item {
        flex: 1 1 0px;
      }
    }
  }
  #reactPlaceholder_paymentMethods {
    .footerPartnership{
      @media (min-width: 1200px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .titolo{
        width:200px;
      }
    }
  }
}

/* Nordics End */


/* SouthAfrica */
html[data-channel="smeg-southafrica-en"]{
  .product-links{
    //display:block;
  }
  .btnSouthAfrica{
    width: 100%;
    border-radius:unset;
    background-color: var(--color-secondary);
    color: var(--color-primary);
    cursor: pointer;
    font-size: inherit!important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Raleway,sans-serif;
    @media (max-width: $tablet-P) {
      font-size: 12px !important;
    }
  }
  .btnSouthAfrica:hover{
    text-decoration: underline;
    background-color: var(--color-primary) !important;
    color: var(--color-secondary) !important;
  }
  .btnDark{
    background-color: var(--color-primary);
    color:var(--color-secondary);
  }
  .btnDark:hover{
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }

  a[href="https://www.gethatch.com/en/widget-landing/"]{
    display:none !important;
  }
}
/* SouthAfrica */
