.languageSelector  {
    position: relative;
    margin-right: 10px;
    display: none;
    @media (min-width: $tablet-P) {
        display: block;
    }

    @media (min-width: $desktop) {
		min-width: fit-content;
        width: fit-content!important;
        margin-left: 40px;
//         margin-top: 7px;
    }
    .select2-container--default {
        .select2-results__option{
            background-color: $color-primary!important;
            span {
                color: $t-color-primary;
            }
            &.select2-results__option--highlighted{
                background-color: $color-primary!important;
                span {
                    color: $t-color-primary;
                }
            }
            &.select2-container--default .select2-results__option--highlighted[aria-selected]{
                background-color: $color-primary!important;
                span {
                    color: $t-color-primary;
                }
            }
        }
        .select2-dropdown {
            border-width: 0;
            @media (min-width: $tablet-P) {
                border-width: inherit;
            }
            @media (min-width: $desktop) {
                border-width: 0;
            }
        }
        .select2-selection__rendered{
            min-width: max-content;
        }
        .select2-results>.select2-results__options{
            width: fit-content;
            display: block!important;
        }
    }

    .languageSelectorOption {
        font-size: .8rem;
        text-transform: none;
        width: max-content;
        margin: 0 auto;

        @media (min-width: $desktop) {
            margin: unset;
            padding-top: 2px;
        }

        @media (min-width: $desktop) and (max-width:1535.9px) {
            padding-top: 7px;
        }

        .testo {
            display: none;
            @media (min-width: $desktop) {
                display: inline;
                font-weight: 200;
            }
        }

        img {
            @media (min-width: $desktop) {
                margin-right: 10px;
            }
        }
    }

    .select2.select2-container .select2-selection.select2-selection--single {
        border-bottom: 0 !important;
    }
    .select2-container--default .select2-dropdown{
        border-bottom: 0;
        background-color: $bg-color-primary;
    }

    .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered{
        padding-left: 12px;
        padding-right: 12px;

        @media (min-width: $desktop) {
            padding-left: 8px;
            padding-right: 20px;
        }
    }
    .select2.select2-container .select2-selection.select2-selection--single .select2-selection__arrow{
        display: none;

        @media (min-width: $desktop) {
            display: inline-block;
        }
    }
}