.newslist-preview{
	.section-header {
		display: flex;
		flex-direction: column;
		padding-left: 37px;
		padding-right: 37px;
		@media (min-width: $tablet-P) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding-left: 0px;
			padding-right: 0px;
		}
		
		.title {
			width: 382px;
			height: 75px;
			font-family: $font-secondary;
			font-size: 18px;
			font-weight: 400;
			line-height: 58px;
			@media (min-width: $tablet-P) {
				font-size: 32px;
			}
		}
		.link{}
	}
	.item{
		width: 300px;
		margin: 0 $space--xs;
		@media (min-width: $desktop) {
			margin:0;
		}
	}
	.image{
		img{
		}
	}
	.title{}
	.category{}
	.stories-grid__item--double{
		width: 300px;
		@media (min-width: $desktop) {
			width: 500px!important;
		}
	}
}


.cmp_newsList{
	&.tpl_2Columns{
		&.customLayout--sustainability{
			.item {
				background-color: var(--bg-color-sustainability);
				display: flex;
				align-items: center;
				margin-left:15px;
				margin-right:15px;

				.cardItem{
					margin:0 auto;
					min-width: 80%;
					max-width: 460px;
					padding: 100px 50px;
					height: 100%;
				}
			}
			.title,
			.action-link{
				color:var(--t-color-sustainability)
			}
			.title{
				letter-spacing: 1.3px;
				text-transform: uppercase;
				@media (min-width: $desktop) {
					font-size: 32px;
				}
			}
			.text{
				margin-bottom: 1rem;
				&:empty{
					margin-bottom: 0;
				}
			}
			.text,
			.text a:not(.action-link){
				color:var(--color-primary);
			}
			.slick-arrow{
				&:before{
					color: var(--color-sustainability-dark);
					opacity: 1;
					@media (min-width: $desktop) {
						font-size: 50px
					}
				}
			}
			.slick-prev{
				@media (min-width: $desktop) {
					margin-left:-10px;
				}

			}
			.slick-next{
				@media (min-width: $desktop) {
					margin-right:10px;
				}

			}
		}
	}
}
