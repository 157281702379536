.imageComponent{
    max-width: $max-screen-size;
    margin: 0 auto;
    .imageComponent__container{
    }
    .imageComponent__image{
        justify-content: space-between;
        width: 100%;
    }
}

.white-section {
    .imageComponent {
        &.imageComponent--no-padding {
            padding-bottom: 0px;
        }
    }
}