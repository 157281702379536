@mixin width-height-mix($w: auto, $h: auto) {
  height: $h;
  width: $w;
}

.header {
  &__row {
    position: fixed;
    top: var(--top-position-menu);
    left:0;
    right: 0;
    z-index: 999;
    background-color: $black;
    a{
      color: $t-color-primary;
    }
    ul {
      list-style: none;
    }
  }
  
  &__navbar {
    @include width-height-mix($w: 100%, $h: 100%);
    background-color: $black;
    color: $white;
    padding: 0 2vw;
    position:static;
    .header__button--navbar {
      padding:.5rem;
    }
    
    .nav-item {
      font-size: 8px;
      font-weight: 400;
      text-transform: uppercase;
      @media (min-width: $desktop) {
        font-size: 12px;
      }
      
      .nav-link {
        padding: 0;
        @media (min-width: $mobile) {
          padding: 0 .5vw 0 .5vw;
        }
      }
    }
  }
  
  &__collapse {
    @include width-height-mix();
    max-height: 0;
    transition: max-height 0.5s;
    overflow: hidden;
    @media (min-width: $tablet-L) {
      @include width-height-mix($h: 100%);
    }
  }
  
  &__dropdown {
    @include width-height-mix($w: 100%);
    display: none;
    flex-direction: column;
    @media (min-width: $tablet-L) {
      @include width-height-mix($w: 100%, $h:100%);
      display: flex;
      flex-direction: row;
    }
    > ul {
      @include width-height-mix($h:100%);
      flex:1 1 auto;
      justify-content: flex-end;
    }
  }
  
  &__mouseOver {
    padding:.5rem 0;
    display:flex;
    
    &.active .header__secondaryMenu {
      top: 100%;
      left: 0;
    }
    
    @media (min-width: $tablet-L) {
      &.open{
        .nav-first{
          border: {
            bottom: 1px solid $white;
          };
        }
        .header__secondaryMenu {
          top: 100%;
          left: 0;
          padding: {
            top: 50px;
            bottom: 70px;
          };
        }
      }
    }
    
    .header__secondaryMenu {
      background-color: $color-primary--menu;
      @include width-height-mix($w: 100%, $h: auto);
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 100%;
      transition: left 0.3s;
      transition-timing-function: ease-in-out;
      z-index: 2;
      @media (min-width: $tablet-L) {
        padding-bottom: .3vw;
        border: none;
        top: -100vh;
        left: 0;
        transition: top 0.5s;
        transition-timing-function: ease-in-out;
        z-index: -1;
      }
      > div {
        @include width-height-mix($h: 100%);
        margin: 0 auto;
        
        >div {
          display:flex;
          
          .header__button--slideOut {
            margin-top: .5rem;
            @media (min-width: $tablet-L) {
              display: none;
            }
          }
          
          .header__button--close {
            margin-top: .5rem;
            margin-left: auto;
            @media (min-width: $tablet-L) {
              display: none;
            }
          }
        }
        
        ul {
          li {
            .nav-link {
              font-size: 12px;
              padding: 5vw;
              border-bottom: 1px solid;
              @media (min-width: $tablet-L) {
                font-size: 10px;
                padding: 0;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    
    > div {
      align-self: center;
      display: flex;
    }
    
    .header__button--slideIn {
      margin-left: auto;
      @media (min-width: $tablet-L) {
        display: none;
      }
      > * {
        pointer-events: none;
      }
    }
  }
}

.secondNav {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  &__products {
    
  }
  &__inspirations{
    .child{
      &__description{
        max-width: 200px;
        p{
          font-family: $font-primary;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.28px;
          line-height: 30px;
        }
        
        
      }
    }
  }
  
  &__aesthetics{
    justify-content: space-around;
    .thirdLevelNavbar{
      ul{
        list-style: none;
        padding-left: 0;
        column-count: 4;
        column-gap: 50px;
      }
      
    }
  }
}
.thirdLevelNavbar{
  ul{
    list-style: none;
    padding-left: 0 ;
    column-count: 2;
    column-gap: 50px;
    li{
    
    }
  }
  
}

.header-nav{
  height: 60px;
  background-color: $color-primary--menu;
  justify-content: space-between;
  align-items: center;
  @media (min-width: $tablet-P) {
    padding: 0;
  }
  @media (min-width: $tablet-L) {
    padding: 0;
  }
  @media (min-width: $desktop) {
    height: 90px;
    padding: 0 37px;
  }
  
  &__store{
    display: none;
    @media (min-width: $tablet-L) {
      display: inherit;
      align-items: center;
    }
    
    .store{
      display: flex;
      padding-left: 0;
      margin-top: 20px;
      min-width: fit-content;
      &__link{
        display: block;
        font-family: $font-primary;
        font-size: 11px;
        letter-spacing: 0.99px;
        font-weight: 400;
        text-transform: uppercase;
        margin-right: 20px;
        line-height: 18px;

        img {
          margin-left: 5px;
        }
      }

      &__hasSubmenu {
        position: relative;

        img {
          width: 20px;
        }
      }

      &__submenu {
        position: absolute;
        z-index: 3;
        padding: 0;
        background-color: $color-primary--menu; 
        height: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s; 

        &.active{
          height: auto;
          max-height: 1000px;
          padding: 0 5px 5px 5px;
        }

        li {
          display: block;
          font-family: $font-primary;
          font-size: 11px;
          letter-spacing: 0.99px;
          font-weight: 400;
          text-transform: uppercase;
          margin-right: 20px;
          line-height: 22px;
          white-space: nowrap;
        }
      }
    }
    
  }
  &__brand{
    display: flex;
    align-items: center;
    
    @media (min-width: $tablet-P) {
      display: block;
      margin: 0 auto;
    }
    
    .brand__link {
      display: block;
      height: auto;
      @media (min-width: $desktop) {
        margin: 0 auto;
      }
    }
    .brand__img{
      display: block;
      position: relative;
      z-index: 1;

      width: 100%;
      height: auto;

      @media (min-width: $mobile) {
        height: 100%;
        max-height: 50px;
        width: inherit;

      }
      @media (min-width: $desktop){
        margin: 0 auto;
      }

      @media (min-width: $desktop-XL) {
        max-height: 100px;
      }
    }
  }
  
  &__actions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
 
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      position: relative;
    }

  }
}



.main-nav{
  display: none;
  background-color: $color-primary--menu;
  height: 40px;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  @media (min-width: $tablet-L) {
    display:flex;
    align-items: flex-start;
    height: 100%;
  }
  .nav-item{
    &:first-of-type{
      padding-left: 100px;
    }
    &:last-of-type{
      padding-right: 100px;
    }
    @media (min-width: $tablet-L) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (min-width: $desktop) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media (min-width: $desktop) and (max-width: $desktop-XL) {
      padding-left: 30px;
      padding-right: 30px;
    }
    .nav-link{
      color: $t-color-primary;
    }
    
  }
  .nav-first{
    font-family: $font-primary;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 1.82px;
    text-transform: uppercase;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
  .nav-second{
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.68px;
    line-height: 36px;
    text-transform: uppercase;
    padding-bottom: 20px;
    &.item-ispiration{
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.48px;
      line-height: 16px;
      .child__image{
        padding-bottom: 20px;
        img{
          width:170px;
        }
      }
    }
  }
  .nav-third{
    font-family: $font-primary;
    font-size: 14px;
    letter-spacing: 0.48px;
    line-height: 1.5;
    padding: 5px 0;
    max-width: 200px;
    a{
      border-bottom: none;
      color:$breadcrumbs;
      display: inline-block;
      height: 30px;
    }
    
    &:hover{
      a{
        color: $t-color-primary;
      }
    }
    
  }
}

.nav-third__image{
  display: block;
  max-width:80%;
  padding-top: 20px;
  @media (min-width: $tablet-P) {
    max-width:90%;
  }
  @media (min-width: $desktop) {
  }
}
.menu-list > li,
.menu-list > .d-block > li {
  @media (max-width: $desktop){
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

#navbarSmegContent {
  .secondNav__products {
    justify-content: space-around;
    max-width:$max-screen-size;
    width: 95%;

    .nav-item {
      padding: 0 10px;
      ul{
        column-gap: 25px;
      }
    }
  }
}