@mixin elements($c-secondary, $c-tertiary){
    span[role="textbox"],
    span[role="textbox"].select2-selection__rendered,
    input[type="text"],
    .form-group textarea,
    //a:not(.action-link) {
    //    color: $c-secondary;
    //}
    a:not(.action-link) {
        font-weight: bold;
        &:hover {
            color: $c-tertiary;
            text-decoration: underline;
        }
        &.link__disabled--desktop{
            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}

.bg-default{
    background-color: $bg-color-primary;
    color: $t-color-primary;
    @include elements($color-secondary,$color-tertiary);
}

.bg-alternative{
    background-color:$bg-color-secondary;
    color: $t-color-secondary;
    @include elements($t-color-primary,$color-tertiary);
}

.bg-dark {
    background-color: $black;
    color: $white;
    @include elements($white,$color-tertiary);
}

.bg-darker {
    background-color: $bg-color-dark;
    color: $t-color-alternative;
    @include elements($bg-color-dark,$color-tertiary);
}

.bg-light {
    background-color: $white;
    color: $black;
    @include elements($black,$color-tertiary);
}

.bg-whiteSmoke {
    background-color: var(--color-whiteSmoke);
}

@mixin subClass($bgInt-color){
    .visualBoxComponent,
    .visualBoxSymmetric,
    .bannersSlider__sliderBlock,
    .row{
        background-color: $bgInt-color;
    }
}


.bgInt-default{
    @include subClass($color-primary);
}
.bgInt-alternative{
    @include subClass($bg-color-secondary);
}

page-promotion,
page-sigle-news{
    .heroBannerBlocks:first-of-type{
        background-color: $color-dark;
    }
}

