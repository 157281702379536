.sitemap__component{
    hr{
        border-top: 1px solid $el-color-primary;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .sitemap__firstLevelMenu{
        font-size:2rem;
        text-transform: uppercase;
        line-height: 80px;
    }

    .sitemap__category--aesthetics,
    .sitemap__category--inspirations,
    .sitemap__category--products{
        .sitemap__secondLevelMenu{
            text-transform: uppercase;
            line-height: 70px;
            font-weight: bold;
        }
    }

    .sitemap__category--default,
    .sitemap__category--products,
    .sitemap__category--aesthetics,
    .sitemap__category--inspirations,
    .sitemap__category--products{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 10px;
        max-width: 60%;
        margin-bottom: 50px;
        @media (min-width: $desktop) {
            display: grid;
            margin-bottom: 20px;
        }
        .sitemap__secondLevelMenu{
            font-size: 1rem;
        }
    }
    .sitemap__category--products{
        grid-template-columns: 1fr 1fr 1fr;
        max-width: none;
    }
    .sitemap__category--aesthetics{
        display: block;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 10px;
        margin-top: 0px;
        max-width: none;

    }
    .sitemap__category--inspirations{
        .sitemap__secondLevelMenu {
            font-size: 1rem;
            line-height: 34px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: .32px;
        }
    }
    .sitemap__thirdlevel{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 10px;
        margin-top: 10px;
        max-width: 60%;
        @media (min-width: $desktop) {
            display: grid;
        }
    }
    .sitemap__products__thirdlevel{
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        margin-top: 10px;
        max-width: none;
        @media (min-width: $desktop) {
            display: grid;
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .sitemap__products__secondlevel{
            display: inline-block;
            width: 33%;
        }
        .sitemap__thirdlevel,
        .sitemap__category--inspirations{
            -webkit-column-count:4;
            -moz-column-count:4;
            column-count:4;

        }
        .sitemap__products__thirdlevel{
            -webkit-column-count:2;
            -moz-column-count:2;
            column-count:2;
        }
        .sitemap__inspirations__secondlevel{
            a{
                display: inline-flex;
            }
        }



    }
}