

@mixin recipeTag {
    background: #968870;
    color: $white;
    display: inline-block;
    font-weight: 400;
}


@import "cooking-details";

//RECIPES LIST + stories-grid.scss
.cooking-list {

    .recommend-title {

        margin: 25px 0;

        @media (min-width: $desktop) {
            padding-top:130px;
        }
    }

    .stories-grid {
        margin-top: 0;
        padding-top: 50px;
    }

    .storyItem__textBlock {
        background: #f2f2f2;
        text-align: left;
        padding: .5rem 2rem 1rem 2rem;

        .storyItem__title{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        ul {
            margin: 0;
            padding: 0;
            height: 72px;
            overflow: hidden;
            text-align: left;
            list-style: none;
            line-height: normal;

            display: flex;
            align-content: flex-end;
            flex-direction: row;
            flex-wrap: wrap;

            > :last-child {
                margin-top: auto;
            }
        }

        li {
            @include recipeTag;
            font-size: 0.8rem;
            margin: 2px 2px;
            white-space: nowrap;
            font-weight: 400;
            padding: 2px 8px;
        }
    }

    .storyItem__title {
        position: relative;
    }
}

.recipe-faceted-navigation {

    .default-section { //fix spazio bianco quando nessun risultato
        overflow: hidden;
    }

    .navigation-title {

        margin: 25px 0;

        @media (min-width: $desktop) {
            padding-top:130px;
        }
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered{
        color: #020202;
    }
}

.faceted-navigation__filters {
    padding-bottom: 25px;

    //> div {
    //    padding: 0 15px;
    //    width: 100%;
    //
    //    @media (min-width: $tablet-P) {
    //        width: 50%;
    //    }
    //
    //    @media (min-width: $desktop) {
    //        width: calc(100%/3);
    //    }
    //}



}

.faceted-results {
    .selectedtags__link,
    .searchtags__link {
        @include recipeTag;
        line-height: normal;
        margin-right: 20px;
        padding: 5px 10px;

        &:hover{
            color: white;
            text-decoration: none;
        }
    }
}

.cooking-select-dropdown{
    padding-right: 1rem;
    padding-bottom: 1rem;
    .select2-container--default {
        .select2-selection--single {
            border-radius: 0;
            border: 1.5px solid black;
            padding-top: 6px;
            height: 45px;
            .select2-selection__arrow {
                height: 45px;
                background-image: url("../images/select-icon.png");
                background-repeat: no-repeat;
                background-size: 14px auto;
                background-position: 50%;
                b{
                    display: none;
                }
            }
        }
    }
}

.centered-filter{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.advanced-filter-input{
    border: 0;
    padding: 5px 15px;
    background-image: url("../../../../images/search-icon--black.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right;
}

.btn-advanced-filter{
    background: black;
    color: white;
    padding: 10px 25px;
    border-radius: 0;
    &:hover{
        color: white;
    }
}

.quick-info-container{
    display: flex;
    height: 35px;
    padding-bottom: .5rem;

    .quick-info{
        text-transform: uppercase;
        font-weight: 400;
        font-size: 10px;
        padding-top: 1px;
    }
}