//Sustainability layout

/* change background-color */
.cmp_generalText.tpl_textDefault,
.cmp_generalText.tpl_asymmetricText,
.cmp_bannerSlider.tpl_sliderDefault,
.cmp_cards.tpl_cardsDefault,
.cmp_cards.tpl_minimal,
.cmp_cards.tpl_twoColumn,
.cmp_newsRelated.tpl_newsDefault,
.cmp_video.tpl_videoDefault,
.cmp_image.tpl_imageDefault{
    &.customLayout--sustainability {
        background-color: var(--bg-color-sustainability);
    }
}

.cmp_newsList.tpl_2Columns.customLayout--sustainability{
    background-color: $white!important;
}

.cmp_heroBanner.tpl_blocks.customLayout--sustainability .wrapper{
    background-color: var(--bg-color-sustainability);
    .textBlock{
        .category,
        .title,
        .action-link{
            color:var(--color-primary)
        }
        .text,
        .text a:not(.action-link){
            color:var(--color-primary);
        }
    }

}

.cmp_generalText.tpl_textDefault,
.cmp_generalText.tpl_asymmetricText,
.cmp_generalText.tpl_focus,
.cmp_bannerSlider.tpl_sliderDefault,
.cmp_cards.tpl_cardsDefault,
.cmp_cards.tpl_minimal,
.cmp_cards.tpl_twoColumn,
.cmp_newsRelated.tpl_newsDefault,
.cmp_visualBox.tpl_visualBoxSymmetric,
.cmp_video.tpl_videoDefault,
.cmp_image.tpl_imageDefault{
    &.customLayout--sustainability{
        .title,
        .action-link{
            color:var(--t-color-sustainability)
        }
        .text,
        .text a:not(.action-link){
            color:var(--color-primary);
        }
    }
}

/* Card component */
.cmp_cards.tpl_columnsCards{
    &.customLayout--sustainability{
        .item {
            background-color: var(--bg-color-sustainability);
            display: flex;
            align-items: center;
            .cardItem{
                margin:0 auto;
            }
        }
        .title,
        .action-link{
            color:var(--t-color-sustainability)
        }
        .text,
        .text a:not(.action-link){
            color:var(--color-primary);
        }
        .slick-arrow{
            &:before{
                color: var(--color-sustainability-dark);
                opacity: 1;
                @media (min-width: $desktop) {
                    font-size: 50px
                }
            }
        }
        .slick-prev{
            margin-left:-25px;
        }
        .slick-next{
            margin-right:-0px;
        }
    }
}
.cmp_cards.tpl_2columnsCards{
    &.customLayout--sustainability{
        .item {
            padding-top:150px;
            padding-bottom: 100px;
        }
    }
}
.cmp_cards.tpl_3columnsCards{
    &.customLayout--sustainability{
        .item {
            padding-top:100px;
            padding-bottom: 50px;
            .cardItem{
                width: 80%;
            }
        }
    }
}
.cmp_cards.tpl_icons{
    .cardItem{
        background-color: var(--bg-color-sustainability);
        .gallery,
        .textBlock {
            background-color: var(--bg-color-sustainability);
        }
    }
}

/* Text component */
.cmp_generalText.tpl_focus{
    &.customLayout--sustainability{
        .title,
        .action-link{
            color:var(--t-color-sustainability)
        }
        .text,
        .text a:not(.action-link){
            color:var(--color-primary);
        }
        .textBlock p:before{
            display: none;
        }
    }
}

/* VisualBox component */
.cmp_visualBox.tpl_visualBoxSymmetric{
    &.customLayout--sustainability{
        .container-fluid,
        .container .row {
            background-color: var(--bg-color-sustainability);
        }
    }
}

/* News */
.stories-grid__item{
    &.customLayout--sustainability{
        .storyItem__textBlock{
            background-color: var(--bg-color-sustainability);
            color:var(--color-primary);
            .storyItem__category:after{
                border-top-color:var(--color-primary);
            }
        }
    }
}

/* News category */
.navbarCategories__item {
    &.customLayout--sustainability,
    &.customLayout--sustainability.active{
        a{
            color: var(--bg-color-sustainability) !important;
        }
    }
}

.adyen-checkout__image--loaded {
    vertical-align: top;
}

.content-for-logged {
    display: none!important;
}

.content-for-guest {
    display: none!important;
}

.content-for-all {

}

//styles for connectif

//button
.cn_button_submit {
    button[type="submit"]{
        font-family: Raleway, sans-serif;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.92px;
        line-height: 24px;
        border: 1px solid #020202;
        text-align: center;
        height: 45px;
        padding: 10px !important;
        background: #020202;
        color: #fff !important;
        width: 100%;
    }
}

//modal
.cn_content{
    color: $t-color-secondary;
    line-height: normal;
    font-family: 'Mulish', sans-serif;
    p{
        line-height: normal;
    }

    label{
        margin-bottom: 0;
    }

    .cni_subtile_p{
        line-height: 16px;
        padding-bottom: 32px;
        @media (min-width: $desktop) and (max-width: $desktop-XL) {
            line-height: 10px;
        }
    }
}