.df-fullscreen,
.df-fullscreen.df-fullscreen__custom {
    height: 78vh;
    top: 100px;
    width: 100vw;
    left: auto;
    padding-bottom: 16px;
    box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.25);
}
.df-fullscreen .df-topbar__content--logo .df-topbar__logo,
.df-fullscreen .df-topbar__content .df-topbar__logo {
    display: none;
}
/* search input in header */
.header-nav__actions > div:first-of-type {
display:flex;
align-items: center;
justify-content: center;
}

input#searchbar_btn {
    background-image: url('#{$images}/icons/icon-search.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 26px;
    height: 100%;
    background-position: 50%;
    border: none;
    color: transparent;
    background-color: transparent;
    padding: 6px 0;
    margin: 0 12px;
    @media (max-width: $mobile) {
        margin: 0 8px;
      }
    @media (min-width: 1200px) and (max-width: 1367px){
        zoom: 0.7;
      }
}
input#searchbar_btn::-webkit-input-placeholder {
color: transparent !important;
}

input#searchbar_btn:-moz-placeholder { /* Firefox 18- */
color: transparent !important;
}

input#searchbar_btn::-moz-placeholder {  /* Firefox 19+ */
color: transparent !important;
}

input#searchbar_btn:-ms-input-placeholder {  
color: transparent !important;  
}
#searchbar_btn + button {
display: none;
}

#searchbar_btn:hover {
cursor: pointer;
}

.df-fullscreen .df-topbar__logo {
    display: flex;
    align-items: flex-end;
}

.df-fullscreen .df-topbar__content,
.df-fullscreen #df-topbar__dffullscreen .df-topbar__content{
    //margin-left: 280px;
    //width: calc(100% - 280px);
    height: auto;
    padding-top: 16px;
    width: 50%;
}
.df-fullscreen .df-topbar__logo img {
    max-height: 95%;
    max-width: 95%;
}

.df-fullscreen[data-facets=left] .df-header {
    justify-content: space-between;
    align-items: flex-end;
}

.df-fullscreen .df-searchbox {
    border-bottom: 2px solid #000;
}

.df-fullscreen input[type=search],
.df-fullscreen .df-searchbox input,
.df-fullscreen .df-searchbox #df-searchbox__dffullscreen {
    height: 32px;
    font-size: 24px;
    font-family: raleway, sans-serif;
    font-weight: 400;
}

.df-fullscreen[data-wide=true] .df-topbar,
.df-fullscreen[data-wide=true] .df-layer__content {
    padding: 0 16px;
}

.df-fullscreen .df-topbar {
    padding: 5px;
}

.df-fullscreen .df-topbar__content [data-role=close] {
    margin-left: 0;
}

.df-fullscreen .df-topbar__content [data-role=close] svg {
    fill: #000000;
    width: 30px;
    height: 30px;
}

.df-fullscreen .df-suggestions-list {
    white-space: nowrap;

    @media (min-width: $tablet-L) {
        margin-left: 280px;
    }
}

.df-fullscreen .df-suggestion-label {
    width: auto !important;
    margin-left: 30px;
    text-transform: none;
}

.df-fullscreen .df-suggestion {
    font-size: 0.8rem;
}

.df-results__header__content {
    width: 100%;
    padding-right: 16px;
}

.df-fullscreen[data-facets=left] .df-header  h2{
    padding: 0;
    font-size: 1.4rem;
    font-weight: 500;
    padding-left: 8px;
}

.df-header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: calc(100% - 260px);
    width: 100%;
    //padding: 0 15px;
    flex-wrap: wrap;
}

.df-bottom-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.df-fullscreen .df-icon-list {
    align-items: center;

    .df-icon {
        width: 35px;
        height: 35px;
        line-height: 35px;
    }

    .total-results {
        margin-right: 20px;
    }
}

.df-fullscreen #tabs,
.df-mobile #tabs{
    display: flex;
}

.df-mobile #tabs{
    width: 100%;
    top: 100px;
    background: none;
}

.df-fullscreen #tabs a,
.df-mobile #tabs a {
    padding: 3px 15px;
    margin: 0 3px;
    text-transform: uppercase;
    color: #161616;
}

.df-fullscreen #tabs a.selected,
.df-mobile #tabs a.selected {
    background: #f8f8f8;
    font-weight: 700;
}

.df-card.product {
    position: relative;
}

.df-card__content {
    color: #000;
}

.df-card__content>* {
    height: 100%;
}

.df-fullscreen .df-aside__content {
    bottom: 0 !important;
    
}

.df-fullscreen .df-panel__title, .df-fullscreen .df-selected-filters__title {
    font-weight: 700;
    letter-spacing: 2.24px;
    text-transform: uppercase;
    padding: 0 10px;
    background-size: 20px;
}

.df-slider .noUi-tooltip {
    padding: 0 4px;
    line-height: 2;
}

.df-term {
    align-items: center;
    padding: 4px 8px 4px 18px;
}

.df-term:hover {
    align-items: center;
}

.df-term:before {
    content: "";
    width: 8px;
    height: 8px;
    border: 1px solid #000;
    border-radius: 50%;
    margin-right: 5px;
}

.df-term:hover:before {
    background: #000;
}

.df-fullscreen .df-term[data-selected],
.df-fullscreen .df-term[data-selected]:hover,
.df-mobile .df-term[data-selected] {
    background: none !important;
    border: 0 none !important;
    color: #000 !important;
}

.df-fullscreen .df-term[data-selected]:before,
.df-mobile .df-term[data-selected]:before {
    background: #000;
}
.df-fullscreen .df-results {
    height: 100%;
    overflow-y: auto !important;
}
.df-fullscreen .df-aside__content::-webkit-scrollbar-track,
.df-fullscreen .df-results::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.df-fullscreen .df-aside__content::-webkit-scrollbar,
.df-fullscreen .df-results::-webkit-scrollbar{
    width: 3px;
}

.df-fullscreen .df-aside__content::-webkit-scrollbar-thumb,
.df-fullscreen .df-results::-webkit-scrollbar-thumb{
    background-color: #000000;
    border: 1px solid #555555;
}

[data-layout=grid] .df-card__content {
    text-align: left !important;
}

[data-layout=grid] .df-results,
[data-layout=grid][data-browser-support~=cssgrid] .df-results {
    grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
    padding-bottom: 50px !important;
}

.df-card__main {
    padding: 16px 16px 0px 16px !important;
    position: relative;
}

// .df-card__main:hover {
//     background-color: #f8f8f8;
// }

.df-card__image {
    padding: 15px 0;
}

.df-card__image.no-image {
    border: 1px solid #E6E6E6;
    padding: 10px;
}

.news .df-card__image,
.faq .df-card__image,
.category .df-card__image,
.pages .df-card__image,
.recipes .df-card__image{
    border: 1px solid #E6E6E6;
}

.df-card__title__price {
    display: flex;
    flex-direction: column;
}

.df-card__pricing {
    text-align: right;
    color: black;
}

.df-card__title {
    font-family: Raleway, sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
}

.product .no__product {
    display: none;
}

.pretitle__product,
.title__product {
    display: none;
}

.product .pretitle__product,
.product .title__product {
    display: block;
    font-family: 'Mulish', sans-serif;
}

// .product .title__product {
//     font-weight: 600;
//     margin-bottom: 6px;
// }

.news .df-card__title,
.faq .df-card__title,
.pages .df-card__title,
.category .df-card__title
.recipes .df-card__title {
    font-weight: 500;
    //line-height: 24px;
    //text-transform: uppercase;
    font-family: Raleway, sans-serif;
    //text-align: center;
    font-size: 0.9rem;
}


// .news .df-card__content:before,
// .faq .df-card__content:before,
// .pages .df-card__content:before {
//     content: "";
//     width: 50px;
//     height: 2px;
//     border-top: 2px solid #000;
//     margin: 0 auto 30px;
// }

.df-card-tag {
    position: absolute;
    background: #E6E6E6;
    padding: 2px 5px;
    left: 1px;
    top: 180px;
    font-size: 0.8rem;
    display: none;
    text-transform: uppercase;
}

.news .df-card-tag.tag-news,
.faq .df-card-tag.tag-faq,
.category .df-card-tag.tag-categories,
.pages .df-card-tag.tag-pages,
.support .df-card-tag.tag-support,
.recipes .df-card-tag.tag-recipes {
    display: block;
}

.df-card__description {
    display: block;
}

.product .df-card__description {
    display: none;
}

.df-card.faq {
    padding: 0;
}

.df-fullscreen .df-card__price--new,
.df-mobile .df-card__price--new {
    color: #000 !important; color: #b4b4b4;
    display: block;
}

.df-fullscreen .df-card__price--old {
    color: #b4b4b4 !important; 
    font-size: 0.8rem;
    display: block;
}

.df-mobile__suggestions {
    color: #000;
}

.df-mobile__wrapper #tabs {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 4px;
    -webkit-overflow-scrolling: touch;
    margin-top: 20px;
    justify-content: flex-start;
}

.df-mobile .df-mobile__action-button[data-role=close] {
    border-color: #000;
    background-color: #000;
}

.df-mobile .df-mobile__action-button svg {
    width: 28px;
    height: 28px;
}

.df-mobile .header-nav {
    margin-bottom: 10px;
    padding: 0 10px;
    height: 50px;
}

.df-fullscreen [data-role=scrolltop]{
    background-color: #000;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    right: 40px;
    bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        fill: #fff;
        height: 27px;
        width: 27px;
    }
}

.df-mobile {
    background: #fff !important;
}

.df-mobile__content {
    position: relative;
    background: #fff !important;
}

.dfwidget-host {
    background: none;
    .card-media{
        width: 60%;
        margin: 0 auto;
      }
      .card-price--sale {
        color: #000 !important;
      }
  }

.df-no-results {
.df-mobile & {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    padding: 0 15px;
    padding-top: 20px;
    //border-bottom: 1px solid #000;
    color: #000;

    .df-no-results__title {
    font-weight: 700; text-transform: uppercase; font-size: 1.2rem; margin: 0;
    }
}

.df-fullscreen & {
    position: absolute; left: 0; bottom: 63%; background: #fff; padding: 0; margin: 0 42px 0 42px; width: calc(100% - 84px); padding-top: 80px !important; 

    .df-no-results__title {
    font-weight: 700; text-transform: uppercase; font-size: 1.3rem; margin: 0;
    }
}
}

.tabs-no-results {
    //position: absolute;
    position: relative;
    z-index: 99;
    //top: 80px;
    //left: 35%;
}
.df-fullscreen__custom  {
    .df-slider {
        .noUi-horizontal {
            height: 5px;
        }
    
        .noUi-horizontal .noUi-handle {
            width: 20px;
            height: 20px;
            border-radius: 50%;

            &::before,
            &:after {
                display: none;
            }
        }

        .noUi-horizontal .noUi-tooltip {
            font-size: 78%;
            border: 0 none;
        }

        .noUi-horizontal .noUi-value-horizontal.noUi-value-large {
            top: 1px;
            font-size: 73%;
        }

        .noUi-marker-horizontal.noUi-marker-large,
        .noUi-marker {
            background: #000;
            color: #000;
        }
    }
}

[data-facet="price"].df-panel {
    display: none;
}

.df-mobile .df-mobile__content {
    min-height: 66vh;
}
.df-mobile .df-mobile__header__actions .df-mobile__button--switch-on {
    color:#000 !important;
}

.df-mobile .df-mobile__action-button[data-role=close] {
    border-color: #000 !important;
    background-color: #000 !important;
    bottom: 12px;
    left: 12px !important;
    height: 46px;
    width: 46px;

    &.df-out {
        opacity: 1 !important;
        transform: translate(0,0) !important;
    }
}

.df-mobile .df-mobile__action-button[data-role=scrolltop] {
    bottom: 65px;
    right: 16px;
    height: 46px;
    width: 46px;
}

.my-df-addtocart-btn.js_addToCart.btn_addToCart.button-primary {
    // min-width: auto;
    // height: auto;
    // padding: 3px;
    // width: 95%;
    // position: relative;
    // z-index: 7;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // margin: 0 auto;
    // margin-top: -25px;
    // font-size: .85rem;
//     width: 30px;
//     height: 30px;
//     display: flex;
//     justify-content: center;
//     min-width: auto;
//     text-decoration: none;
    padding: 0;
    background: none;
    border: none;
//     position: absolute;
//     top: auto;
//     bottom: 20px;
//     left: 15px;
//     z-index: 22;

    i:before {
        -webkit-text-fill-color: #fff;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #000;
    }

    &:hover {
        text-decoration: none; 

        i:before {
            -webkit-text-fill-color: #000;
            -webkit-text-stroke-width: 0;
            -webkit-text-stroke-color: transparent;
        }
    }
}

.addCart-price {
    display: flex;
    justify-content: space-between;
}

[data-layout=list] {
    .df-card {
        flex-wrap: nowrap !important;
    }
    
    .df-card__description {
        p {
            display: inline;
            font-size: 13px;
            line-height: 1.2rem;
        }
    }

    .my-df-addtocart-btn.js_addToCart.btn_addToCart.btnMinSize.button-primary {
        width: 30px;
        display: flex;
        justify-content: center;
        min-width: auto;
        text-decoration: none;
        background: 0 0;
        border: none;
        left: 15px;
        z-index: 22;
        position:  static !important;
        left: auto;
        align-items: center !important;
        height: auto !important;
    }
}

[data-layout=list] .df-card__main{
    max-width: 900px;
    @media (min-width: 1200px) and (max-width: 1600px){
        max-width: 600px;
    }
}

[data-layout=list] .df-card {
    flex-wrap: nowrap !important;
}

[data-layout=list]{
    .df-card__links{
        max-width: 600px;
        @media (min-width: 1200px) and (max-width: 1600px){
            max-width: 350px;
        }
    }
}

.df-card__links{
    min-height: 90px;
    padding: 0px 16px 0px 16px;
    line-height: 10px;

    .action-link--black{
        font-size: 12px;
    }

//     &:hover{
//         background-color: #f8f8f8;
//     }
}

.icon-30{
    width: 30px;
    height: 30px;
}

[data-layout=grid] .energy-label_container{
    min-height: 30px;
}

[data-layout=grid] .product_information_sheet_container{
    min-height: 18px;
}