.pdf-listing{
    .pdf-header{
        margin-bottom: $text--pb;
        .title{
            font-family: $font-secondary;
            letter-spacing: 0.28px;
            margin-bottom: 15px;
            padding-bottom: 0px;
            font-size: 26px;
            padding-top: 50px;
            line-height: 42px;
            font-weight: 200;

            @media (min-width: $tablet-L) {
                letter-spacing: .7px;
                line-height: 70px;
                margin-bottom: 25px;
                font-weight: 200;
                margin-top: 100px;
                padding-top: 20px;

            }

            @media (min-width: $desktop) {
                font-size: 40px
            }

            @media(min-width: $desktop-XL) {
                line-height: 42px;
                margin-top: 0px;
            }
        }
        .subtitle{
            font-family: $font-secondary;
            font-size: 1.4rem;
            margin-bottom: 30px;
        }
        .content{
            font-family: $font-primary;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.12px;
            line-height: 18px;

            @media (min-width: $tablet-L) {
                font-size: 18px;
                letter-spacing: 0.18px;
                line-height: 30px;
            }
        }


    }
    .functions-panel{
        margin-bottom: $text--pt;
        flex-direction: column;
        @media (min-width: $desktop) {
            justify-content: space-between;
            flex-direction: row;
        }


    }
    .pagination-panel{
        margin-bottom: $text--pt;

    }
    .pdfItem{
        margin-bottom: $text--pt;
        .pdf-download{
            text-align: inherit;
            @media (min-width: $desktop) {
                text-align: right;
            }
        }
    }
    .search-form{
        .search-form__input{
            width: 150px;
            padding-left: 10px;
            padding-right: 10px;
            background-color: #020202;
            border: none;
            border-bottom: 1px solid #fff;
            caret-color: #fff;
            color: #fff;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                padding-bottom: 10px;
            }
        }
        .search-form__submit{
            cursor: pointer;
            display: inline-block;
            .search-form__submit--input{
                background: none;
                border: none;
            }
            .search-form__submit--label{
                &:before {
                    content: "";
                    display: inline-block;
                    background-image: url(../images/search-icon.svg);
                    background-size: 20px 20px;
                    height: 20px;
                    width: 20px;
                    background-repeat: no-repeat;
                }

            }
        }
    }
    .sign-out-col{
        text-align: inherit;
        @media (min-width: $desktop) {
            text-align: right;
        }
        .sign-out{
            min-width: fit-content;
            display: contents;
            background: none;
            border: none;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                width: 150px;
            }
        }
    }

}