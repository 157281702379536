
$wallGap: 20px;
$wallBox_height: 150px;
 

.wall-grid {
	width: 100%;
	padding-top: 50px; 
	padding-bottom: 40px;
	
	display: grid;
	grid-gap: $wallGap;
	//grid-auto-rows: $wallBox_height*2;
	grid-auto-flow: row dense;

	@media (min-width: $mobile) {
		grid-template-columns: repeat(3, 1fr);
		//grid-auto-rows: $wallBox_height;
	}

	@media all and (-ms-high-contrast: none) {
		display: inline-flex;
		flex-wrap: wrap;

		margin: -#{$wallGap} 0 0 -#{$wallGap} ;
  		width: calc(100% +  #{$wallGap});

		.wallBox {
			width: calc( (100% / 3) - #{$wallGap});
			margin: $wallGap  0 0 $wallGap ;
		}
	}
}

.wallBox {
	width: 100%;
	overflow: hidden; 
	position: relative;

	@media (min-width: $mobile) {
		&:nth-child(6n),
		&:nth-child(6n+1){ 
			grid-row: span 2;
			grid-column: auto / span 2;	
		}

		&:nth-child(6n-1) {  
			grid-column: 1 / 2;
		}
	}

	img {
		width: 100%; 
		object-fit: cover;
		transform: scale(1);
		transition: .2s ease-in;
	}

	&:hover img {
		transform: scale(1.1);
		transition: .2s;
	}

	&:hover a {
		color: inherit;
	}

}

@mixin wallBox__textBlock (){
	background: rgba(0, 0, 0, 0.7); 
	padding: 4px 8px;
	text-transform: uppercase;
}

.wallBox__textBlock {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	.wallBox__category { 
		@include wallBox__textBlock;

		display: block;
		font-size: 10px; 
		font-family: 'Raleway', sans-serif;
		font-weight: 700;
		letter-spacing: 1px;

		@media (min-width: $mobile) {
			font-size: 12px;
		}
		
		// solo box grandi
		// .wallBox:nth-child(6n) &,
		// .wallBox:nth-child(6n+1) &{ 
		// 	 display: block;
		// }
	} 

	.wallBox__title {
		@include wallBox__textBlock;
		font-size: 16px;
		margin-bottom: 0;
		
		@media (min-width: $mobile) {
			font-size: 20px;
			line-height: unset; 
		}
	}
}
