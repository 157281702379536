.countrySelector{
	//Grid start
	.body {
		@media (min-width: $tablet-P) {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			grid-template-areas: "left" "right";
			grid-area: body;
		}
		@media (min-width: $desktop) {
			display: grid;
			grid-template-columns: 2fr 1fr;
			grid-template-rows: 1fr;
			grid-template-areas: "left right";
			grid-area: body;
		}
	}
	
	.left { grid-area: left; }
	
	.right {
		@media (min-width: $tablet-P) {
			display: grid;
			grid-area: right;
			grid-template-columns: 1fr 1fr;
			grid-template-areas: "top-left top-right" "bottom-left bottom-right";
		}
	}
	
	.top-left { grid-area: top-left; }
	
	.top-right { grid-area: top-right; }
	
	.bottom-left {
		@media (min-width: $desktop) {
			grid-area: bottom-left;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}
	.bottom-right {
		@media (min-width: $desktop) {
			grid-area: bottom-right;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}
	//Grid end
	
	.country__title{
		font-family: $font-secondary;
		font-size: 32px;
		font-weight: 400;
		letter-spacing: 1.28px;
		line-height: 38px;
		@media (max-width: $tablet-P){
			padding-top: 20px;
		}
		&:after{
			content: '';
			display: block;
			border-bottom: 1px solid $product-grey;
			width: 90%;
			margin-top: 10px;
			margin-bottom: 20px;
		}
	}
	.country__dealer{
		text-transform: uppercase;
		color: $product-grey;
		font-size: 12px;
		margin-left: 15px;
	}
	.countrySelector__europe{
		column-count: 1;
		column-fill: balance;
		@media (min-width: $tablet-P) {
			column-count: 3;
			column-fill: balance;
		}
	}
	.countrySelector__country{
		display: block;
		padding:10px 0;
		font-family: $font-primary;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.32px;
		line-height: 20px;
	}
}