
.recipes-details {
    .column-reverse {
        position: relative;
        padding-top: 35px;
        padding-bottom: 35px;

        @media (min-width: $desktop) {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        @media (min-width: $mobile) {
            flex-direction: row-reverse;
        }

        
    }
}

.recipe_ingredientsList {

    .title {
        font-weight: 500;
        line-height: 26px;
        text-transform: uppercase;
        margin-bottom: 25px;
        
        @media (min-width: $desktop) {
            font-weight: 400;
            line-height: 40px;
        }
    }

    .ingredients_section {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 4px;
    }

    ul {
        //padding: 0 25px !important;

        // column-count: 2;
        // column-gap: 30px;
        // column-fill: balance;
         
        // @media (min-width: $mobile) {
        //     // (ingredients >= 7)
        //     &[data-count~='7'] {
        //         columns: 2;
        //     }
        // }
        padding: 0 ;

        @media (min-width: $tablet-L) {
            display: flex; 
            flex-wrap: wrap;

            .title-section { 
                width: 100%
            }
         
            li {
                width: 50%
            }
        }
        
        a {
            font-weight: unset;
        }
          
        .title-section {
            @include newtitle(xs);
            text-transform: uppercase;
            font-weight: 700;
            margin: 20px 0 10px;
        } 

        li {
            list-style: none;
        }
    } 

    a:hover {
        color: inherit;
    }
}

.recipe_summary {
 
    margin-left: -37px;
    margin-right: -37px;
    width: calc(100vw + 37px);

    @media (min-width: $mobile) {
        margin-left: 0;
        margin-right: 0;
        width: inherit; 
    }

    .inner {
        padding: 37px;
        background-color: $dusty-grey; 
        color: $lighter-grey;   
    }

    .recipe_summary-box {
        margin-bottom: 10px;

        a {
            color: inherit;
         }

         ul {
            padding: 0;//reset
            margin-bottom: 0; 
         }
    }

    .summary_title {
        font-family: $font-secondary;
        font-weight: 700;
        text-transform: uppercase;
        display: inline-block;
        margin-right: 5px;
        @include newtitle(xs);
    }

    .content { //+ tutti gli UL
        display: inline;
        padding-left: 5px;
    }

    ul.tagList {
        li {
            @include recipeTag;
            line-height: normal;
            margin-right: 3px;

            a {
                text-decoration: none;
                font-weight: 300;
            }
        }
    }
    
    .recipe_appliancesUsed {
        ul {
            list-style: none;  
        }
    }

    .recipe_chefAdvice {
        .content, 
        .content p {
            line-height: 1.5;
        }

        ul {
            padding-left: 20px;
            margin-bottom: 1rem;
        }
    }
}

.shareButtons {
    $shareButtons_size: 40px;
    $shareButtons_sizeSmall: 30px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    .social-icon,
    .print {
        display: inline-block;
        width: $shareButtons_sizeSmall;
        height: $shareButtons_sizeSmall;
        line-height:  $shareButtons_sizeSmall - 2px  ;
        border-radius: $shareButtons_sizeSmall/2;
        
        border: 1px solid transparent; 
        margin: 4px;

        @media (min-width: $tablet-P) {
            width: $shareButtons_size;
            height: $shareButtons_size;
            line-height:  $shareButtons_size  - 2px  ;
            border-radius: $shareButtons_size/2; 
        }
    }

    .print {
        background: url('#{$images}/icons/icon-print.png') center no-repeat;
        background-size: cover;
 
        .text {
            @include visuallyHidden;
        }
    }
 
}

.relatedRecipes {
    .storyItem__category {
        display: none;
    }

    .storyItem__borderBlock {
        > div {
            background-color: $white;
            a {
                color: $black;
            }
        }
        .storyItem__textBlock{
            color: $black;
        }
    }
}

