.videoComponent {
	width: 100%;
	.video {
		width: 100%;
		line-height: 0;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			iframe {
				width: 100%;
				min-height: 700px;
			}
		}
		.link-video-cookie{
			display: block;
			position: relative;
			color: $white;
			img {
				aspect-ratio: 16/9;
			}
			.cookie__text{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				line-height: initial;
				a{
					color:$white;
					&:hover{
						color:$white;
					}
				}
			}
		}
		
		video {
			width: 100%;
			height: auto;
			overflow: hidden;
		}

        .rutube-video{
            aspect-ratio: 16 / 9;
            width: 100%;
            height: 100%;
        }

	}
}

.link-video-cookie{
	display: block;
	position: relative;
	color: $white;
	a{
		color:$white;
		&:hover{
			color:$white;
		}
	}
	img {
		aspect-ratio: 16/9;
	}
	.cookie__text{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		line-height: initial;
		a{
			color:$white;
			&:hover{
				color:$white;
			}
		}
	}
}

.video.js-player {
	position: relative;
	.plyr__poster::after {
		content:'';
		background: url('#{$images}/icons/player.png') center no-repeat;
		height: 50px;
		width: 50px;
		position: absolute;
		top: calc(50% - 20px);
		left: calc(50% - 20px);
		opacity: .8;
		background-size: cover;

		&:hover {
			opacity: 1;
		}
	}
	.plyr--paused {
		.plyr__poster {
			opacity: 1;
			background-color: transparent; 
		}
	}
	
}