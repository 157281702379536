.project-references{
    padding: $space--sm 0;
    .city-header{
        @include newtitle;
        font-weight: 400;
        border-bottom: 1px solid $color-secondary;
        margin: $space--md 0;
        padding: $space--sm 0;

    }
    .project-reference{
        margin: $space--sm 0;
        padding: $space--sm 0;
        .image {
        }
        .textBlock {
            padding: $space--md 0;
            @media (min-width: $tablet-P) {
                padding: 0 $space--md;
            }
            .title {
                @include newtitle;
                margin-bottom: $space--md;
            }
            .subtitle {
                @include subTitle;
            }
            .description {
                @include text;
            }
            .link {
            }
        }
    }
}