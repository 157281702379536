.related-products-component {
  .section-title{
    font-family: $font-secondary;
    font-size: 30px;
    font-weight: 300;
    line-height: 58px;
    margin-top: 50px;
  }
  .slider-cards-component{
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: $max-screen-size;
    margin: 0 auto;
    @media (min-width: $tablet-P) {
      padding-left: 70px;
      padding-right: 70px;
    }
    @media (min-width: $tablet-L) {
      padding-left: 120px;
      padding-right: 120px;
    }
    @media (min-width: $desktop) {
      padding-left: 100px;
      padding-right: 100px;
    }
    
  }
  .slider-cards-component__slide {

    //@media (min-width: $desktop) {
    //  display: flex;
    //  justify-content: space-between;
    //  max-width: 320px;
    //}
    .cards__card{
      @media (min-width: $desktop) {
        //width: 70%;
        //padding-top: 50px;
      }
    }
    .cards__card{
      max-width: unset;
      padding: 0 0 25px 0;
    }
    .cards__image {
      min-height: 300px;
      max-height: 500px;
      //border: 1px solid $product-light-grey;
      @media (min-width: $desktop) {
        padding: 25px;
        width: 100%;
        height: unset;
        margin: 0 auto;
      }

      @media (min-width: $tablet-P) {
          max-height: none;
      }

        //TODO: capire a cosa serve, tolto per task SSD-3719 SMEG-2582
      img {
//         width: inherit;
//         height: auto;
//         margin: auto;
      }

    }
    .cards__textBox{
      //margin-top: 20px;
      font-size: 12px;
      font-weight: 400;
      padding: 0;

      @media (min-width: $desktop) {
        padding: 0 37px;
      }

      .cards__title {
        text-align: center;
        font-size: 20px!important;
      }
      .cards__sub_title {
        text-align: center;
      }
      .cards__description {
        color: $light-black;
        font-family: $font-primary;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.72px;
        line-height: 20px;
        min-height: initial;
        @media (min-width: $tablet-P) {
          min-height: 80px;
        }
        @media (min-width: $desktop) {
          font-size: 10px;
        }
        @media(min-width: $desktop-XL){
          font-size: 12px;
        }
      }
    }
  }
  .slick-slider{
    .slick-prev{
      left: -15px;
      @media (min-width: $tablet-P) {
        left: 30px;
      }
      @media (min-width: $desktop) {
        left: 75px;
      }
    }
    .slick-next{
      right:-15px;
      @media (min-width: $tablet-P) {
        right: 30px;
      }
      @media (min-width: $desktop) {
        right:75px;
      }

    }
  }

  .hover-product{
    a{
      color: #161616;
      text-decoration: none;
      &:hover{
        color: #161616;
        text-decoration: none;
      }
    }
    &:hover {
      background: #f8f8f8;
    }
  }

  .width-no-slider{
    max-width: 315px;
  }

}