.search__stories__title{
	font-size: 80px;
	font-family: $font-secondary;
	font-weight: 300;
	padding-top: $text--pt;
	@media (min-width: $desktop) {
		padding-top: 50px;
	}
}

.search__stories{
	display: flex;
	flex-wrap: wrap;
	
}

.promoted__product__title{
	font-family: $font-secondary;
	font-weight: 300;
	padding-top: $text--pt;
	padding-bottom: $text--pt;
	color: black;
	text-transform: uppercase;
	@media (min-width: $desktop) {
		padding-top: 50px;
	}
}

.search__product__title{
	font-size: 32px;
	font-family: $font-secondary;
	font-weight: 300;
	padding-top: $text--pt;
	color: black;
	@media (min-width: $desktop) {
		font-size: 42px;
		padding-top: 50px;
	}
}



.search__product__result_number{
	font-size: 22px;
	font-family: $font-secondary;
	font-weight: 100;
	padding-top: $text--pt;
	color: black;
	margin-bottom: 75px;
	@media (min-width: $desktop) {
		padding-top: 50px;
	}
}