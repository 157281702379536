.relatedItems,
.relatedItem{
	.item {
		@media (min-width: $tablet-P) {
			display: flex;
			justify-content: space-around;
			padding: 20px;
		}
	}
	&__color{
		&--white{
			background-color: $white;
			@media (min-width: $tablet-L) {
			
			}
		}
		&--black{
			background-color: $black;
			@media (min-width: $tablet-L) {
			
			}
		}
	}
	&__block {
		
		@media (min-width: $tablet-L) {
		
		}
	}
	&__header{
		margin-bottom: 0px;
		&::after{
			content:"";
			display: block;
			white-space: pre;
			background-color: $white;
			width: 100%;
			height: 1px;
		}
		&--title{
			font-family: $font-secondary;
			font-size: 24px;
			font-weight: 300;
			@media (min-width: $tablet-L) {
				font-size: 30px;
				font-weight: 400;
				margin-bottom: 20px;
			}
		}
	}
	&__body{
		align-items: center;
	}
	&__textBlock{
	}
	&__title{
		font-family: $font-secondary;
		font-size: 18px;
		font-weight: 400;
		letter-spacing: 0.36px;
		line-height: 69px;
		
		@media (min-width: $tablet-L) {
			width: 328px;
			font-size: 25px;
			letter-spacing: 0.5px;
		}
	}
	&__introduction{
		font-family: $font-primary;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.72px;
		line-height: 28px;
		padding-bottom: 10px;
		@media (min-width: $tablet-L){
			font-size: 18px;
			line-height: 34px;
			max-width: 450px;
		}
	}
	&__content{}
	&__imageBlock{
	}
	&__image{
		width:300px;
		height: 300px;
		object-fit: cover;
		@media (min-width: $tablet-L) {
		}
	}
}

.singleItem {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

	> a, 
	.relatedItem__textBlock  {
		position: relative;
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		
		@media (min-width: $tablet-P) {
			flex: 0 0 50%;
			max-width: 50%;
		}
		
	}


}