//component
.cmp_visualBox {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media (min-width: $tablet-L) {
        flex-direction: row;
    }
    .reverseBlocks{
        .container-text{
            @media (min-width: $desktop) {
                padding-right: 15px;
            }
        }
    }
    .sortBlocks{
        .container-text{
            @media (min-width: $desktop) {
                padding-left: 15px;
            }
        }
    }

    .row {
        align-items: center;
        justify-content: space-between;
        .textBlock {
            .container-text{
                max-width: 100%;
                @media (min-width: $desktop) {
                    max-width: $textBlock-width;
                }
                .title {
                    @include pb(3);
                    @include letterSpacing(lg);
                    font-weight: 500;
                    @media (min-width: $desktop) {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    //special
    .container,
    .container-fluid{
        max-width: $max-screen-size;
        .reverseBlocks,
        .sortBlocks{
            .textBlock {
                @media (min-width: $desktop) {
                    display: flex;
                }
            }
            .gallery{
                .slideShow{
                    @media (min-width: $desktop) {
                        width: $el-max-width;
                        max-width: 100%;
                    }
                }
                .video,
                video{
                    width: 100%;
                    @media (min-width: $desktop) {
                        &:not(.fullscreen) {
                            max-width: $el-max-width;
                        }
                    }

                }
                .image{
                    a,
                    picture{
                        min-width: 100%;
                    }
                }
            }

        }

        .reverseBlocks{
            .textBlock {
                @media (min-width: $desktop) {
                    justify-content: flex-start;
                }
            }
            .gallery{
                @media (min-width: $desktop) {
                    display: flex;
                    justify-content: flex-end;
                }
                // .slideShow,
                // .video{

                // }
            }
        }
        .sortBlocks {
            .textBlock {
                @media (min-width: $desktop) {
                    justify-content: flex-end;
                }
            }
            .gallery{
                @media (min-width: $desktop) {
                    display: flex;
                    justify-content: flex-start;
                }
                // .slideShow,
                // .video{

                // }
            }
        }
    }

    .container{
        &.container-sm-0{
            .textBlock {
                padding-left: 37px;
                padding-right: 37px;
                @media (min-width: $tablet-P) {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }
    }

    //visualbox con container e con bgInterno
    &.bgInt-default,
    &.bgInt-alternative{
        .container {
            .textBlock {
                @media (min-width: $desktop) {
                    justify-content: center;
                }
            }
        }
    }


    .visualBox__action-link-extended {
        &:before {
            margin-right: 30px;
            width: 50px;
            transition: all 1s ease-in-out;
        }
    }
    &.visualBox__sortBlock {
        flex-direction: column-reverse;
        @media (min-width: $desktop) {
            flex-direction: row;
        }
    }

    .visualBox__gallery {
        position: relative;
        margin-bottom: 25px;
        @media (min-width: $tablet-P) {
            margin-left: unset;
        }
        @media (min-width: $desktop) {
            margin-left: unset;
            margin-bottom: unset;
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-bottom: 0;
        }

        > div {
            @media (max-width: $mobile) {
                width: 100vw;
                max-width: 940px;
                float: unset;
            }
            @media (min-width: $mobile) {
                width: 100%;
                float: right;
            }
            @media (min-width: $desktop) {
                width: 100%;
                max-width: $el-max-width;
                float: right;
            }
        }

        picture, img {
            width: 100vw;
            @media (min-width: $mobile) {
                width: 100%;
            }
        }

        &--left {
            > div {
                float: right;
            }
        }

        &--right {
            // > div {
            //     float: left;
            // }

            .scrollIcon {
                @media (min-width: $desktop) {
                    right: 30px;
                }
                @media (min-width: $desktop-XL) {
                    right: 130px;
                }

            }
        }

        &--fullwidth {
            max-width: 100vw;
            @media (min-width: $desktop) {
                max-width: 100%;
            }

            > div {
                @media (min-width: $desktop) {
                    max-width: 100%;
                }
            }
        }
    }

    &__galleryBlock {
        width: 100%;
        position: relative;
        margin-left: -37px;
        margin-bottom: 41px;
        @media (min-width: $desktop) {
            position: unset;
            margin-left: unset;
            margin-bottom: unset;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &Component__gallery {
        padding-left: 0;
        max-width: 300px;
        @media (min-width: $tablet-L) {
            max-width: 600px;
        }
        @media (min-width: $desktop) {
            max-width: 940px;
        }


    }

    .visualBoxSlider {
        &__video {
            float: right;
            width: 100%;
            max-width: 940px;
            min-height: 300px;
            padding-top: 25px;
            padding-bottom: 25px;
            @media (min-width: $tablet-P) {
                min-height: 400px;
            }
            @media (min-width: $tablet-L) {
                min-height: auto;
                padding-top: 0;
            }

            &--right {
                float: left;
            }

            &.visualBoxSlider__video--full-width {
                max-width: 100%;
            }
        }

        ul.slick-dots {
            text-align: center;
            bottom: $dots--pb;
            height: 30px;
            @media (min-width: $tablet-L) {
                left: 20px;
                text-align: left;
            }

            li {
                width: 102px;
                margin-left: 0px;
                margin-right: 0px;
                @media (min-width: $tablet-L) {
                    width: 50px;
                    margin-left: 0px;
                    margin-right: 10px;
                }


                button {
                    border-bottom: 3px solid $grey;
                    width: 100%;

                    &:before {
                        content: "";
                    }

                }

                &.slick-active {
                    button {
                        border-color: $white;
                    }
                }
            }
        }

        &.visualBoxSlider--full-width {
            ul.slick-dots {
                text-align: center;
            }
        }

        &.slick-dotted.slick-slider {
            margin-bottom: -1px;
        }

    }
}
//templates
.cmp_visualBox{
    //template
    &.tpl_visualBoxDefault{
        align-items: center;
        .row {
            align-items: center;
            justify-content: space-between;
            .textBlock{
                display: flex;
                align-items: center;
                .container-text{
                    @media(min-width: $desktop){
                        max-width: 90%;
                    }
                }
            }

        }
        .gallery{
            width: $el-max-width;
            max-width: 100%;
            @media(min-width: $desktop){
                max-width: 66%;
            }
        }
        // .sortBlocks{
        // }
        // .reverseBlocks{
        // }

        .container-fluid{
            .sortBlocks{
                .textBlock{
                    padding-left: 22px;
                    @include space-r();
                }
            }
            .reverseBlocks{
                .textBlock{ 
                    @include space-l();
                }
            }

        }

        &.isFullWidth--true{
            &.textPosition--left{
                .visualBoxComponent__textBlock{
                    @media(min-width: $desktop){
                        padding-left: 37px;
                        padding-right: 37px;
                    }
                    @media(min-width: $desktop-XL){
                        padding-left: 37px;
                        padding-right: 37px;
                    }
                    @media (min-width: $max-screen-size) {
                        padding-left: 0px;
                    }
                }
            }
            &.textPosition--right{
                .visualBoxComponent__textBlock{
                    @media(min-width: $desktop){
                        padding-left: 37px;
                        padding-right: 37px;
                    }
                    @media(min-width: $desktop-XL){
                        padding-left: 37px;
                        padding-right: 37px;
                    }
                    @media (min-width: $max-screen-size) {
                        padding-right: 0px;
                    }
                }
            }
            &.visualBox__gallery{
                >div{
                    @media (min-width: $tablet-P) {
                        width: 100vw;
                        max-width: 100%;
                        float: right;
                    }
                    @media (min-width: $desktop) {
                        width: 100%;
                        max-width: 940px;
                        float: right;
                    }
                }
            }

        }
        &.isFullWidth--false{
            flex-wrap: wrap;
            @media (min-width: $desktop) {
                flex-wrap: inherit;
            }

            &.textPosition--left{
                .visualBoxComponent__textBlock{
                    @media(min-width: $desktop){
                        padding-left: unset;
                        padding-right: 37px;
                    }
                    @media(min-width: $desktop-XL){
                        padding-left: 0px;
                        padding-right: 37px;
                    }
                }
            }
            &.textPosition--right{
                .visualBoxComponent__textBlock{
                    @media(min-width: $desktop){
                        padding-left: 37px;
                        padding-right: unset;
                    }
                    @media(min-width: $desktop-XL){
                        padding-left: 37px;
                        padding-right: 0px;
                    }
                }
            }
        }
    }
    //template
    &.tpl_visualBoxFocus{
        //padding-top: 70px;
        //padding-bottom: 70px;
        @media (min-width: $tablet-L) {
            //padding-top: 150px;
            //padding-bottom: 150px;
        }
        /*&__textBlock{
            p {
                font-family: $font-secondary;
                font-size: 18px;
                font-weight: 300;
                letter-spacing: 0.36px;
                line-height: 24px;
                @media (min-width: $tablet-L) {
                    width: 755px;
                    font-size: 30px;
                    line-height: 40px;
                }
            }
        }*/
        /*&__content{
            padding-bottom: 50px;
            text-align: center;
            @media (min-width: $tablet-L) {
                border-top: none;
                display: flex;
                text-align: left;
            }
            &::before{
                content: '';
                border-top: 3px solid $black;
                border-left: none;
                padding-top:24px;
                width: 150px;
                margin: 0 auto;
                display: flex;
                @media (min-width: $tablet-L) {
                    width:  auto;
                    border-top: none;
                    border-left: 3px solid $black;
                    padding:10px 0px 10px 24px;
                    display: flex;
                }
            }
            &--white{
                &::before{
                    border-left: none;
                    border-top: 3px solid $white;
                    margin-top: 50px;
                    @media (min-width: $tablet-L) {
                        border-top: none;
                        border-left: 3px solid $white;
                        margin-top: unset;
                    }
                }
            }

        }*/
        /*.visualBoxSlider__image{
            img{
                width: 300px;
                @media(min-width: $desktop){
                    width: 100%;
                }
            }

        }*/
    }
    //template
    &.tpl_visualBoxSymmetric{
        .textBlock{
            display: flex;
            align-items: center;
            padding-left: 0;
            padding-right: 0;
            .container-text{
                padding-left: 0;
                padding-right: 0;
                @media (min-width: $desktop) {
                    margin:0 auto;
                }
            }
        }
        .container-fluid{
            .textBlock{
                @include space-h();
                @media (min-width: $desktop) {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        } 
        &.has-bg {
            @media (min-width: $desktop) {
                .textBlock,
                .gallery {
                    aspect-ratio: 931/669;
                }
            }
        }
    }
    //template
    &.tpl_visualBoxImgOut{



        .container-fluid{
            overflow: visible;

            .textBlock{
                @include space-h();
                @media (min-width: $desktop) {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }

        @media (min-width: $desktop) {
            .margin-end-img-out{
                margin-bottom: -200px
            }

            .margin-start-img-out{
                margin-top: -200px
            }

            .margin-center-img-out{
                margin-top: -200px;
                margin-bottom: -200px;
            }

            .p-highlights{
                padding: 0px 60px;
            }
        }

            .textBlock{
                display: flex;
                align-items: center;
                padding-left: 0;
                padding-right: 0;
                .container-text{
                    padding-left: 0;
                    padding-right: 0;
                    @media (min-width: $desktop) {
                        margin:0 auto;
                    }
                }
            }

            &.has-bg {
                @media (min-width: $desktop) {
                    .textBlock,
                    .gallery {
                        aspect-ratio: 931/669;
                    }
                }
            }
        }

    .textBlock {
        .title {
            padding-bottom: 50px;
            letter-spacing: 1.3px;
            font-weight: 500;
            font-family: raleway,sans-serif;
            line-height: 1.3;

            @media (min-width: 1200px) {
                font-weight: 400;
            }

            &.style-h1 {
                font-size: 20px;
    
                @media (min-width: 1200px) {
                    font-size: 40px;
                }
            }
    
            &.style-h2 {
                font-size: 18px;
    
                @media (min-width: 1200px) {
                    font-size: 36px;
                }
            }
    
            &.style-h3 {
                font-size: 18px;
    
                @media (min-width: 1200px) {
                    font-size: 32px; 
                }
            }
        }
       
    }
}

/*.visualBoxGallery{
    .visualBoxSlider__image{
        img{

            @media(max-width: $mobile){
                width: 100%;
                height: auto;
            }
            @media(min-width: $desktop){
                width: 100%;
            }
        }
    }
}*/

//.visualBoxSymmetric{
//    align-items: center;
//    .visualBoxSymmetric__gallery {
//        .visualBoxSlider {
//            max-width: 100%;
//        }
//    }
//    .visualBoxSymmetric__video {
//        float: right;
//        width: 100%;
//        &--right{
//            float: left;
//        }
//    }
//    .textBlock{
//        //padding-left: 37px;
//        //padding-right: 37px;
//        //@media(min-width:$desktop){
//        //    padding-left: $text--px;
//        //    padding-right: $text--px;
//        //}
//    }
//    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//        .visualBox__text{
//            max-width: 50%;
//            > div{
//                max-width: 460px;
//                margin:0 auto;
//            }
//        }
//    }
//
//    .visualBoxSlider__image{
//        img{
//            @media(min-width: $desktop){
//                width: 100%;
//            }
//        }
//    }
//    .visualBoxSlider__gallery{
//        a{
//            display: contents;
//        }
//        picture,
//        img{
//            width: 100vw;
//            @media(min-width: $mobile){
//                width: 100%;
//            }
//        }
//    }
//}

.button-default, %button-default{
    color: red;
    background-color: yellow;
    padding: 20px;
}

.button-cta{
    @extend %button-default;
    margin:20px;
    color:green;
}
.button-success{
    @extend %button-default;
    margin:40px;
    color:blue;
}

.scrollIcon{
    display: none;
    width: 100px !important;
    position: absolute;
    bottom: 20px;
    right: 20px;
    @media(min-width: $desktop){
        display: none;
    }

    .text-white,
    .text-black{
        .scrollIcon__scrollText {
            float: right;
            text-align: right;

            &:after {
                content: '';
                display: block;
                width: 100px;
            }
        }

    }
    .text-white {
        .scrollIcon__scrollText {
            &:after {
                border: 1px $white solid;
            }
        }

    }
    .text-black {
        .scrollIcon__scrollText {
            &:after {
                border: 1px $black solid;
            }
        }

    }
}

.default-section,
.bg-default{
    .visualBoxFocus__content:before{
        border-left: none;
        @media (min-width: $tablet-L) {
            border-left: 3px solid $color-secondary;
        }
    }
}
.black-section{
    .visualBoxFocus__content:before{
        border-left: none;
        @media (min-width: $tablet-L) {
            border-left: 3px solid $color-secondary;
        }
    }
}
.white-section{
    .visualBoxFocus{
        .visualBox__title,
        .visualBoxFocus__content{
            color: $black;
        }
    }
    .visualBoxFocus__content:before{
        border-left: none;
        @media (min-width: $tablet-L) {
            border-left: 3px solid $color-primary;
        }
    }

}

.visualBoxImgOut-margin-fullWidth{
    @media (min-width: $desktop) {
        margin: 200px 0px;
    }
}

.visualBoxImgOut-margin-excludedVerticalPadding{
    @media (min-width: $desktop) {
        margin: 200px 170px;
    }
}

.visualBoxImgOut-margin-evp-fw{
    @media (min-width: $desktop) {
        margin: 200px 0px;
    }
}

.visualBoxImgOut-margin{
    @media (min-width: $desktop) {
        margin: 200px 170px;
    }
}