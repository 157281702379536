$newsletter-button-loader-size: 24px;

@mixin column-format() {
  padding: 5vw 2.5vw 5vw;
  @media (min-width: $mobile) {
    padding: 1.5vw;
  }
}

.footer {
  background-color: $black;
  color:$white;

  .footer__container {
    padding-top: 5vw;
    padding-bottom: 5vw;

  }

  .footer_backToTop{
    z-index: 2;
    position: fixed;
    width: 100%;
    padding: 0;
    bottom: 15px;
    right: 15px;
  }

  #btn_backToTop {
    background-color: $white;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: absolute; 
    bottom: 0; 
    right: 0;
    border: 1px solid $light-grey;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-open & {
      right: 16px;
    }

    &:before {
      content: '';
      display: block;
      background-image: url('#{$images}/icons/arrow-up-black.svg');
      position: absolute;
      background-size: 15px 15px;
      background-repeat: no-repeat;
      background-position: center;
      margin-top:-10px;
      width: 100%;
      height: 50px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      @media (min-width: $desktop) and (max-width:$desktop-XL) {
          background-size: 12px 12px;
      }
    }

    .backToTop_text{
      font-size: 0.8rem;
      margin-top: 10px;
      display: block;
      color: #000;

      &:hover,
      &:active,
      &:focus {
        color: #000;
      }
    }
  }

  .footer__social {
    padding-left: 0;
 
    display: contents;
    flex-wrap: wrap;
    align-self: flex-end;
    padding-top: 1rem;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: inline-flex;
      align-self: flex-start;
    }

    img {
      fill: $white;
      width: 24px;
      color: $white;
    }
 
  }

  .footer__social-icon {
    .footer__social-icon__img {
      width: 30px;
      margin-right: 20px;
    }
  }

  .footer__logo {
    width: 50%;
    max-width: 150px;
    margin: 0 auto;
    display: block;
    text-align: center;
    @media (min-width: $tablet-L) {
      width: auto;
      margin: auto;
      display: inline-block;
      text-align: initial;
    }
  }

  .footer__columns {
    @include column-format();

    &--topLeft {
      @include column-format();
      display: flex;

      ul {
        div {
          padding-bottom: 1rem;
        }
      }
    }

    &--bottomRight {

      @include column-format();
      display: flex;
      justify-content: space-between;

      .ecommerce & {
        flex-wrap: wrap;
      }

      @media (min-width: $tablet-L) {
        order: 4;
        justify-content: unset;
      }

    }

    &--bottomLeft {
      @include column-format();
      display: flex;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
      }

      div {
        display: flex;
        flex-wrap: wrap;

        p {
          line-height: 34px;
          margin-bottom: 0;
          padding-right: 10px;
        }
      }
    }

    .form-group {
      .form-control {
        background-color: transparent;
      }

      .footer__submit {
        background-color: $black;
      }
    }
  }

  .footer__evenNavItems {
    width: 50%;
    @media (min-width: $tablet-L) {
      width: 70%;
    }
  }

  .footer__oddNavItems {
    width: 50%;
    @media (min-width: $tablet-L) {
      width: 30%;
    }
  }

  .footer__newsletter {

    .label-newsletter-promo {
      display: none;
    }

    .form-group {

      .footer__submit {
        color: $white;
        width: 100px;
        border: 0 solid $grey;
        border-bottom: 1px solid $grey;
        padding: 0;

        &:hover {
          border-bottom: 1px solid $white;
        }
      }

      &.loading {

        input[type="submit"] {
          color: $black;
        }

        .submit-loader,
        .submit-loader:after {
          border-radius: 50%;
          width: $newsletter-button-loader-size;
          height: $newsletter-button-loader-size;
        }

        .submit-loader {
          margin: auto;
          font-size: 10px;
          position: absolute;
          top: calc(50% - #{$newsletter-button-loader-size / 2});
          right: calc(100px / 2 - #{$newsletter-button-loader-size / 2});
          text-indent: -9999em;
          border-top: .3em solid rgba(255, 255, 255, 0.2);
          border-right: .3em solid rgba(255, 255, 255, 0.2);
          border-bottom: .3em solid rgba(255, 255, 255, 0.2);
          border-left: .3em solid #ffffff;
          -webkit-transform: translateZ(0);
          -ms-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
        }

        @-webkit-keyframes load8 {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes load8 {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }
    }

    label[for="subscribe"] {
      font-family: 'Mulish', sans-serif;
      font-size: 17px;
      font-weight: 400;
      letter-spacing: 2.38px;
      line-height: 49px;
      text-transform: uppercase;
    }

    .positive, .negative, .subscriberExist {
      display: none;
    }

    .negative {
      color: $red;
    }

    .subscriberExist {
      color: $red
    }
  }

  .newsletter-input {
    .form-control {
      &:focus{
        box-shadow: none!important;
      }
    }
  }
}

.newsletterSign{
  width: 100%;
  .newsletter-input{
    display: flex;
    position: relative;
    .footer__submit{
      width: 100%;
      max-width: 100px;
    }
    .submit-loader{
      position: absolute;

    }
  }
}

.nav-link {
  color: $color-secondary;
  padding: 0 1rem 0 0;
}

.form-control {
  color: $grey;
  padding: 0;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $light-grey;
  background-color: transparent;

  &:focus{
      background-color: transparent;
      box-shadow: none;
  }
}
.form-control[readonly]{
    background-color:transparent;
}

.footer-nav {
  list-style: none;
  padding-left: 0;
  column-count: 1;
  column-gap: 20px;
  color: $white;
  font-family: $font-primary;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 2.38px;
  line-height: 49px;
  text-transform: uppercase;
  white-space: nowrap;
  @media (min-width: $desktop-XL) {
    column-count: 2;
  }

  .nav-item {
    .nav-link {
    }
  }
}

.disclaimer-nav {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  color: $white;
  font-family: $font-primary;
  font-size: 12px;
  text-transform: uppercase;

  .nav-item {
    list-style-type: none;
    display: inline;
    position: relative;
    padding-right: 20px;

    &:not(:first-child):before {
      content: " | ";
      color: $light-black;
      position: absolute;
      left: -11px;
      top: -1px;
    }
  }

}

.privacy-policy {
  .form-check-input {
    margin-top: .75rem;
  }

  .form-check-label {
    font-size: 14px;
    color: $light-grey;
  }
}

.pre-footer {

  .footer__newsletter {
    background: #F3ECE0;
    padding: 50px 0;

    .label-newsletter-promo {
      display: block;
    }

    .form-group {
      display: flex;
      max-width: 1920px;
      padding: 20px;
      padding-bottom: 0;
      justify-content: flex-start;
      text-align: left;
      margin: 0 auto;
      flex-wrap: wrap;
      //max-width: 400px;
      gap:5rem;

      @media (min-width: $desktop) and (max-width: $desktop-XL ) {
        gap: 0;
      }

      @media (min-width: 960px) {
        flex-wrap: nowrap;
        justify-content: space-around;
        max-width: 1920px;
        padding: 20px 40px 0;
      }

      @media (min-width: 1440px) {
        padding: 20px 280px 0;
      }

      &.privacy-policy { 
        padding: 0;
        width: 100%;

        .form-check {
          width: 100%;
        }
      }

      .input-group {
        justify-content: flex-end;
        width: auto;

        @media (min-width: 960px) {
          width: 60%;
        }
      }

      .footer__submit {
        color: $white;
        width: 100px;
        background: $black;
        padding: 0;
        font-size: 11px;
        border: 0;
        height: 50px;
        margin-left: 4px;
        text-transform: lowercase;

        @media (min-width: 1200px) {
          width: 150px;
        }
      }

      input[type="text"] {
        background: $white;
        padding: 5px;
        height: 50px;
        border: 0;
        padding-left: 15px;
      }

      .privacy-policy {
       

        .form-check-label {
          color: $black;
        }

        .is-invalid {
           + .form-check-label {
            color: $red;
          }
        }
      }
      

      &.loading { 

        input[type="submit"] {
          color: $black;
        }

        .submit-loader,
        .submit-loader:after {
          border-radius: 50%;
          width: $newsletter-button-loader-size;
          height: $newsletter-button-loader-size;
        }

        .submit-loader {
          margin: auto;
          font-size: 10px;
          position: absolute;
         // top: calc(50% - #{$newsletter-button-loader-size / 2});
          top: 10px;
          right: calc(100px / 2 - #{$newsletter-button-loader-size / 2});
          text-indent: -9999em;
          border-top: .3em solid rgba(255, 255, 255, 0.2);
          border-right: .3em solid rgba(255, 255, 255, 0.2);
          border-bottom: .3em solid rgba(255, 255, 255, 0.2);
          border-left: .3em solid #ffffff;
          -webkit-transform: translateZ(0);
          -ms-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
        }

        @-webkit-keyframes load8 {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes load8 {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }
    }

    .label-newsletter {
      width: 100%;
      margin-bottom: 20px;
      max-width: none;
      flex: 100%;

      @media (min-width: 960px) {
        width: 40%;
        margin-bottom: 0;
        flex: 0 0 50%;
      }

      &-promo {
        color: $black;
        font-size: 14px;

        @media (min-width: 1200px) {
          font-size: 18px;
        }
      }
    }

    label[for="subscribe"] {
      font-family: 'Mulish', sans-serif;
      margin: 0;
      color: $black;
      font-size: 20px;
      font-weight: bold;

      @media (min-width: 1200px) {
        font-size: 24px;
      }
    }

    .footer__newsletter__feedback{
      text-align: left;
      width: 100%;

      .positive {
        color: var(--color-success);
        font-weight: bold;
      }
      .subscriberExist {
        color: $red
      }
    }

    .positive, .negative, .subscriberExist {
      display: none;
    }

    .negative {
      color: $red;
    }

    .form-group input[type=text].form-control.is-invalid {
      border: 1px solid #dc3545;
    }
  }
}

.footer_backToTop{
  z-index: 2;
  position: fixed;
  width: 100%;
  padding: 0;
  bottom: 15px;
  right: 15px;
}

#btn_backToTop {
  background-color: $white;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute; 
  bottom: 0; 
  right: 0;
  border: 1px solid $light-grey;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $desktop) and (max-width: $desktop-XL ) {
    width: 35px !important;
    height: 35px !important;
  }

  .modal-open & {
    right: 16px;
  }

  &:before {
    content: '';
    display: block;
    background-image: url('#{$images}/icons/arrow-up-black.svg');
    position: absolute;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: center;
    margin-top:-10px;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media (min-width: $desktop) and (max-width: $desktop-XL ) {
      margin-top: -15px !important;
    }

  }

  .backToTop_text{
    font-size: 0.8rem;
    margin-top: 10px;
    display: block;
    color: #000;

    @media (min-width: $desktop) and (max-width: $desktop-XL ) {
      font-size: 1em !important;
    }

    &:hover,
    &:active,
    &:focus {
      color: #000;
    }
  }
}


