$promotion-text-pt:10px;

/* Promotions list page*/
.promotions-list{

}

/* Promotion list preview */
.promotion-preview{
    .promotion-preview__header{
		padding-top: $promotion-text-pt;
		display: block;
        .promotion__info{
            color: $color-tertiary;
        }
        .ean{
            color: $color-tertiary;
        }

        .prices{
            text-align: right;
            color: black;
            position: relative;
            .price{
                color: black;
                font-size: 1.5rem;
                font-weight: bold;
				@media screen and (min-width:$tablet-L) and (max-width:$desktop){
					font-size: 1.2rem;
				}
            }
            .price--strikethrough{
                color: $color-secondary--disabled;
                text-decoration: line-through;
                font-size: 0.8rem;
                font-weight: normal;
                position: absolute;
                right: 0;
                top: -20px;
            }
            .price--discounted{
                color: black;
                font-weight: bold;
                font-size: 1.5rem;
                @media screen and (min-width:$tablet-L) and (max-width:$desktop){
                    font-size: 1.2rem;
                }
            }
            
        }

	}
	.product-preview__description{
		padding-top: $promotion-text-pt;
	}
    

}

/* Promotions list preview */
.promotion {
	.promotion__label{
		font-family: $font-secondary;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 2.88px;
		line-height: 24px;
		text-transform: uppercase;
		&::after{
			content: ' ';
			display: block;
			height: 1px;
			width: 38px;
			min-width: 50px;
			margin: 20px auto;
			text-indent: -9999px;
			border-top: 1px solid $white;
			border-radius: 2px;
			transition: width .2s ease-in;
		}
	}
	.promotion__category {
		color: $red;
		font-family: $font-secondary;
		font-weight: bold;
		line-height: 24px;
		text-transform: uppercase;
		min-height: 50px;
	}
	.storyItem__borderBlock{
		&.storyItem__borderBlock--light {
			.storyItem__textBlock{
				.promotion__label{
					&::after{
						border-top: 2px solid $light-black;
					}
				}
			}
		}
	}

	.awardIcons{
		display: flex;
		gap: 2px;
		.symbols,
		.awards{
			span{
				margin-left: 2px;
				display: inline-block;
				img{
					width: 35px;
					height: 35px;
				}
			}
		}
	}

	.awards{
        min-height: 40px;
		text-align: right;
		padding-top: $promotion-text-pt;
	}
	 
	.promotion__grid-container { 
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 0px 0px;
		grid-template-areas:
			"product__info product__info";	
		
		.promotion__info {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr;
			gap: 0px 0px;
			grid-template-areas:
				"product__ean product__price";
			grid-area: product__info;

			@media all and (-ms-high-contrast:none) {
				display: flex;
				justify-content: space-between;
			}
		}

		.promotion__ean { grid-area: product__ean; }

		.promotion__price { grid-area: product__price; }
	}


}

/* Promotion detail page */
.page-promotion{
	.cmp_heroBanner.tpl_blocks .text {
		@media (min-width: $tablet-L) and (max-width: $desktop) {
			padding-bottom: 15px;
		}
	}
	.cmp_heroBanner .textBlock .title_3{
		@media (min-width: $tablet-L) and (max-width: $desktop) {
			padding-bottom: 15px;
		}
	}
	.promotion__date{
		&:not(:empty){
			margin-bottom: 1rem;
		}
		.date__start,
		.date__end,
		.date__reedem{
			line-height: 2;
			@media (min-width: $tablet-L) and (max-width: $desktop) {
				line-height: 1.5rem;
			}
			i{
				min-width: 15px;
			}
		}
	}
}
