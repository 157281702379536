@import "../smeg/theming/variables";
@import "theming/variables";
@import "../shared/main";

.foodservice {
  /* Parte alta categorie prodotto*/
  .heroBannerUnderlined .heroBannerUnderlined__content .heroBannerUnderlined__text {
    padding-left: 0 !important;
  }

  .heroBannerUnderlined .heroBannerUnderlined__content .heroBannerUnderlined__text .heroBannerUnderlined__text__line {
    width: 0;
    display: none;
  }

  /* end */
  .products-list .product-preview__header .name {
    font-weight: bold !important;
  }


  /* cta */
  .action-link {
    display: inline-block;
    padding: 15px 40px;
    width: auto;
    color: $white;
    background-color: $btn-color-primary;

    &:hover {
      color: $white;
    }

    &:before {
      display: none;
    }
  }

  .white-section {
    .action-link {
      background-color: $black;
      color: $white;
    }
  }

  .black-section {
    .action-link {
      background-color: $white;
      color: $black;
    }
  }

  .cta_box {
    margin-bottom: 1rem;
  }

  /* Hero banner */
  .heroBannerFullScreen .slick-dots {
    padding-left: 170px;
    text-align: left;
  }

  .heroBannerFullScreen .slick-dots li button {
    border: none;
  }

  .cmp_heroBanner.tpl_textFocus .textBlock.collapsed .text p{
    display: inline-block;
  }

  .cmp_heroBanner.tpl_fullScreen{
    .wrapper-text {
      @media (max-width: $tablet-L) {
        display: flex;
        align-items: baseline;
        flex-direction: column;
      }
    }
  }

  /* footer */
  .scroll-title,
  .js-action-scroll {
    display: none;
  }

  .footer,
  .footer a {
    color: $white;
  }

  .footer_newsletter {
    input#subscribe {
      border-top: 0;
      border-left: 0;
      border-right: 0
    }
  }

  .footer, .footer a {
    color: #FFF;
  }

  .footer_scrollTop, .footer_newsletter {
    display: block
  }

  .footer__newsletter input#subscribe {
    border-top: 0;
    border-left: 0;
    border-right: 0
  }


  /*END footer*/
  .slick-active {
    font-weight: bold;
  }

  /* Sezione Menu */
  .main-nav .nav-third {
    a {
      color: $black;
      font-weight: bold;
    }
  }

  .thirdLevelNavbar ul {
    column-count: 1;
  }

  .main-nav .nav-first {
    font-weight: bold;
  }

  .main-nav .nav-second {
    font-size: 18px;
    text-transform: none;
  }

  .nav-link.nav-second {
    font-size: 24px;

    &.item-ispiration {
      padding-top: 60px;
    }
  }

  .nav-link.nav-second.item-ispiration {
    padding-top: 60px;
  }

  .menu-wrapper .hamburger-menu span {
    background-color: #000 !important;
  }

  .header_btn-search,
  .search-bar__submit {
    .icon {
      background-image: url('#{$images}/search-icon--black.svg');
      background-size: contain;
    }
  }

  /* Numeri nello slider */
  .bannersSlider .slick-dots li button,
  .visualBox .visualBoxSlider ul.slick-dots li button {
    border-bottom: none;
  }

  .heroBannerFullScreen .slick-dots {
    padding-left: 170px;
    text-align: left;
  }

  .heroBannerFullScreen .slick-dots li button {
    border: none;
  }

  /* END Numeri nello slider */

  /* CTA */
  a.action-link.action-link--black,
  a.action-link.action-link--white {
    padding: 15px 40px;
    width: auto;
  }

  a.action-link.action-link--black:before,
  a.action-link.action-link--white:before {
    display: none;
  }

  /* END CTA*/

  .eform-component .eform-component__block .form-group .form-control:focus {
    border: 1px solid $border-color-primary;
  }

  .eform-component .eform-component__block .form-group .form-control-file+.action-link{
    top: 50%;
    transform: translateY(-50%);
    margin-top: 3.5px;
    right: 10px;
    bottom: unset;
    padding: 0.4rem 0.8rem;
    height: unset;
    color: $white;
  }
  #ssc-form .eform-component .action-link{
    color:$white;
  }

  #ssc-form .eform-component .select2-selection--single,
  #ssc-form .eform-component .select2-selection__rendered{
    background-color: unset;
    border:unset;
  }

  /* End Form Generici */

  .heroBannerUnderlined .heroBannerUnderlined__content .heroBannerUnderlined__text {
    padding-left: 0 !important;

    .heroBannerUnderlined__text__line {
      width: 0;
      display: none;
    }
  }

  /* END Hero Banner*/

  /* show more */
  .heroBannerUnderlined .heroBannerUnderlined_text.collapsed .heroBannerUnderlinedtext_subTitle p {
    display: block
  }

  .btn__showMore {
    display: none
  }

  /*END show more */
  .products-manager-page .products-manager-page__content .products-manager-page__content__action-link {
    float: none;
  }

  /* END Pagina Categoria */

  /* Listing prodotti */


  .products-list .product-preview__header .name {
    font-weight: bold;
  }

  /*SSD-1141*/
  //@media (min-width: 768px) {
  //  .cards__card {
  //    padding: 50px 100px !important;
  //    margin: 0 0 75px 0;
  //    max-width: 95%;
  //  }
  //}

  /* BASE */
    .base-layout .base-layout__left-right {
      background-color: $white;
    }

    .how-to-connect-component {
      p, a {
        color: $black;
      }
    }


  /* HEADER */

  .secondNav__aesthetics .thirdLevelNavbar ul,
  .thirdLevelNavbar ul {
    column-gap: 70px;
  }

  .thirdLevelNavbar ul {
    column-count: 1;
  }

  .header__mouseOver.open .nav-first {
    border-bottom: 2px solid #000;
  }

  .main-nav {

    .nav-first {
      font-weight: bold;
    }

    .nav-second {
      font-size: 18px;
      text-transform: none;
    }

    .nav-third {
      max-width: 250px;
    }

  }

  .secondNav__inspirations .child__description {
    color: $color-secondary
  }

  /* COMPONENTS */

  /* cards-component */
  .cards-component {
    .cards__card {
      /*SSD-1141*/
      //margin: 0px;
      //padding: 50px 0px !important;
      //max-width: none;

      .cards__image img.box--bigger {
        max-width: 100%;
      }
    }
  }

  /* cards-home */
  .cards-home {
    .cards__card {
      max-width: 100%;
      margin: 0 15px 15px;
      @media (min-width: $desktop) {
        margin: 0;
      }
      background-color: $color-fourth;
      height: 100%;

      .cards__textBox {
        padding-top: 50px;
        padding-bottom: 50px;

        @media (min-width: $desktop) {
          padding-top: 100px;
          padding-bottom: 100px;
        }
        margin: 0 auto;

        .cards__title {
          font-weight: 700;
          font-size: 2rem;

          @media (min-width: $desktop) {
            font-size: 2.5rem;
          }
        }

        .action-link {
          display: inline;
        }
      }

      .cards__image {
        display: none;
      }
    }
  }

  /* relatedItems */
  .white-section {
    .relatedItem__header:after {
      background-color: $color-secondary;
    }
  }

  .black-section {
    .relatedItem__header:after {
      background-color: $color-primary;
    }
  }

  /* FORMS */
  .eform-component .eform-component__block .form-group.checkboxgroup .custom-control-input:checked ~ .custom-control-label:before {
    border-color: $color-primary;
  }

  .select-dropdown .select2.select2-container .select2-selection.select2-selection--single {
    border: 1px solid $color-tertiary;
  }

  .eform-component .eform-component__block .eforms-buttons button,
  .eform-component .eform-component__block .eforms-buttons input[type=button] {
    background-color: $black;
    color: $white;

    &:hover {
      color: $light-grey;
      border: 1px solid $white;
    }
  }

  .form-control {
    /*border: none;
    border: 1px solid $color-tertiary!important;*/
  }

  .select2-container--default .select2-results__option {
    border-left: 1px solid $color-tertiary;
  }

  .white-section {
    .form-group {
      button {
        background-color: black;
        color: white;
      }
    }

    .eform-component .ui-slider-horizontal a.ui-slider-handle {
      background-color: $color-primary;
      color: $color-secondary;
    }
  }

  .select-dropdown .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
    color: $black;
  }

  input#sku-download {
    padding-left: 10px;
    padding-right: 10px;
  }

  /*END FORMS*/

  /* PAGES */
  /* download-catalogues */
  .documentViewer .documentViewer__title {
    color: $t-color-primary;
  }

  .documentViewer .documentViewer__picture > picture {
    background-color: unset;
  }

  /* white-section */
  .white-section {
    .visualBoxFocus__content:before {
      border-left-color: $black;
    }
  }

  /* buttons */
  .default-section {
    .action-link--black:hover {
      color: $grey;
      text-decoration: none;
    }
  }

  /* products-manager-page */
  .products-list-page,
  .products-manager-page {
    .black-section {
      background-color: $bg-color-secondary;
      color: $t-color-primary;
    }

    .heroBannerUnderlined__text {
      &.text-white {
        background-color: $bg-color-secondary;
        color: $t-color-primary;
      }

      .btn__showMore {
        .action-link--white {
          background-color: $color-secondary;
          color: $color-primary;
        }
      }
    }
  }

  .products-manager-page__content {
    .action-link.action-link--black {
      color: $color-primary;

      &:hover {
        color: $grey;
        text-decoration: none;
      }
    }
  }

  .products-manager-page .white-section .action-link.action-link--black {
    &:hover {
      color: $grey;
      text-decoration: none;
    }
  }
	.how-to-connect-component .instruction_manual {
	  a {
	    color: white;
	  }
	  .cards p{
	    color:black;
	  }
	}

  /* Footer */
  .footer {
    .footer__columns--topLeft {
      .footer-nav {
        a {
          color: $white;

          &:hover {
            color: $light-grey;
          }
        }
      }
    }

    .footer__columns--bottomLeft {
      .disclaimer-nav {
        .nav-item {
          a {
            color: $white;
            &:hover {
              color: $light-grey;
            }
          }
        }

        .nav-item:not(:first-child) {
          &:before {
            color: $white;
          }
        }
      }
    }

    .footer__columns--bottomRight {
      @media (min-width: $desktop) {
        justify-content: flex-end;
      }
    }
  }

  /* SITEMAP */
  .sitemap__component{
    .sitemap__category--default,
    .sitemap__category--inspirations {
      max-width: fit-content;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .sitemap__category--inspirations {
      .sitemap__secondLevelMenu {
        text-transform: initial;
      }
    }
  }

  /* Store locator */
  .stores-locator .stores-list .store-detail > div .action-link{
    border: 1px solid $black;
    color: $black;
    text-align: center;
    font-weight: bold;
    &:before{
      display: none;
    }
  }
  .stores-locator .stores-list .store-detail > div .action-link::before{
    display:none;
  }

  .stores-locator{
    background-color: $black;
    color: $white;
    .select-dropdown .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered{
      color: $white;
    }
  }

  /* End Store locator */
}
