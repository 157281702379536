@media print {

    
    /**
    * Making intergated videos disappear, and removing the iframes' whitespace to zero. 
    */
    iframe, ins {
        display: none;
        width: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
        line-height: 0pt !important;
        white-space: nowrap;
    }

    body {
        line-height: 1.3;
        background: #fff !important;
        
    }
    body, h1, h2, h3, a {
        color: #000 !important;
    }
        

    .desktop-menu,
    .mobile-menu,
    .breadcrumb__row,
    .footer,
    .relatedItems,
    .shareButtons,
    #cookie-banner
    {
        display: none;
    }

    .base-layout {
        margin-top: 0 !important;

        // &:before {
        //     content: url('https://test-smeg.onehippo.io/site/binaries/content/gallery/foodservice/logo-foodservice.png') ;
        //     display: block;
        // }
    }


    .recipes-details {
 
        .recipe_summary {
            .inner {
                color: #000000 !important;
                border: 1px solid #000000;
            }

            ul li {
                border: 0;
            }

        }

        .recipe_ingredientsList  {
            color: #000000;

            ul {
                column-count: 1;
                padding-left: 0;
            }

            a {
                text-decoration: none;
            }
        }

        .relatedRecipes {
            display: none;
        }
    }
 











}
 