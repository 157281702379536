.cooking-details {
    font-size: 16px;
    font-family: 'Mulish', sans-serif;

    .title{
        font-size: 32px;
        font-weight: 400;
        font-family: 'Raleway' ,sans-serif;
    }

    .column-reverse {
        position: relative;
        padding-top: 35px;
        padding-bottom: 35px;

        @media (min-width: $desktop) {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        @media (min-width: $mobile) {
            flex-direction: row-reverse;
        }
    }

}

.recipe_ingredientsList {

    .title {
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 25px;

        @media (min-width: $desktop) {
            font-weight: 400;
            line-height: 40px;
        }
    }

    .title-section {
        @include newtitle(xs);
        text-transform: uppercase;
        font-weight: 700;
        margin: 20px 0 10px;
        letter-spacing: 2.24px;
    }

    a:hover {
        color: #bebebe;
        text-decoration: none;
    }

    //.ingredients_section {
    //    font-size: 18px;
    //    font-weight: 700;
    //    margin-bottom: 4px;
    //}

    //ul {
    //    //padding: 0 25px !important;
    //
    //    // column-count: 2;
    //    // column-gap: 30px;
    //    // column-fill: balance;
    //
    //    // @media (min-width: $mobile) {
    //    //     // (ingredients >= 7)
    //    //     &[data-count~='7'] {
    //    //         columns: 2;
    //    //     }
    //    // }
    //    padding: 0 ;
    //
    //    @media (min-width: $tablet-L) {
    //        display: flex;
    //        flex-wrap: wrap;
    //
    //        .title-section {
    //            width: 100%
    //        }
    //
    //        li {
    //            width: 50%
    //        }
    //    }
    //
    //    a {
    //        font-weight: unset;
    //    }
    //
    //    .title-section {
    //        @include newtitle(xs);
    //        text-transform: uppercase;
    //        font-weight: 700;
    //        margin: 20px 0 10px;
    //    }
    //
    //    li {
    //        list-style: none;
    //    }
    //}
    //
}

.cooking_summary {

    .inner {
        padding: 37px;
        background-color: $dusty-grey;
        color: $lighter-grey;
    }

    .cooking_summary-box {
        margin-bottom: 10px;

        a {
            &:hover{
                color: #bebebe !important;
                text-decoration: none;
            }
        }

        ul {
            padding: 0;//reset
            margin-bottom: 0;
        }
    }

    .summary_title {
        font-family: $font-primary;
        font-weight: 400;
        display: inline-block;
        //margin-right: 5px;
        @include newtitle(xs);
    }

    a.content{
        &:hover{
            cursor: pointer;
            color: #bebebe;
        }
    }

    .content { //+ tutti gli UL
        display: inline;
        //padding-left: 5px;
        font-weight: 700;
    }

    ul.tagList {
        li {
            line-height: normal;
            background: transparent;
            padding: 0;
            margin: 0;
            display: inherit;

            a {
                font-weight: bold;
                &:hover{
                    cursor: pointer;
                    color: #bebebe;
                    text-decoration: none;
                }
            }
        }
    }

    .recipe_appliancesUsed {
        ul {
            list-style: none;
        }
    }

    .recipe_chefAdvice {
        .content,
        .content p {
            line-height: 1.5;
        }

        ul {
            padding-left: 20px;
            margin-bottom: 1rem;
        }
    }
}

.shareButtons {
    $shareButtons_size: 40px;
    $shareButtons_sizeSmall: 30px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    .social-icon,
    .print {
        display: inline-block;
        width: $shareButtons_sizeSmall;
        height: $shareButtons_sizeSmall;
        line-height:  $shareButtons_sizeSmall - 2px  ;
        border-radius: $shareButtons_sizeSmall/2;

        border: 1px solid transparent;
        margin: 4px;

        @media (min-width: $tablet-P) {
            width: $shareButtons_size;
            height: $shareButtons_size;
            line-height:  $shareButtons_size  - 2px  ;
            border-radius: $shareButtons_size/2;
        }
    }

    .print {
        background: url('#{$images}/icons/icon-print.png') center no-repeat;
        background-size: cover;

        .text {
            @include visuallyHidden;
        }
    }

}

.relatedRecipes {
    .storyItem__category {
        display: none;
    }

    .storyItem__borderBlock {
        > div {
            background-color: $white;
            a {
                color: $black;
            }
        }
        .storyItem__textBlock{
            color: $black;
        }
    }
}

.recipeHeader{
    .category {
        display: flex;
        align-items: center;
        font-family: $font-secondary;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 1.82px;
        line-height: 36px;
        margin-bottom: 10px;

        @media (min-width: $tablet-P) {
            padding-left: unset;
            padding-right: unset;
        }

        @media (min-width: $desktop) {
            font-size: 16px;
            letter-spacing: 2.24px;
        }

        span {
            display: inline-block;
            margin-right: 19px;
            line-height: 20px;
            border-top: 1px solid;
            width: 39px;
        }
    }
    .image{
        img{
            width: 100%;
        }
    }
    .image-title{
        @media (max-width: $tablet-L) {
            margin: 0 -37px;
        }
    }
}

.knowledge-slider{

    .knowledge-itemSlider{
        @media (min-width: $tablet-P) {
            flex-direction: row-reverse;
            padding: 3rem 1rem;
        }
        background: #f8f8f8;
    }

    .pre_title{
        font-size: 12px;
        letter-spacing: 2.24px;

        @media (min-width: $tablet-P) {
            font-size: 16px;
            padding-bottom: 1rem;
        }
    }

    .title{
        font-size: 12px;
        font-weight: 700;
        padding-bottom: 0;

        @media (min-width: $tablet-P) {
            font-size: 32px;
            padding-bottom: 25px;
        }
    }

    .text{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 12px;
        line-height: 24px;

        @media (min-width: $tablet-P) {
            font-size: 16px;
            display: block;
        }
    }

    .image{
        img {
            width: 100%;
        }
    }

    .bg-F0F0E6{
        background: #F0F0E6;
    }
}

.howToCookQuestion{
    font-family: Raleway, sans-serif;
    letter-spacing: 1.3px;
}

.procedure-selector{
    .nav-tabs{
        border-bottom: none;
        .nav-link{
            color: #8A8A8A;
            text-transform: uppercase;
            padding: 0 1rem 0 0;
            border: none;
            font-size: 12px;
            letter-spacing: 1.44px;

            @media (min-width: $tablet-P) {
                padding: 0 2rem 0 0;
                font-size: 16px;
            }

            &:hover{
                border: transparent;
                text-decoration: none;
            }

            &.active{
                color: #020202;
                border: transparent;
                span{
                    border-bottom: 3px solid black;
                }
            }
        }
    }
}

.procedure-container{
    flex-direction: column-reverse;

    @media (min-width: $tablet-L) {
        flex-direction: row;
        &:nth-child(even){
            flex-direction: row-reverse;
        }
    }

    .gallery{
        .image{
            img{
                width: 100%;
            }
        }
    }
}

.procedure{
    background: #f8f8f8;

    .procedure-number{
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 0.32px;
        background: #d9d9d9;
        width: 52px;
        padding: 9px 0;
        margin: 56px 25px 25px 25px;
        text-align: center;
    }

    .procedure-number.img{
        @media (min-width: $desktop) {
            position: absolute;
            top: 56px;
            left: 45px;
            margin: 0;
        }
    }

    .procedure-text{
        padding: 0 28px;
    }
}

.wine-modal{
    .modal-dialog {
        @media (min-width: 576px) {
            max-width: 800px;
        }
    }

    .modal-header {
        border-bottom: none;
    }

    .modal-body {
        img{
            width: 100%;
        }
    }
}

.recipe-filter{
    border: 2px solid black;
    padding: 10px;
    margin-right: 8px;
}

.recipe-filter-active{
    padding: 5px 20px;
    margin-right: 8px;
    color: white;
    background: #968870;
}

.js-cooking-itemSlider{
    .js-storyItem{
        width: 100%;
        @media (max-width: $desktop) {
            padding: 0 15px;
        }
    }
    .js-storyItem.single-storyItem{
        width: 300px;
    }
}

.usedProduct {
    .small-listItem{
        display: inline-flex;
        width: 29%;
        justify-content: space-between;
        align-items: center;
        margin: 2% 4% 2% 0;

        @media only screen and (max-width: 1500px)  {
            width: 45%;
        }

        @media only screen and (max-width: 790px)  {
            width: 100%;
            margin: 30px 0;
        }

        .product-content{
            display: flex;
        }

        .product-content a{
            display: flex;
            flex-direction: row;
            align-items: center;

            .product-preview__gallery {
                margin-right: 15px;
            }

            img {
                max-width: 100px;
            }

            .name {
                color: #161616;
                line-height:normal !important;
                font-size: .75rem;
                max-height: 3rem;
                position: relative;
                overflow: hidden;

                &:after {
                    content: "...";
                    position: absolute;
                    right: 0;
                    top: 31px;
                    background: #fff;
                    padding-right: 30%;
                }
            }

            .name-sku {
                color: #161616;
                font-size: .625rem;
                font-weight: 100;
                line-height: normal !important;
            }
        }

        .promotion-preview__header .prices {
            text-align: left;
            line-height: normal;

            .price {
                font-size: .825rem;
                display: inline-block;
            }

            .price--strikethrough {
                font-size: .75rem;
                line-height: 1.3rem;
                position: static;
                display: inline-block;
                margin-right: 8px;
            }
        }

        .product-link__buy button.btn_addToCart {
            display: flex;
            align-items: center;
            font-size: .75rem;
            padding: 7px 15px;
            border: none;

            .icon {
                font-size: 40px;
                font-weight: 100;
                padding-left: 8px;
                margin-top: -3px;

                @media only screen and (max-width: 500px)  {
                    padding-left: 0;
                }
            }

            @media only screen and (max-width: 500px)  {
                padding: 0;
                height: 40px;
                width: 40px;
                justify-content: center !important;
                padding: 0 !important;
            }

            .btn-text {
                @media only screen and (max-width: 500px)  {
                    display: none;
                }
            }

        }

    }
    .products-list-small{
        width: 100%;

        .energy_label_text{
            font-size: 12px;
            line-height: normal;
        }

        h2 {
            font-size: 1.5rem;
            font-weight: 700;
        }

        .products-list-small {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        .btn-viewMore {
            border: 0;
            background: none;
            margin: 0 auto;
            font-weight: 700;
            display: flex;
            align-items: flex-end;
            width: 100%;
            justify-content: center;
            color: #000;

            &:hover,
            &:active,
            &:visited {
                color: #000;
            }

            .icon-more {
                font-size: 1.2rem;
                margin-right: 5px;
            }
        }
    }
}