$white: #ffffff;
$black: #020202;
$light-white: #f8f8fa;
$light-black: #161616;
$grey: #b4b4b4;
$lighter-grey: #363636;
$light-grey: #a0a0a0;
$dark-grey: #797979;
$dusty-grey: #f8f8f8;
$red: #B10011;

$product-grey: #797979;
$product-light-grey: #e3e3e3;
$product-label: $dusty-grey;

$color-primary:$white;
$color-secondary: $light-white;
$color-secondary--disabled: $grey;
$color-tertiary: $light-grey;
$color-fourth: $light-white;
$color-dark:$lighter-grey;
$color-whiteSmoke:#F4F4F4;

/* Css variables*/
:root {
  --color-gold: #baa483;
  --color-attention: rgba(160, 160, 160, 0.6);
}

/*Background color*/
$bg-color-primary: $color-primary;
$bg-color-secondary: $color-secondary;
$bg-color-dark: $light-white;

/*Text color*/
$t-color-primary: $black;
$t-color-secondary:$black;
$t-color-alternative: $black;

/*Elements color*/
$el-color-primary:$black;
$btn-color-primary:$black;
$border-color-primary:$grey;

$color-primary--menu: $color-secondary;
$color-secondary--menu: $black;

$breadcrumbs: #b6b6b6;

/*Elements size*/
$el-max-width:960px;

$text--pt:25px;
$text--px:25px;
$text--pb:50px;

$text__ul--px:80px;

$text__productCategory--pt:10px;

/* Icons */
$icon--search:'search-icon--black.svg';


/* Header */

//Burger menu
$burgerMenu: (
    offset-top-m: 60px,
    offset-top-d: 130px,
);

//Burger Menu 150%
$w-150: (
    offset-top: 120px,
);


/* Gallery */
$dots--pb:15px;

/* Buttons */
$minWidth:200px;
$pdpMinWidth:$minWidth;
