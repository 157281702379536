body {
    letter-spacing: .32px;
    line-height: 34px;
}

a {
    color: inherit;
    &:hover {
        color: inherit;
    }
}

h1, h2, h3,
h4, h5, h6 {
    margin-bottom: 0;
    font-weight: normal;
    line-height: normal;
}

h1, h2, h3,
h4, h5, h6 {
    &:not([class]) {
        font-weight: bold;
        letter-spacing: 1.3px;
        font-family:  $font-secondary;
    }
}

button{
    &.reset-button{
        all:inherit;
    }
}

.btn-link:hover{
    color: inherit;
}

img{
    height: auto;
}